import React, {useContext, useEffect, useState} from "react";
import styles from "./Card.module.css"
import cn from "classnames";
import shoppingCart from "../../../assets/images/shoppingCart.svg"
import greenPlus from "../../../assets/images/greenPlus.svg"
import minusDarkBlue from "../../../assets/images/minusDarkBlue.svg"
import plusDarkBlue from "../../../assets/images/plusDarkBlue.svg"
import garbage from "../../../assets/images/garbage.svg"
import TouchableOpacity from "../../../components/TouchableOpacity";
import SText from "../../../components/SText";
import DataContext from "../../../data/DataContext";
import {useCoolDown, useRestrictions, useWindowDimensions} from "../../../utils/hooks";
import {between, round} from "../../../utils/other";
import {NavLink} from "react-router-dom";
import envelope from '../../../assets/images/envelope.svg'
import placeholder from '../../../assets/images/productImagePlaceholder.png'
import NewModal from "../../../components/NewModal";
import InformAboutAdmission from "../../../components/InformAboutAdmission";
import ym from 'react-yandex-metrika';

const Card = ({image, name, measure, price, id, sale = '', salePrice, weight, out = false, ...product}) => { //todo Ð²ÐµÑ

    const {cart} = useContext(DataContext)
    const [count, setCount] = useState(0)
    const [imageUrl, setImageUrl] = useState(image)
    const [informModalVisible, setInformModalVisible] = useState(false)
    const {windowDimensions: {width}} = useWindowDimensions()

    const recordValue = useCoolDown(cart.update, 600)

    function _newHandleChange(delta) {
        let newValue = between(0, count + delta, product.availableones)
        setCount(newValue)
        recordValue(id, newValue)
    }

    // const newHandleChange = useRestrictions(_newHandleChange)
    const newHandleChange = _newHandleChange

    useEffect(() => {
        const amount = cart.data?.productList?.find(item => item.product_id == id)?.quantity || 0
        setCount(amount)
    }, [id, cart.data?.productList])


    return <>
        <NewModal isVisible={informModalVisible} onClose={() => setInformModalVisible(false)} fullScreen={false}
                  margin={'0 36px'} minWidth={width > 790 ? 781 : 303}>
            <InformAboutAdmission onClose={() => setInformModalVisible(false)} productId={id}/>
        </NewModal>
        <div className={styles.wrapper}>
            <div className={cn(styles.cardImg, out && styles.imgOut)}>
                <NavLink to={'/product/' + id}>
                    <div className={styles.mainImg}>
                        <img draggable={false} src={imageUrl} onError={() => setImageUrl(placeholder)}/>
                    </div>
                </NavLink>
                {!out && <div>
                    <div className={cn(styles.counter, count > 0 && styles.visible)}>
                        {count >= 2 ? <TouchableOpacity onClick={() => newHandleChange(-1)}>
                                <div className={styles.minusDarkBlue}><img draggable={false}  src={minusDarkBlue}/></div>
                            </TouchableOpacity>
                            : <TouchableOpacity onClick={() => newHandleChange(-1)}>
                                <div className={styles.garbage}><img draggable={false}  src={garbage}/></div>
                            </TouchableOpacity>}
                        <div><SText fontSize={20} fontWeight={700} color={'#213140'}>{count}</SText></div>
                        <TouchableOpacity onClick={() => newHandleChange(1)}>
                            <div className={styles.plusDarkBlue}><img draggable={false}  src={plusDarkBlue}/></div>
                        </TouchableOpacity>
                    </div>
                    {product.availableones==count?<div className={styles.quantityIsLimited}>
                        ÐšÐ¾Ð»Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾ Ð´Ð°Ð½Ð½Ð¾Ð³Ð¾ Ñ‚Ð¾Ð²Ð°Ñ€Ð° Ð¾Ð³Ñ€Ð°Ð½Ð¸Ñ‡ÐµÐ½Ð¾
                    </div>:null}
                </div>}
                {out && <div className={styles.notifyMe} onClick={() => setInformModalVisible(true)}>
                    <SText fontSize={16} fontWeight={700} color={'#fff'}>{'Ð¡Ð¾Ð¾Ð±Ñ‰Ð¸Ñ‚ÑŒ Ð¾ Ð¿Ð¾ÑÑ‚ÑƒÐ¿Ð»ÐµÐ½Ð¸Ð¸'}</SText>
                </div>}
            </div>
            <div className={styles.cardName}>
                <div><SText fontSize={15} fontWeight={700} color={'#213140'}
                            style={{textOverflow: 'ellipsis', overflow: 'hidden',}}>{name}</SText></div>
            </div>
            <div className={cn(styles.cardMeasure, sale !== '' && styles.sale, sale === '' && styles.noSale)}>
                <div><SText fontSize={14} fontWeight={700}
                            color={'#909090'}>{Math.round(weight * Math.max(1, count) * 10) / 10 + ' ' + measure}</SText>
                </div>
            </div>
            {sale !== '' ? <div className={styles.saleWrapper}>
                <div className={styles.cardSale}>
                    {count >= 1
                        ? <SText fontSize={16} fontWeight={700} color={'#909090'}>{round(count * salePrice)}</SText>
                        : <SText fontSize={16} fontWeight={700} color={'#909090'}>{round(salePrice)}</SText>}
                    <SText fontSize={16} fontWeight={550} color={'#909090'}>{' Ñ€ÑƒÐ±'}</SText>
                </div>
                <div className={styles.percent}>
                    <SText fontSize={16} fontWeight={700} color={'#fff'}>{`-${sale}%`}</SText>
                </div>
            </div> : null}
            <div className={styles.cardPrice}>
                <div>
                    {count >= 1
                        ? <SText fontSize={24} fontWeight={700} color={'#213140'}>{round(count * price)}</SText>
                        : <SText fontSize={24} fontWeight={700} color={'#213140'}>{round(price)}</SText>}
                    <SText fontSize={18} fontWeight={550} color={'#213140'}>{' Ñ€ÑƒÐ±'}</SText>
                </div>
            </div>
            {out ?
                <TouchableOpacity onClick={() => setInformModalVisible(true)}>
                    <div className={cn(styles.shoppingCart, styles.mailMe)}>
                        <img draggable={false}  src={envelope}/>
                    </div>
                </TouchableOpacity> :
                (count > 0
                    ? product.availableones == count ? <div style={{opacity: 0.5}} className={styles.shoppingCart}>
                            <img src={greenPlus}/>
                        </div>
                        : <TouchableOpacity >
                            <div onMouseDown={() => newHandleChange(1)} className={styles.shoppingCart}>
                                <img draggable={false}  src={greenPlus}/>
                            </div>
                        </TouchableOpacity>
                    :
                    <TouchableOpacity >
                        <div onMouseDown={() => {

                            newHandleChange(1)
                            ym('reachGoal','mainButton')

                        }

                        } className={styles.shoppingCart}>
                            <img draggable={false}  src={shoppingCart}/>
                        </div>
                    </TouchableOpacity>)
            }
        </div>
    </>
}

export default Card
