import React, {useContext, useState} from "react";
import DataContext from "../../data/DataContext";
import Enum from "../../utils/enum";
import {requestConfirmationCode, submitAuthCode} from "../../api";
import Confirm from "./components/Confirm";
import SignIn from "./components/SignIn";
import styles from "./AuthModal.module.css";
import closeIcon from "../../assets/images/closeButton.svg";
import TouchableOpacity from "../../components/TouchableOpacity";
import {useHistory} from "react-router-dom";


const [SIGN_IN, CONFIRM] = Enum(2)

export default ({onClose=()=>{}}) => {
    const [screen, setScreen] = useState(SIGN_IN)
    const [initialPhone, setInitialPhone] = useState('')
    const [temporaryToken, setTemporaryToken] = useState('')
    const {push} = useHistory()
    const [error, setError] = useState('')

    const {auth, authorize} = useContext(DataContext)

    async function onPhoneSubmit (phone, agreed) { //todo
        setInitialPhone(phone)
        setError('')
        const response = await requestConfirmationCode(phone, agreed)
        if (response?.token) {
            setTemporaryToken(response.token)
            setScreen(CONFIRM)
        } else {
            setError('ÐŸÑ€Ð¾Ð¸Ð·Ð¾ÑˆÐ»Ð° Ð¾ÑˆÐ¸Ð±ÐºÐ°. ÐŸÐ¾Ð¿Ñ€Ð¾Ð±ÑƒÐ¹Ñ‚Ðµ Ð¿Ð¾Ð·Ð´Ð½ÐµÐµ.')
        }
    }

    function reenterPhone() {
        setScreen(SIGN_IN)
        setError('')
    }

    async function submitCode(code) { //todo
        setError('')
        const response = await submitAuthCode(code, temporaryToken)
        console.log(response);
        if (response?.token) {
            await authorize(response.token)
            onClose()
            if (window.awaitingOrder === true)
                setTimeout(()=>push('/submit_order'), 500)
        } else {
            setError('ÐžÐ¹, ÐºÐ¾Ð´ Ð½ÐµÐ²ÐµÑ€Ð½Ñ‹Ð¹. ÐŸÐ¾Ð¿Ñ€Ð¾Ð±ÑƒÐ¹Ñ‚Ðµ ÐµÑ‰Ðµ Ñ€Ð°Ð·')
        }
    }



    function getContent () {
        switch (screen) {
            case CONFIRM: return <Confirm phone={initialPhone} onReenter={reenterPhone} onSubmit={submitCode} error={error} onDismissError={()=>setError('')}/>
            case SIGN_IN: default: return <SignIn onSubmit={onPhoneSubmit} initialPhone={initialPhone} error={error} onDismissError={()=>setError('')}/>
        }
    }

    return <div className={styles.wrapper}>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <TouchableOpacity onClick={onClose}>
                <div style={{background: '#F5F5F5', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', position: 'absolute', top: 20, right: 20, justifyContent: 'center'}}>
                    <img src={closeIcon}/>
                </div>
            </TouchableOpacity>
        </div>
        {getContent()}
    </div>

}
