import React from "react";
import styles from "./BonusSystem.module.css"
import {Helmet} from "react-helmet";
import Header from "./Header";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import {useWindowDimensions} from "../utils/hooks";
import BreadCrumbs from "./BreadCrumbs";
import SText from "./SText";
import gift from "../assets/images/giftVoucher.jpg"

export default () => {

    const {windowDimensions: {width}} = useWindowDimensions(600)

    const breadCrumbs = [
        {
            name: 'Ð­Ð»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ñ‹Ðµ ÑÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚Ñ‹',
            to: '/gift-voucher'
        }
    ]

    return <div>
        <Helmet>
            <title>Ð­Ð»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ñ‹Ðµ ÑÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚Ñ‹</title>
            <meta name="description" content='Ð­Ð»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ñ‹Ðµ ÑÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚Ñ‹'/>
            <meta name="keywords" content='Ð­Ð»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ñ‹Ðµ ÑÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚Ñ‹'/>
        </Helmet>
        <Header/>
        {width < 992 && <MobileExtraInfo/>}
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BreadCrumbs _items={breadCrumbs}/>

                <div style={{marginBottom: width > 762 ? 28 : 18}}><img style={{width: "100%", maxWidth: 532}} src={gift}/></div>


                <div style={{marginBottom: width > 762 ? 28 : 18}}><SText fontSize={16} fontWeight={550}
                                                                          lineHeight={18}>Ð­Ð»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ñ‹Ðµ ÑÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚Ñ‹ SeasonMarket â€”
                    Ð¸Ð´ÐµÐ°Ð»ÑŒÐ½Ñ‹Ð¹ Ð²Ñ‹Ð±Ð¾Ñ€ Ð½Ð° Ð»ÑŽÐ±Ð¾Ð¹ Ð¿Ñ€Ð°Ð·Ð´Ð½Ð¸Ðº, Ð¿Ð¾ Ð¿Ð¾Ð²Ð¾Ð´Ñƒ Ð¸ Ð±ÐµÐ·. Ð Ð°Ð´ÑƒÐ¹Ñ‚Ðµ Ñ€Ð¾Ð´Ð½Ñ‹Ñ…, ÑƒÐ´Ð¸Ð²Ð»ÑÐ¹Ñ‚Ðµ Ð´Ñ€ÑƒÐ·ÐµÐ¹, Ð¿Ð¾Ð·Ð´Ñ€Ð°Ð²Ð»ÑÐ¹Ñ‚Ðµ
                    ÐºÐ¾Ð»Ð»ÐµÐ³!</SText></div>

                <div style={{marginBottom: width > 762 ? 22 : 42}}><SText fontSize={16} fontWeight={900}
                                                                          lineHeight={15}>Ð£ÑÐ»Ð¾Ð²Ð¸Ñ Ð¿Ñ€Ð¸Ð¼ÐµÐ½ÐµÐ½Ð¸Ñ:</SText>
                </div>

                <div className={styles.dot} style={{marginBottom: width > 762 ? 27 : 22}}><SText fontSize={16}
                                                                                                 fontWeight={550}
                                                                                                 lineHeight={18}>ÐŸÐ¾Ð´Ð°Ñ€Ð¾Ñ‡Ð½Ñ‹Ð¹
                    ÑÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚ Ð¿Ñ€ÐµÐ´Ð¾ÑÑ‚Ð°Ð²Ð»ÑÐµÑ‚ Ð²Ð»Ð°Ð´ÐµÐ»ÑŒÑ†Ñƒ Ð¿Ñ€Ð°Ð²Ð¾ Ð¿Ñ€Ð¸Ð¾Ð±Ñ€ÐµÑ‚ÐµÐ½Ð¸Ñ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð² Ð½Ð° ÑÑƒÐ¼Ð¼Ñƒ, ÑÐºÐ²Ð¸Ð²Ð°Ð»ÐµÐ½Ñ‚Ð½ÑƒÑŽ Ð½Ð¾Ð¼Ð¸Ð½Ð°Ð»Ñƒ
                    Ð¿Ð¾Ð´Ð°Ñ€Ð¾Ñ‡Ð½Ð¾Ð³Ð¾ ÑÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚Ð°.</SText></div>
                <div className={styles.dot} style={{marginBottom: width > 762 ? 27 : 22}}><SText fontSize={16}
                                                                                                 fontWeight={550}
                                                                                                 lineHeight={18}>ÐŸÑ€Ð¸
                    Ð¾Ð¿Ð»Ð°Ñ‚Ðµ Ð¿Ð¾Ð´Ð°Ñ€Ð¾Ñ‡Ð½Ñ‹Ð¼ ÑÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚Ð¾Ð¼ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð², Ð¾Ð±Ñ‰ÐµÐ¹ ÑÑ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒÑŽ Ð½Ð¸Ð¶Ðµ ÐµÐ³Ð¾ Ð½Ð¾Ð¼Ð¸Ð½Ð°Ð»Ð°, Ñ€Ð°Ð·Ð½Ð¸Ñ†Ð° Ð² Ð´ÐµÐ½ÐµÐ¶Ð½Ð¾Ð¼
                    ÑÐºÐ²Ð¸Ð²Ð°Ð»ÐµÐ½Ñ‚Ðµ Ð½Ðµ ÐºÐ¾Ð¼Ð¿ÐµÐ½ÑÐ¸Ñ€ÑƒÐµÑ‚ÑÑ.</SText></div>
                <div className={styles.dot} style={{marginBottom: width > 762 ? 27 : 22}}><SText fontSize={16}
                                                                                                 fontWeight={550}
                                                                                                 lineHeight={18}>ÐŸÑ€Ð¸
                    Ð¾Ð¿Ð»Ð°Ñ‚Ðµ Ð¿Ð¾Ð´Ð°Ñ€Ð¾Ñ‡Ð½Ñ‹Ð¼ ÑÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚Ð¾Ð¼ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð², Ð¾Ð±Ñ‰Ð°Ñ ÑÑ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ñ… Ð¿Ñ€ÐµÐ²Ñ‹ÑˆÐ°ÐµÑ‚ Ð½Ð¾Ð¼Ð¸Ð½Ð°Ð» Ð¿Ð¾Ð´Ð°Ñ€Ð¾Ñ‡Ð½Ð¾Ð³Ð¾
                    ÑÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚Ð°, Ð¿Ð¾ÐºÑƒÐ¿Ð°Ñ‚ÐµÐ»ÑŒ Ð´Ð¾Ð¿Ð»Ð°Ñ‡Ð¸Ð²Ð°ÐµÑ‚ Ð¾ÑÑ‚Ð°Ð²ÑˆÑƒÑŽÑÑ ÑÑƒÐ¼Ð¼Ñƒ.</SText></div>
                <div className={styles.dot} style={{marginBottom: width > 762 ? 27 : 22}}><SText fontSize={16}
                                                                                                 fontWeight={550}
                                                                                                 lineHeight={18}>Ð¡ÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚
                    Ð´ÐµÐ¹ÑÑ‚Ð²ÑƒÐµÑ‚ Ð² Ñ‚ÐµÑ‡ÐµÐ½Ð¸Ðµ Ð³Ð¾Ð´Ð° ÑÐ¾ Ð´Ð½Ñ Ð¿Ð¾ÐºÑƒÐ¿ÐºÐ¸.</SText></div>
                <div className={styles.dot} style={{marginBottom: width > 762 ? 30 : 30}}><SText fontSize={16}
                                                                                                 fontWeight={550}
                                                                                                 lineHeight={18}>Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ
                    Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´ Ð¿Ñ€Ð¸ Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÐµÐ½Ð¸Ð¸ Ð·Ð°ÐºÐ°Ð·Ð° Ð² Ð¿Ð¾Ð»Ðµ Â«ÐŸÑ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´Â».</SText></div>
            </div>
        </div>
    </div>
}