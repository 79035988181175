import React, {useContext, useEffect, useState} from "react";
import styles from "./DeliveryAndPayment.module.css"
import SText from "./SText";
import Header from "./Header";
import mapPin from "../assets/images/MapPin.svg"
import {useCoolDown, useWindowDimensions} from "../utils/hooks";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import DataContext from "../data/DataContext";
import {getGeoSuggestions, validateLocation} from "../api";
import TouchableOpacity from "./TouchableOpacity";
import cn from "classnames";
import Colors from "../utils/Colors";
import tick from "../assets/images/tick.svg"
import BreadCrumbs from "./BreadCrumbs";
import {Helmet} from "react-helmet";
import {RegionContext} from "../App";

const Interval = ({price = '150 Ñ€', day = 'ÐŸÐ¾Ð½ÐµÐ´ÐµÐ»ÑŒÐ½Ð¸Ðº'}) => {

    return <div className={styles.intervalWrapper}>
        <SText style={{width: '97px'}} fontSize={14} fontWeight={400} lineHeight={15} color={'#5A5A5A'}>{day}</SText>
        {/*        <SText fontSize={14} fontWeight={price === 'Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾' ? 550 : 400} lineHeight={15}
               color={price === 'Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾' ? '#2DCC70' : '#5A5A5A'}>{price}</SText>*/}
        <SText fontSize={14} fontWeight={400} lineHeight={15} color={'#5A5A5A'}>00:00 - 23:59</SText>
    </div>
}

const GeoInput = ({visible, setVisible}) => {

    const [value, setValue] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [candidate, setCandidate] = useState({valid: false})
    const {userLocation} = useContext(DataContext)
    const {windowDimensions: {width}} = useWindowDimensions()
    const [suggestionsVisible, setSuggestionsVisible] = useState(false)

    async function _getSuggestions(input) {
        if (input === '')
            return setSuggestions([])

        const response = await getGeoSuggestions(input)
        setSuggestions(response)
    }

    const getSuggestions = useCoolDown(_getSuggestions)

    async function handleSuggestionSelect(name, point) {
        setValue(name)
        const response = await validateLocation(point.lat, point.lon) || false
        setCandidate({
            name, point, valid: response.validate || false
        })
    }

    async function onSubmit() {
        if (!candidate.valid) return
        await userLocation.update(candidate.point, candidate.name)
    }

    useEffect(() => {
        getSuggestions(value)
    }, [value])

    useEffect(() => {
        if (candidate.valid)
            setVisible(true)
        else setVisible(false)
    }, [candidate])

    return <div style={{position: 'relative', width: '100%', maxWidth: 817, display: 'flex', flexDirection: 'column',}}>
        <div style={{
            marginBottom: visible ? '45px' : '0',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth: 817,
            position: 'relative',
        }}>
            <img src={mapPin} style={{marginRight: width > 475 ? '14px' : '2px',}}/>
            <input value={value} onChange={e => {
                setValue(e.target.value);
                setSuggestionsVisible(true)
            }}
                   placeholder={'Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ Ð²Ð°Ñˆ Ð°Ð´Ñ€ÐµÑ, Ð¿Ð¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°'}
                   className={styles.inputSearch} type="text"
                   onBlur={() => setTimeout(() => setSuggestionsVisible(false), 200)}
            />
            {candidate.valid ? <img className={styles.tick} src={tick}/> : null}
        </div>
        <div style={{alignSelf: 'flex-end', marginTop: '3px'}}>
            <SText fontWeight={700} fontSize={12}
                   color={Colors.red}>{(!candidate.name || candidate.valid) ? '' : 'ÐœÑ‹ Ð½Ðµ Ð´Ð¾ÑÑ‚Ð°Ð²Ð»ÑÐµÐ¼ Ð² Ð²Ñ‹Ð±Ñ€Ð°Ð½Ð½Ñ‹Ð¹ Ñ€ÐµÐ³Ð¸Ð¾Ð½'}</SText>
        </div>
        {suggestions.length !== 0 && suggestionsVisible && <div
            className={cn(styles.suggestionsList, width > 475 ? styles.suggestDesc : styles.suggestMob)}>{suggestions.map(suggestion => {
            return <TouchableOpacity className={styles.suggestionItem}
                                     onClick={() => handleSuggestionSelect(suggestion.name, suggestion.point)}>
                <SText fontSize={18} fontWeight={400}>
                    {suggestion?.name}
                </SText>
            </TouchableOpacity>
        })}</div>}
    </div>
}

const Calculation = () => {

    const {windowDimensions: {width}} = useWindowDimensions()

    const [visible, setVisible] = useState(false)

    return <div style={{paddingBottom: !visible ? '25px' : width >= 475 ? '85px' : '38px'}} className={styles.calc}>
        <div style={width > 475 ? {marginBottom: '18px'} : {marginLeft: '16px', marginBottom: '18px'}}><SText
            fontSize={width > 475 ? 18 : 16} fontWeight={900}>Ð Ð°ÑÑ‡Ñ‘Ñ‚ ÑÑ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚Ð¸ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸</SText>
        </div>
        <GeoInput visible={visible} setVisible={setVisible}/>
        {visible
            ? <div className={styles.deliveryWrapper}>
                <div className={styles.aboutPrice}>
                    <div className={styles.sum}>
                        <SText style={{marginBottom: 20}} fontSize={14} fontWeight={400} lineHeight={15}
                               color={'#5a5a5a'}>Ð¡ÑƒÐ¼Ð¼Ð° Ð·Ð°ÐºÐ°Ð·Ð°:</SText>
                        <SText style={{marginBottom: 20}} fontSize={16} fontWeight={700} lineHeight={16}
                               color={'#5a5a5a'}>3000 - 4000 Ñ€</SText>
                        <SText fontSize={16} fontWeight={700} lineHeight={16} color={'#5a5a5a'}>Ð¾Ñ‚ 4000 Ñ€</SText>
                    </div>
                    <div className={styles.sumPrice}>
                        <SText style={{marginBottom: 20}} fontSize={14} fontWeight={400} lineHeight={15}
                               color={'#5a5a5a'}>Ð¦ÐµÐ½Ð° Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸:</SText>
                        <SText style={{marginBottom: 20}} fontSize={16} fontWeight={700} lineHeight={16}
                               color={'#2DCC70'}>150 Ñ€</SText>
                        <SText fontSize={16} fontWeight={700} lineHeight={16} color={'#2DCC70'}>Ð‘ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾</SText>
                    </div>
                </div>
                {width >= 789 ? <div className={styles.verticalLine}/> : <div className={styles.horizontalLine}/>}
                <div>
                    <div style={{marginBottom: '20px'}}><SText fontSize={14} fontWeight={400} lineHeight={15}
                                                               color={'#5a5a5a'}>Ð¡Ñ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ Ð¸ Ð¸Ð½Ñ‚ÐµÑ€Ð²Ð°Ð»Ñ‹ Ð¿Ð¾ Ð´Ð½ÑÐ¼
                        Ð½ÐµÐ´ÐµÐ»Ð¸:</SText></div>
                    <div>
                        <Interval day={'ÐŸÐ¾Ð½ÐµÐ´ÐµÐ»ÑŒÐ½Ð¸Ðº'} price={'Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾'}/>
                        <Interval day={'Ð’Ñ‚Ð¾Ñ€Ð½Ð¸Ðº'} price={'Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾'}/>
                        <Interval day={'Ð¡Ñ€ÐµÐ´Ð°'} price={'Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾'}/>
                        <Interval day={'Ð§ÐµÑ‚Ð²ÐµÑ€Ð³'} price={'Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾'}/>
                        <Interval day={'ÐŸÑÑ‚Ð½Ð¸Ñ†Ð°'} price={'Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾'}/>
                        <Interval day={'Ð¡ÑƒÐ±Ð±Ð¾Ñ‚Ð°'} price={'Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾'}/>
                        <Interval day={'Ð’Ð¾ÑÐºÑ€ÐµÑÐµÐ½ÑŒÐµ'} price={'Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾'}/>
                    </div>
                </div>
            </div>
            : null
        }
    </div>
}

const StaticInfo = () => {

    const {windowDimensions: {width}} = useWindowDimensions()
    const regionData = useContext(RegionContext)
    debugger

    return <div className={styles.infoWrapper}>

        <div style={{margin: '20px 0', width: width <= 500 ? '100%' : 500}}>
            <a href={regionData.region == 78 ? "https://yandex.ru/maps/?um=constructor%3A5f4127c3f90ace8825b996925803c34dd13abb1b81a4f7f088e7651dd02aa6a7&amp;source=constructorStatic" : "https://yandex.ru/maps/?um=constructor%3A7097259d9d535556ba6e0ad4e2b25dc39ab1ca876aa25e3674266b7f951b7ef4&amp;source=constructorStatic"}
               target="_blank"><img
                src={regionData.region == 78 ? "https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3A5f4127c3f90ace8825b996925803c34dd13abb1b81a4f7f088e7651dd02aa6a7&amp;width=600&amp;height=450&amp;lang=ru_RU" : "https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3A7097259d9d535556ba6e0ad4e2b25dc39ab1ca876aa25e3674266b7f951b7ef4&amp;width=500&amp;height=400&amp;lang=ru_RU"}
                alt="" style={{border: 0, width: '100%'}}/></a>
        </div>

        <div className={styles.payment}>
            <div style={{marginBottom: '20px'}}><SText fontSize={24} fontWeight={900}>ÐžÐ¿Ð»Ð°Ñ‚Ð°</SText></div>

            <div style={{marginBottom: '10px'}}><SText fontSize={14} fontWeight={400}>ÐœÐ¸Ð½Ð¸Ð¼Ð°Ð»ÑŒÐ½Ð°Ñ ÑÑƒÐ¼Ð¼Ð° Ð·Ð°ÐºÐ°Ð·Ð° - 3000
                Ñ€ÑƒÐ±Ð»ÐµÐ¹</SText></div>
            <div style={{marginBottom: '40px'}}><SText fontSize={14} fontWeight={400}>Ð‘ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð°Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð¾Ñ‚ 4000 Ñ€ÑƒÐ±Ð»ÐµÐ¹</SText></div>
            <div style={{marginBottom: '18px'}}><SText fontSize={18} fontWeight={700}>Ð¡Ð¿Ð¾ÑÐ¾Ð±Ñ‹ Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹:</SText></div>
            <div>
                <ul>
                    <li className={styles.li}><SText fontSize={14} fontWeight={400}>Ð‘Ð°Ð½ÐºÐ¾Ð²ÑÐºÐ¾Ð¹ ÐºÐ°Ñ€Ñ‚Ð¾Ð¹ Ð¿Ñ€Ð¸ Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÐµÐ½Ð¸Ð¸
                        Ð·Ð°ÐºÐ°Ð·Ð° Ð²
                        Ð¸Ð½Ñ‚ÐµÑ€Ð½ÐµÑ‚-Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½Ðµ.</SText></li>
                    <li className={styles.li}><SText fontSize={14} fontWeight={400}>ÐžÐ¿Ð»Ð°Ñ‚Ð° ÐºÑƒÑ€ÑŒÐµÑ€Ñƒ Ð½Ð°Ð»Ð¸Ñ‡Ð½Ñ‹Ð¼Ð¸.</SText>
                    </li>
                    <li className={styles.li}><SText fontSize={14} fontWeight={400}>ÐžÐ¿Ð»Ð°Ñ‚Ð° ÐºÑƒÑ€ÑŒÐµÑ€Ñƒ ÐºÐ°Ñ€Ñ‚Ð¾Ð¹ Ñ Ð¿Ð¾Ð¼Ð¾Ñ‰ÑŒÑŽ QR-
                        ÐºÐ¾Ð´Ð° Ð½Ð° Ð½Ð°ÐºÐ»Ð°Ð´Ð½Ð¾Ð¹
                        Ð¸Ð»Ð¸ Ð¿Ð¾ ÑÑÑ‹Ð»ÐºÐµ.</SText></li>
                </ul>
            </div>
        </div>
        <div className={styles.hr}/>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <div style={width > 1000 ? {margin: '0 100px 23px 0'} : {margin: '0 0 18px 0'}}>
                <div className={styles.info}>
                    <SText style={{marginBottom: 16}} fontSize={18} fontWeight={700}>ÐšÐ°Ðº Ð¼Ñ‹ Ð´Ð¾ÑÑ‚Ð°Ð²Ð»ÑÐµÐ¼ Ð·Ð°ÐºÐ°Ð·?</SText>
                    <SText lineHeight={16} fontSize={14} fontWeight={400}>Ð’ÑÐµ Ð°Ð²Ñ‚Ð¾Ð¼Ð¾Ð±Ð¸Ð»Ð¸ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸ Ð¾Ð±Ð¾Ñ€ÑƒÐ´Ð¾Ð²Ð°Ð½Ñ‹
                        ÑÐ¿ÐµÑ†Ð¸Ð°Ð»ÑŒÐ½Ñ‹Ð¼Ð¸
                        ÐºÐ»Ð¸Ð¼Ð°Ñ‚Ð¸Ñ‡ÐµÑÐºÐ¸Ð¼Ð¸ ÑƒÑÑ‚Ð°Ð½Ð¾Ð²ÐºÐ°Ð¼Ð¸ (Ñ€ÐµÑ„Ñ€Ð¸Ð¶ÐµÑ€Ð°Ñ‚Ð¾Ñ€Ð°Ð¼Ð¸) Ð´Ð»Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð². Ð­Ñ‚Ð¾ ÑÐ¾Ñ…Ñ€Ð°Ð½ÑÐµÑ‚ ÑÐ²ÐµÐ¶ÐµÑÑ‚ÑŒ Ð¸
                        Ð½Ð°Ñ‚ÑƒÑ€Ð°Ð»ÑŒÐ½Ñ‹Ð¹ Ð²ÐºÑƒÑ Ñƒ Ð½Ð°ÑˆÐ¸Ñ… Ñ„ÐµÑ€Ð¼ÐµÑ€ÑÐºÐ¸Ñ… Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð²!

                        ÐšÑƒÑ€ÑŒÐµÑ€ Ð¿Ð¾Ð·Ð²Ð¾Ð½Ð¸Ñ‚ Ð²Ð°Ð¼ Ð¿Ð¾ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ñƒ Ð½ÐµÐ¿Ð¾ÑÑ€ÐµÐ´ÑÑ‚Ð²ÐµÐ½Ð½Ð¾ Ð¿ÐµÑ€ÐµÐ´ Ð¿Ñ€Ð¸ÐµÐ·Ð´Ð¾Ð¼.</SText>
                </div>
                <div className={styles.info}>
                    <SText style={{marginBottom: 16}} fontSize={18} fontWeight={700}>Ð¡ÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚Ñ‹ ÐºÐ°Ñ‡ÐµÑÑ‚Ð²Ð°</SText>
                    <SText lineHeight={16} fontSize={14} fontWeight={400}>ÐŸÐ¾ Ð·Ð°Ð¿Ñ€Ð¾ÑÑƒ Ð¼Ñ‹ Ð¼Ð¾Ð¶ÐµÐ¼ Ð²Ð»Ð¾Ð¶Ð¸Ñ‚ÑŒ ÑÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚
                        ÐºÐ°Ñ‡ÐµÑÑ‚Ð²Ð° Ð½Ð°
                        Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°ÐµÐ¼Ñ‹Ðµ Ð²Ð°Ð¼Ð¸ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹</SText>
                </div>
                <div className={styles.info}>
                    <SText style={{marginBottom: 16}} fontSize={18} fontWeight={700}>Ð£Ð¿Ð°ÐºÐ¾Ð²ÐºÐ° Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð²:</SText>
                    <SText lineHeight={16} fontSize={14} fontWeight={400}>Ð”Ð»Ñ ÐºÐ°Ð¶Ð´Ð¾Ð³Ð¾ Ð·Ð°ÐºÐ°Ð·Ð° Ð¾Ð´Ð½Ð¾Ñ€Ð°Ð·Ð¾Ð²Ð°Ñ
                        ÑÐºÐ¾Ð»Ð¾Ð³Ð¸Ñ‡ÐµÑÐºÐ¸-Ñ‡Ð¸ÑÑ‚Ð°Ñ Ñ‚Ð°Ñ€Ð°:
                        ÐºÑ€Ð°Ñ„Ñ‚Ð¾Ð²Ñ‹Ð¹ ÐºÐ¾Ñ€Ð¾Ð±, ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ð¹ Ð¿Ñ€Ð¸Ð³Ð¾Ð´Ð¸Ñ‚ÑÑ Ð² Ñ…Ð¾Ð·ÑÐ¹ÑÑ‚Ð²Ðµ. ÐŸÑ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ ÑƒÐ¿Ð°ÐºÐ¾Ð²Ð°Ð½Ñ‹ Ð»Ð¸Ð±Ð¾ Ð² Ñ‚ÐµÑ€Ð¼Ð¸Ñ‡ÐµÑÐºÐ¸Ðµ Ð²Ð°ÐºÑƒÑƒÐ¼Ð½Ñ‹Ðµ
                        Ð¿Ð°ÐºÐµÑ‚Ñ‹, Ð»Ð¸Ð±Ð¾ Ð² Ð±ÑƒÐ¼Ð°Ð¶Ð½Ñ‹Ðµ Ð¿Ð°ÐºÐµÑ‚Ñ‹, Ð° Ð½Ð° ÐºÐ°Ð¶Ð´Ð¾Ð¼ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ðµ Ð¼Ñ‹ Ð¿Ð¾Ð´Ð¿Ð¸ÑÑ‹Ð²Ð°ÐµÐ¼ Ñ‚Ð¾Ñ‡Ð½Ñ‹Ð¹ ÑÑ€Ð¾Ðº
                        Ð³Ð¾Ð´Ð½Ð¾ÑÑ‚Ð¸.</SText>
                </div>
            </div>
            <div>
                <div className={styles.info}>
                    <SText style={{marginBottom: 16}} fontSize={18} fontWeight={700}>Ð’ÐµÑ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð²</SText>
                    <SText lineHeight={16} fontSize={14} fontWeight={400}>ÐœÑ‹ Ñ„Ð°ÑÑƒÐµÐ¼ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ Ð¸Ð½Ð´Ð¸Ð²Ð¸Ð´ÑƒÐ°Ð»ÑŒÐ½Ð¾ Ð¿Ð¾Ð´ Ð·Ð°ÐºÐ°Ð·,
                        Ð¿Ð¾ÑÑ‚Ð¾Ð¼Ñƒ Ð²Ð¾Ð·Ð¼Ð¾Ð¶Ð½Ð°
                        Ð¿Ð¾Ð³Ñ€ÐµÑˆÐ½Ð¾ÑÑ‚ÑŒ Ð¿Ð¾ Ð²ÐµÑÑƒ Ð¸ Ð¸Ð·Ð¼ÐµÐ½ÐµÐ½Ð¸Ðµ Ñ†ÐµÐ½Ñ‹ Ð² Ð¿Ñ€ÐµÐ´ÐµÐ»Ð°Ñ… 10%.</SText>
                </div>
                <div className={styles.info}>
                    <SText style={{marginBottom: 16}} fontSize={18} fontWeight={700}>Ð¦ÐµÐ½Ñ‹ Ð¸ ÐºÐ¾Ð¼Ð¿Ð»ÐµÐºÑ‚Ð°Ñ†Ð¸Ñ Ð·Ð°ÐºÐ°Ð·Ð°</SText>
                    <SText lineHeight={16} fontSize={14} fontWeight={400}>Ð’Ñ‹ Ð²Ð¸Ð´Ð¸Ñ‚Ðµ Ñ†ÐµÐ½Ñ‹ Ð¸ Ð²ÐµÑ Ð½Ð° ÐºÐ°Ð¶Ð´Ð¾Ð¼ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ðµ. Ð’
                        ÐºÐ°Ð¶Ð´Ñ‹Ð¹ Ð·Ð°ÐºÐ°Ð· Ð¼Ñ‹
                        Ð²ÐºÐ»Ð°Ð´Ñ‹Ð²Ð°ÐµÐ¼ Ð½Ð°ÐºÐ»Ð°Ð´Ð½ÑƒÑŽ Ñ ÑÐ¾ÑÑ‚Ð°Ð²Ð¾Ð¼ ÐºÐ¾Ñ€Ð·Ð¸Ð½Ñ‹ Ð¸ Ð´ÐµÑ‚Ð°Ð»ÑÐ¼Ð¸ Ð·Ð°ÐºÐ°Ð·Ð°.</SText>
                </div>
                <div className={styles.info}>
                    <SText style={{marginBottom: 16}} fontSize={18} fontWeight={700}>Ð“Ð°Ñ€Ð°Ð½Ñ‚Ð¸Ñ Ð´Ð¾Ð±Ñ€Ð¾Ð³Ð¾ Ð¾Ñ‚Ð½Ð¾ÑˆÐµÐ½Ð¸Ñ:</SText>
                    <SText lineHeight={16} fontSize={14} fontWeight={400}>Ð•ÑÐ»Ð¸ Ð²Ð°Ð¼ Ð½Ðµ Ð¿Ð¾Ð½Ñ€Ð°Ð²Ð¸Ñ‚ÑÑ ÐºÐ°Ñ‡ÐµÑÑ‚Ð²Ð¾ Ð¸Ð»Ð¸ ÑÐ²ÐµÐ¶ÐµÑÑ‚ÑŒ
                        Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð° Ð²Ñ‹
                        Ð¼Ð¾Ð¶ÐµÑ‚Ðµ Ð¾Ñ‚ÐºÐ°Ð·Ð°Ñ‚ÑŒÑÑ Ð¾Ñ‚ Ð½ÐµÐ³Ð¾ Ð¿Ñ€Ð¸ Ð¿Ñ€Ð¸ÐµÐ¼Ðµ Ð·Ð°ÐºÐ°Ð·Ð°. ÐŸÐ¾Ð·Ð²Ð¾Ð½Ð¸Ñ‚Ðµ Ð½Ð°ÑˆÐµÐ¼Ñƒ Ð¼ÐµÐ½ÐµÐ´Ð¶ÐµÑ€Ñƒ, Ð¸ Ð¼Ñ‹ Ð²ÐµÑ€Ð½ÐµÐ¼ Ð²Ð°Ð¼ 100%
                        ÑÑ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚Ð¸ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð°. <br/> ÐŸÐ¾ Ð²Ð°ÑˆÐµÐ¼Ñƒ Ð·Ð°Ð¿Ñ€Ð¾ÑÑƒ Ð¼Ñ‹ Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾ Ð·Ð°Ð¼ÐµÐ½Ð¸Ð¼ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚, ÐµÑÐ»Ð¸ Ñ Ð½Ð¸Ð¼ Ñ‡Ñ‚Ð¾-Ñ‚Ð¾
                        ÑÐ»ÑƒÑ‡Ð¸Ð»Ð¾ÑÑŒ Ð¿Ñ€Ð¸ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐµ.</SText>
                </div>
            </div>
        </div>
    </div>
}

export default () => {

    const {windowDimensions: {width}} = useWindowDimensions()
    const breadCrumbs = [
        {
            name: 'Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð¸ Ð¾Ð¿Ð»Ð°Ñ‚Ð°',
            to: '/delivery-and-payment'
        }
    ]

    return <div style={{background: '#FCFCFE'}}>
        <Helmet>
            <title>Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð¸ Ð¾Ð¿Ð»Ð°Ñ‚Ð°</title>
            <meta name="description" content='Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð¸ Ð¾Ð¿Ð»Ð°Ñ‚Ð°'/>
            <meta name="keywords" content='Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð¸ Ð¾Ð¿Ð»Ð°Ñ‚Ð°'/>
        </Helmet>
        <Header/>
        {width < 992 && <MobileExtraInfo/>}
        <div className={styles.container}>
            <BreadCrumbs _items={breadCrumbs}/>
            <div style={{marginBottom: '14px'}}><SText fontSize={24} fontWeight={900}>Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ°</SText></div>
            <div className={styles.green}>
                {
                    width >= 425
                        ? <SText fontSize={16} fontWeight={900} color={'#fff'}>Ð•Ð¶ÐµÐ´Ð½ÐµÐ²Ð½Ð¾, 7 Ð´Ð½ÐµÐ¹ Ð²
                            Ð½ÐµÐ´ÐµÐ»ÑŽ, Ð²&nbsp;Ð²Ñ‹Ñ…Ð¾Ð´Ð½Ñ‹Ðµ Ð¸
                            Ð¿Ñ€Ð°Ð·Ð´Ð½Ð¸ÐºÐ¸, ÐºÑ€ÑƒÐ³Ð»Ð¾ÑÑƒÑ‚Ð¾Ñ‡Ð½Ð¾.</SText>
                        : <SText fontSize={16} fontWeight={900} color={'#fff'}>Ð•Ð¶ÐµÐ´Ð½ÐµÐ²Ð½Ð¾, 7 Ð´Ð½ÐµÐ¹ Ð²
                            Ð½ÐµÐ´ÐµÐ»ÑŽ, Ð²&nbsp;Ð²Ñ‹Ñ…Ð¾Ð´Ð½Ñ‹Ðµ Ð¸
                            Ð¿Ñ€Ð°Ð·Ð´Ð½Ð¸ÐºÐ¸, <br/> ÐºÑ€ÑƒÐ³Ð»Ð¾ÑÑƒÑ‚Ð¾Ñ‡Ð½Ð¾.</SText>
                }
            </div>
            <Calculation/>
            <StaticInfo/>
        </div>
    </div>
}
