import React, {useContext, useEffect, useState} from "react";
import {useOfflineField, useStateField} from "../utils/hooks";
import {
  appendPromoCode,
  createAddress,
  getBanners,
  getCart,
  getCatalogue,
  getDeliverySlots,
  getProductById,
  getUserInfo,
  makeOrder,
  pushCart, removePromoCode,
  setLocation, updateAddress,
  updateCard,
  updateUserInfo,
} from "../api";
import {DOMAIN_URL} from "../config";
import {categoryColors, idToColor} from "../utils/Colors";
import ym from "react-yandex-metrika";
import {RegionContext} from "../App";
import {isInMoscow} from "../utils/regionDeterminer";

const defaultStateField = {
  data: {},
  load: async () => {
  },
  get: async () => {
  },
  update: async () => {
  },
  remove: async () => {
  },
};

const defaultOfflineField = {
  data: null,
  get: ()=>{},
  set: (value)=>value
}

const defaultMethod = async () => {};

const AsyncStorage = {}

/*
* basketAmount
* deliveryPrice
* productList: [
*   {product_id, quantity, price}
* ]
* promocode: ''
* */

const cartShape = {
  basketAmount: 0,
  deliveryPrice: 0,
  productList: [],
  promocode: ''
}

const useOfflineCart = (getProductById) => {
  const [state, setState] = useState()
  const [read, setRead] = useState(false)


  function pushToLS (list) {
    if (read)
      localStorage.setItem('localCart', JSON.stringify(list))
  }


  function getFromLS () {
    const savedCart = JSON.parse(localStorage.getItem('localCart'))

    setState(prev => ({
      ...prev,
      productList: savedCart
    }))
    setTimeout(()=>{
      processCart()
    }, 200)
  }

  function addProduct (product_id, quantity) {
    setState(prev => {
      const positionIndex = prev.productList?.findIndex(pos => pos.product_id == product_id)
      return {
        ...prev,
        productList: positionIndex && positionIndex === -1 ?
            [...(prev?.productList || []), { product_id, quantity }] :
            prev.productList?.map((item) => item.product_id === product_id ? {product_id, quantity} : item)
      }
    })
    processCart()
  }

  function clearCart () {
    setState(cartShape)
    processCart()
  }


  function processCart () {
    setState(prev=>{
      const productList = prev?.productList?.filter(pos=>pos.quantity > 0)?.map(pos=>({
        ...pos,
        price: pos?.price || getProductById(pos.product_id)?.price || 0
      })) || []
      const totalProductsPrice = productList?.reduce((total, pos)=>pos.price * pos.quantity + total, 0) || 0
      const deliveryPrice = totalProductsPrice >= 4000 ? 0 : 150
      const amount_bonuses = totalProductsPrice >= 10000 ? 1000 :  totalProductsPrice >= 7000 ? 500 : 0

      pushToLS(productList)
      setRead(true)
      return {
        productList,
        basketAmount: totalProductsPrice-amount_bonuses,
        deliveryPrice,
        amount_bonuses,
      }
    })
  }

  useEffect(()=>{
    processCart()
    setTimeout(()=>{
      getFromLS()
    }, 300)
  }, [])

  return {
    data: state,
    get: () => state,
    update: addProduct,
    remove: clearCart,
    _load: ()=>{},
    reset: clearCart,
  }
}

const useCart = ({ region, getProductById}) => {
  const isAuthorized = !!localStorage.getItem('token')

  const onlineState = useStateField({
    getter: getCart,
    updater: (...args)=> {
      ym('cartUpdate', {arguments: args})
      return updateCard(...args, region)
    },
    deleter: async () => await pushCart([], region),
    instant: true, _private: true
  })

  const offlineState = useOfflineCart(getProductById)

  async function onAuthorized () {
    const productsToPush = offlineState?.data?.productList?.map(pos=>({productId: pos.product_id, quantity: pos.quantity}))
    if (productsToPush?.length) {
      await pushCart(productsToPush, region)
      await onlineState.get()
      offlineState.remove()
      localStorage.removeItem('localCart')
    }
  }

  useEffect(()=>{
    if (isAuthorized) {
      onAuthorized()
    } else {
      offlineState.remove()
    }
  }, [isAuthorized])

  return isAuthorized ? onlineState : offlineState
}

const shape = {
  auth: defaultStateField,
  userLocation: defaultStateField,
  catalogue: defaultStateField,
  cart: defaultStateField,
  user: defaultStateField,
  deliverySlots: defaultStateField,
  authorize: defaultMethod,
  signOut: defaultMethod,
  setLocation: defaultMethod,
  loadCatalogue: defaultMethod,
  getProduct: defaultMethod,
  offlineCart: defaultOfflineField,
  makeOrder: ({paymentType = 1, scores, deliverySlot = {}, usePayUrl = false})=>{},
  getProductById: defaultMethod,
};

const DataContext = React.createContext(shape);

export function useAppData(isApp = true) {

  const {region, setRegion} = useContext(RegionContext)
  const data = {
    auth: useStateField({
      getter: async () => {
        return isApp ?
            await AsyncStorage.getItem("token") :
            localStorage.getItem("token");
      },
      updater: async (token) => {
        return isApp ?
            await AsyncStorage.setItem("token", token) :
            localStorage.setItem("token", token);
      },
      deleter: async () => {
        return isApp ?
            await AsyncStorage.removeItem("token") :
            localStorage.removeItem("token");
      },
    }),
    userLocation: useStateField({
      getter: async () => {
        const response = await getUserInfo()
        if (!response) return

        const location = response.address?.[(response.address?.length || 1) - 1] || {}

        if (location.address) {
          const newRegion = isInMoscow(location.lat, location.lon) ? 77 : 78
          const savedRegion = localStorage.getItem('region')
          setRegion(savedRegion ? JSON.parse(savedRegion) : newRegion)
        }
        return {
          ...location,
          point: {
            lat: location?.lat,
            lon: location?.lon,
          },
          address: location?.address,
        }
      },
      updater: async (newData) => {
        let resp
        const id = data.userLocation?.data?.id
        if (id)
          resp = await updateAddress({id, ...data.userLocation?.data, ...newData})
        else
          resp = await createAddress(newData)
       // const resp =  await setLocation(point, address)
        await methods.loadDeliverySlots()
        return resp
      },
      deleter: async () => {
      },
      _private: true,
    }),
    catalogue: useStateField({
      getter: async (region) => region ? mapCatsV2(await getCatalogue(region)) : null,
    }),
    /*cart: useStateField({
      getter: getCart,
      updater: (...args)=>updateCard(...args, data.region.data),
      deleter: async () => await pushCart([], data.region.data),
      instant: true, _private: true
    }),*/
    cart: useCart({
      isAuthorized: this?.auth?.data != null,
      region,
      getProductById: (id)=>methods?.getProductSync(id)

    }),
    user: useStateField({
      getter: getUserInfo,
      updater: updateUserInfo,
      _private: true,
    }),
    banners: useStateField({
      getter: getBanners,
    }),
    deliverySlots: useStateField({
      getter: async (region) => region ? await getDeliverySlots(region) : null
    }),
    offlineCart: useOfflineField()
  };

  const methods = {
    authorize: async (token) => {
      return await data.auth.update(token);
    },
    getProductById: async (id) =>  {
      const product = data.catalogue.data?.products?.find(product => product.id == id)
      if (product)
        return product
      else {
        return await methods.getProduct(id)
      }
    },
    getProductByEcoId: (id) =>  {
      const product = data.catalogue.data?.products?.find(product => product.eco_id == id)
      if (product)
        return product
      else {
        return null
      }
    },
    signOut: async () => {
      data.cart.reset()
      data.user.reset()
      await data.auth.remove();
      await data.userLocation.remove();
    },
    setLocation: async (address, point) => {
      await data.userLocation.update(point, address);
    },
    loadCatalogue: async () => {
      await data.catalogue.get(region);
    },
    loadDeliverySlots: async () => {
      return await data.deliverySlots.get(region)
    },
    getProduct: async (id) => {
      const response = await getProductById(region, id) || [null];

      const product = response?.product
      const recommended = response?.recommended
      return {
        ...product,
        price: Number(product?.price),
        old_price: Number(product?.old_price),
        big_image: DOMAIN_URL+(product?.big_image || product?.medium_image),
        medium_image: DOMAIN_URL+(product?.medium_image || product?.small_image),
        small_image: DOMAIN_URL+(product?.small_image || product?.medium_image),
        weight: Number(product?.weight),
        recommended,
      }
    },
    getProductSync: (id) => {
      return data.catalogue.data?.products?.find(product => product.id == id)
    },
    getIsAuth: () => {
      return !!data.auth.data
    },
    makeOrder: async ({...args}) => {
      return await makeOrder({region,  address_id: data.userLocation.data.id, ...args})
    },
    appendPromoCode: async (code) => {
      const response =  await appendPromoCode(region, code)
      await data.cart?.get(region)
      return response
    },
    removePromoCode: async () => {
      const response =  await removePromoCode(region)
      await data.cart?.get(region)
      return response
    }
  };


  useEffect(()=>{
    data.catalogue.get(region)
    data.deliverySlots.get(region)
    data.cart.get(region);
  }, [region])

  useEffect(() => {
    if (data.auth.data) {
      data.userLocation.get();
      data.cart.get(region);
      data.user.get();
      data.deliverySlots.get(region)
    }
  }, [data.auth.data]);


  async function fetchAll() {
    let promises = [];
    const isAuthorized = !!localStorage.getItem('token')
    for (const field in data) {
      if (data[field]?.load && (isAuthorized || !data[field]?._private))
        promises.push(new Promise(resolve => {
          data[field].load().then(resolve);
        }));
    }

    return await Promise.all(promises);
  }


  window.data = {...data, ...methods}

  return { ...data, ...methods, fetchAll };
}

export default DataContext;

const IS_WEB = true //

export function mapCatsV2 (response) { //todo map for app
  if (!response?.data?.categories) return

  const products = response.data.products.map(product=>({
    ...product,
    price: Number(product?.price),
    old_price: Number(product?.old_price),
    medium_image: DOMAIN_URL+(product?.medium_image || product?.small_image),
    small_image: DOMAIN_URL+(product?.small_image || product?.medium_image),
    weight: Number(product?.weight),
    slave_category: JSON.parse(product?.slave_category),
    master_category: JSON.parse(product?.master_category),
  })).filter(product=>/*product?.availableones && product?.availableones > 0 && */product?.price && Number(product?.price) > 0)

  const categories = response.data.categories
      .filter(category=>category?.active === '1')
      .map(category=>({
        ...category,
        firstSubCategoryId: category.subCategories?.[0]?.id || null, // todo
        firstSubCategoryUrl: category.subCategories?.[0]?.url || null, // todo
        subCategories: category.subCategories
            .filter(subCategory=>subCategory?.active === '1')
            .map(subCategory=>({
              ...subCategory,
             // data: products.filter(({group})=>group == subCategory.id), // todo products
              color: idToColor(subCategory.id, categoryColors)
            }))
      }));

  // for SectionList in the mobile app
  const allSubCategories = IS_WEB ?
      [] :
      categories
          .reduce((subCategories, category) => {
            return [
              ...subCategories,
              ...category.subCategories.map(subCategory=>({
                ...subCategory,
                data: products.filter(product=>product?.slave_category?.includes(subCategory.id))
              }))
            ]
          }, [])
          .map((subCategory, index)=>(
              {
                ...subCategory,
                index
              }
          )); // index for scrolling

  // app helper ))
  const indexes = IS_WEB ?
      null :
      allSubCategories.reduce((map, current, index) => {
        return {
          ...map,
          [current.id]: index,
        };
      }, {})


  return {
    categories,
    allSubCategories,
    indexes,
    products,
    threshold: response.region?.min_amount_order_pc
  }
}

export function mapCats(response) { // todo optimize
  const data = response?.data || false
  const isWeb = true

  if (!data) return null

  const products = data.products;
  const categories = data.categories.filter(item => item.parentId === "");
  const subCategories = data.categories.filter(item => item.parentId !== "").map((subcat) => {
    return {
      ...subcat,
      color: idToColor(subcat.id, categoryColors),
      data: products.filter(product => {
        return product.all_groups.includes(subcat.id);
      }).map((product, i) => ({
        index: i,
        id: product.id,
        name: product.title,
        price: product.price,
        old_price: product.old_price,
        image: DOMAIN_URL + product?.thumb, //product.imageUrl?.[0] || null,
        weight: product.weight,
        available: product.availableones,
        measureUnits: product.ed_izm, // ))))
      })),
    };
  }).filter(s => s.data?.length > 0);

  const cats = categories.map((category) => {
    return {
      ...category,
      subcategories: subCategories.filter(subcat => subcat.parentId == category.id).length ?
          subCategories.filter(subcat => subcat.parentId == category.id) :
          [{
            ...category,
            color: idToColor(category.id, categoryColors),
            data: products.filter(product => product.all_groups.includes(category.id))?.map((product, i) => ({
              index: i,
              id: product.id,
              name: product.title,
              price: product.price,
              old_price: product.old_price,
              image: DOMAIN_URL + product?.thumb, //product.imageUrl?.[0] || null,
              weight: product.weight,
              available: product.availableones,
              measureUnits: product.ed_izm,
            })),
          }].filter(s => s.data?.length > 0)
    };
  }).filter(c => c.subcategories?.length > 0).map((p, i) => ({ index: i, ...p }));

  const _cats = cats.map(cat => (
      {
        id: cat.id,
        name: cat.name,
        image: cat.imageUrl,
        subcategories: isWeb ?
            [{name: 'Ð’ÑÐµ',
              id: cat.id+'_all',
              data: cat.subcategories.reduce((total, current)=>{
                return [...total, ...current.data]
              },[])}, ...(cat.subcategories.length > 1 ? cat.subcategories : [])] :
            cat.subcategories,
        firstSubCat: cat.subcategories[0]?.id
      }))

  const subCats = _cats.reduce((subcats, cat) => ([...subcats, ...cat.subcategories]), []);


  const indexes = subCats.reduce((map, current, index) => {
    return {
      ...map,
      [current.id]: index,
    };
  }, {});


  return {
    cats: _cats,
    subCats,
    products, //: subCats.map(({data})=>data).flat(),
    indexes,
  };
}
