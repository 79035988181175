import React from "react";
import styles from "./BonusSystem.module.css"
import {Helmet} from "react-helmet";
import Header from "./Header";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import {useWindowDimensions} from "../utils/hooks";
import BreadCrumbs from "./BreadCrumbs";
import SText from "./SText";

export default () => {

    const {windowDimensions: {width}} = useWindowDimensions(600)

    const breadCrumbs = [
        {
            name: 'Ð˜ÐºÑ€Ð° Ð³Ð¾Ñ€Ð±ÑƒÑˆÐ¸',
            to: '/caviar'
        }
    ]

    return <div>
        <Helmet>
            <title>Ðž SeasonMarket</title>
            <meta name="description" content='Ð˜ÐºÑ€Ð° Ð³Ð¾Ñ€Ð±ÑƒÑˆÐ¸'/>
            <meta name="keywords" content='Ð˜ÐºÑ€Ð° Ð³Ð¾Ñ€Ð±ÑƒÑˆÐ¸'/>
        </Helmet>
        <Header/>
        {width < 992 && <MobileExtraInfo/>}
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BreadCrumbs _items={breadCrumbs}/>
                <div><img style={{maxWidth: 891, marginBottom: 20, width: '100%'}} src={width>=540?"https://api.seasonmarket.ru/images/banners/banner-ikra_v_podarok_do_1512-desk.png":'https://api.seasonmarket.ru/images/banners/banner-ikra_v_podarok_do_1512-mob.png'} alt=""/></div>
                <div style={{marginBottom: width > 762 ? 30 : 30}} className={styles.title}><SText fontSize={24}
                                                                                                   lineHeight={23}
                                                                                                   fontWeight={900}>Ð˜ÐºÑ€Ð° Ð³Ð¾Ñ€Ð±ÑƒÑˆÐ¸</SText></div>

                <div style={{marginBottom: width > 762 ? 28 : 18}}><SText fontSize={16} fontWeight={550} lineHeight={18}>Ð‘Ð°Ð½ÐºÐ° ÐºÑ€Ð°ÑÐ½Ð¾Ð¹ Ð¸ÐºÑ€Ñ‹ Ð³Ð¾Ñ€Ð±ÑƒÑˆÐ¸ 100Ð³  Ð·Ð° 1 Ñ€ÑƒÐ±Ð»ÑŒ Ð¿Ñ€Ð¸ Ð¿ÐµÑ€Ð²Ð¾Ð¼ Ð·Ð°ÐºÐ°Ð·Ðµ Ð² Ð¼Ð¾Ð±Ð¸Ð»ÑŒÐ½Ð¾Ð¼ Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ð¸ Ð¾Ñ‚ 3 Ñ‚Ñ‹Ñ Ñ€ÑƒÐ±Ð»ÐµÐ¹. Ð§Ñ‚Ð¾Ð±Ñ‹ Ð²Ð¾ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÑŒÑÑ Ð¿Ñ€ÐµÐ´Ð»Ð¾Ð¶ÐµÐ½Ð¸ÐµÐ¼, Ð±Ð¾Ð½ÑƒÑÑ‹ Ð½Ðµ Ð¿Ñ€Ð¸Ð¼ÐµÐ½ÑÑŽÑ‚ÑÑ.</SText></div>
                <div style={{marginBottom: width > 762 ? 28 : 18}}><SText fontSize={16} fontWeight={550} lineHeight={18}>Ð”Ð¾Ð±Ð°Ð²Ð»ÑÐ¹Ñ‚Ðµ Ñ‚Ð¾Ð²Ð°Ñ€Ñ‹ Ð² ÐºÐ¾Ñ€Ð·Ð¸Ð½Ñƒ Ð¸ ÑÐ¾Ð²ÐµÑ€ÑˆÐ°Ð¹Ñ‚Ðµ Ð¿Ð¾ÐºÑƒÐ¿ÐºÑƒ, Ð½Ð°Ñ‚ÑƒÑ€Ð°Ð»ÑŒÐ½Ñ‹Ð¹ Ð´ÐµÐ»Ð¸ÐºÐ°Ñ‚ÐµÑ Ð¿Ð¾ ÑÐ¿ÐµÑ†Ð¿Ñ€ÐµÐ´Ð»Ð¾Ð¶ÐµÐ½Ð¸ÑŽ Ð±ÑƒÐ´ÐµÑ‚ Ð´Ð¾Ð±Ð°Ð²Ð»ÐµÐ½ Ð°Ð²Ñ‚Ð¾Ð¼Ð°Ñ‚Ð¸Ñ‡ÐµÑÐºÐ¸.</SText></div>
                <div style={{marginBottom: width > 762 ? 28 : 18}}><SText fontSize={16} fontWeight={550} lineHeight={18}>ÐÐºÑ†Ð¸Ñ Ð´ÐµÐ¹ÑÑ‚Ð²ÑƒÐµÑ‚ Ð´Ð¾ 31 Ð´ÐµÐºÐ°Ð±Ñ€Ñ 2021 Ð³. ÐšÐ¾Ð»Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾ Ñ‚Ð¾Ð²Ð°Ñ€Ð° Ð¾Ð³Ñ€Ð°Ð½Ð¸Ñ‡ÐµÐ½Ð½Ð¾.</SText></div>


            </div>
        </div>
    </div>
}