export const round = (value) => (Math.round(value*100)/100).toFixed(value % 1 === 0 ? 0 : 2 )

const $body = document.querySelector('body');
let scrollPosition = 0;

export const bodyLocker =  {
    enable() {
        return

        scrollPosition = window.pageYOffset;
        $body.style.overflow = 'visible';
       // $body.style.transform = 'translate3d(0,0,0)';
        $body.style.position = 'fixed';
        $body.style.top = `-${scrollPosition}px`;
        $body.style.width = '100%';
    },
    disable() {
        return

        $body.style.removeProperty('overflow');
        $body.style.removeProperty('position');
        $body.style.removeProperty('top');
     //   $body.style.removeProperty('transform');
        $body.style.removeProperty('width');
        window.scrollTo(0, scrollPosition);
    }
};


export function between (min, value, max) {
    return Math.min(max, Math.max(value, min))
}


export function declOfNum(n, text_forms) {
    n = Math.abs(n) % 100;
    let n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 == 1) { return text_forms[0]; }
    return text_forms[2];
}

export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export const handleEnter = (cb) => (e) => {
    if (e.charCode === 13) {
        e.preventDefault()
        cb()
    }
}

export  function removeYear(string) {
    return string.replace(/ [0-9]{4} Ð³\./gm, '')
}

export function insertBetween(array, element){
    return [...array].map((e, i) => i < array.length - 1 ? [e, element] : [e]).reduce((a, b) => a.concat(b))
}
