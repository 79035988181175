import {useContext, useState} from "react";
import * as React from 'react';
import vanillaTextMask from 'vanilla-text-mask';
import {useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";
import DataContext from "../data/DataContext";
import ModalContext, {useModalContext} from "../data/ModalContext";


export const REGEX = {
  EMAIL: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
  PHONE: /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g,
}

export const useForm = (
    {
      initialState = null,
      onCreate=()=>{},
      onUpdate=()=>{},
      requiredFieldNames=[]
    }
) => {
  const [state, setState] = useState(initialState)
  const [editMode, setEditMode] = useState(true)
  const [errors, setErrors] = useState([])


/*
  useEffect(()=>{
    if (initialState !== null) {
      setState(initialState)
      setEditMode(false)
    } else {
      setEditMode(true)
    }
  }, [initialState])
*/

  const onInputChange = (fieldName, validationType) => (text) => {
    setState(prevState=>({...prevState, [fieldName]: text}))
    validate(fieldName, validationType, text)
  }

  const value = (fieldName) =>(state?.[fieldName]?.toString() || '')

  const onCancel = () => {
    setState(initialState)
    setEditMode(false)
  }

  const onSave = async () => {
    if (isSaveDisabled) return
    if (initialState === null)
      await onCreate(state)
    else
      await onUpdate(state)
    setEditMode(false)
  }

  const [saveWaiting, saveWithWaiting] = useWaiting(onSave)

  const isSaveDisabled = requiredFieldNames.reduce((result, fieldName)=>(!state?.[fieldName] || result), false) // || errors.length

  const validate = (fieldName, validationType, text) => {
    let regex
    switch (validationType) {
      case 'email': regex = REGEX.EMAIL; break
      case 'phone': regex = REGEX.PHONE; break
      default: return
    }
    const hasError = !regex.test(text)
    if (hasError)
      setErrors(errors=>[...errors, fieldName])
    else
      setErrors(errors=>errors.filter(error=>(fieldName !== error)))
  }
  const getInputProps = (fieldName, validationType=false) => ({
    onChange: onInputChange(fieldName, validationType),
    value: value(fieldName),
    isSaveDisabled,
    disabled: !editMode,
    onSubmit: saveWithWaiting,
    error: false, //errors.includes(fieldName),
    required: requiredFieldNames.includes(fieldName)
  })

  return {
    disabled: isSaveDisabled,
    editMode,
    _onInputChangeOf: onInputChange,
    _valueOf: value,
    save: saveWithWaiting,
    cancel: onCancel,
    isSaveWaiting: saveWaiting,
    edit: () => setEditMode(true),
    getInputProps
  }
}





export const useEscape = (className, callback) => {
  const containerRef = useRef(null);

  const onOuterClick = (e) => {
    if (e?.target === document.querySelector(`.${className}`)) {
      callback();
    }
  };

  const onEscape = (e) => {
    if (e.keyCode === 27) callback();
  };



  useEffect(() => {
    if (!containerRef.current) return;
    const el = containerRef.current;
    el.addEventListener('click', onOuterClick);
    document.addEventListener('keydown', onEscape);
    return () => {
      el.removeEventListener('click', onOuterClick);
      document.removeEventListener('keydown', onEscape);
    }
  }, [containerRef.current]);

  return containerRef;
};


export function useTextMask(input, options) {
  React.useEffect(function () {
    const handler = input && vanillaTextMask(Object.assign({}, { inputElement: input }, options));

    return function() {
      if (handler) {
        handler.destroy();
      }
    };
  }, [input]);
}

export const useOfflineField = () => {
  const [state, setState] = useState(null)

  const get = () => {
    return state
  }

  return {data: state, set: setState, get}
}

export const useStateField = ({
                                getter = () => {}, creator = () => {},
                                updater = () => {}, deleter = () => {},
                                instant = false, _private=false,
                              }) => {
  const [data, setData] = useState({ data: null, isLoaded: false });

  const get = async (...args) => {
    const response = await getter(...args);
    setData({ data: response || null, isLoaded: true });
  };

  const load = async () => {
    if (!data.isLoaded) {
      await get();
    }
  };

  const create = async (...data) => {
    if (instant) {
      const response = await creator(...data)
      setData({ data: response || null, isLoaded: true });
      return
    }
    await creator(...data);
    await get();
  };

  const update = async (...data) => {
    if (instant) {
      const response = await updater(...data)
      setData({ data: response || null, isLoaded: true });
      return
    }

    await updater(...data);
    await get();
  };

  const remove = async (id) => {
    if (instant) {
      const response = await deleter(id)
      setData({ data: response || null, isLoaded: true });
      return
    }

    await deleter(id);
    await get();
  };

  const reset = () => {
    setData({data: null, isLoaded: false})
  }

  return { ...data, get, create, update, remove, load, reset, _private };
};


export const useWaiting = (func) => {
  const [waiting, setWaiting] = useState(false);

  const waitingFunc = async (...args) => {
    setWaiting(true);
    try {
      const result = await func(...args);
      setWaiting(false);
      return result;
    } catch (err) {
      setTimeout(()=> {
        setWaiting(false);
       // Alert.alert('ÐŸÑ€Ð¾Ð¸Ð·Ð¾ÑˆÐ»Ð° Ð¾ÑˆÐ¸Ð±ÐºÐ°', 'ÐŸÐ¾Ð²Ñ‚Ð¾Ñ€Ð¸Ñ‚Ðµ Ð¿Ð¾Ð¿Ñ‹Ñ‚ÐºÑƒ Ð¿Ð¾Ð·Ð´Ð½ÐµÐµ')
      }, 1000)
      console.log({ err });
      return false;
    }

  };

  return [waiting, waitingFunc];
};


//todo temp
export function fakeTask(timeout = 1000) {
  return new Promise(resolve => {
    setTimeout(() => resolve(true), timeout);
  });
}


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions(mobileThreshold = 400) {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    handleResize()
    return () => {
      try {
        window.removeEventListener('resize', handleResize)
      }
      catch (e) {
        console.log(e)
      }

    };
  }, []);

  return {windowDimensions, isMobile: windowDimensions.width <= mobileThreshold};
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useRestrictions (callback=()=>{}) {
  const {auth} = useContext(DataContext)
  const modals = useContext(ModalContext)

  const isAuth = !!auth.data

  function restrictedCallback (...args) {
    if (isAuth) return callback(...args)
    // todo show auth modal
    modals.open('auth')
  }

  return restrictedCallback
}

export function useCoolDown(callback = (...args) => {}, timeout=600) {

  const [timer, setTimer] = useState(0)

 return (...args) => {
    if (timer)
      clearTimeout(timer)

    const newTimer = setTimeout(() => {
      return callback(...args)
    }, timeout)

    setTimer(newTimer)
  }
}
