import React, {useContext, useEffect, useState} from "react";
import SText from "./SText";
import styles from "./InformAboutAdmission.module.css"
import TouchableOpacity from "./TouchableOpacity";
import closeIcon from "../assets/images/closeButton.svg";
import Input from "./Input";
import RoundedButton from "./RoundedButton";
import Colors from "../utils/Colors";
import {useForm, useWindowDimensions} from "../utils/hooks";
import DataContext from "../data/DataContext";
import placeholder from "../assets/images/productImagePlaceholder.png";
import tick from "../assets/images/greenTick.png"
import ModalContext from "../data/ModalContext";
import {informAboutAdmission} from "../api";

export const ModalTick = () => {

    const {close} = useContext(ModalContext)


    useEffect(() => {
        setTimeout(() => {
            close('admission')
        }, 1200)
    }, [])

    return <div className={styles.modalTick}>
        <div style={{textAlign: 'center'}}><img style={{marginBottom: 18, height: 36, width: 36}} src={tick}/></div>
        <div style={{width: 161, textAlign: 'center'}}><SText fontSize={18} fontWeight={700} lineHeight={22}>ÐœÑ‹ ÑÐ¾Ð¾Ð±Ñ‰Ð¸Ð¼
            Ð²Ð°Ð¼ Ð¾ Ð¿Ð¾ÑÑ‚ÑƒÐ¿Ð»ÐµÐ½Ð¸Ð¸ Ð´Ð°Ð½Ð½Ð¾Ð³Ð¾ Ñ‚Ð¾Ð²Ð°Ñ€Ð°</SText></div>
    </div>
}

export default ({
                    onClose = () => {
                    }, productId = 0
                }) => {

    const {windowDimensions: {width}} = useWindowDimensions()
    const {getProductById, user} = useContext(DataContext)
    const {open: _open} = useContext(ModalContext)

    const [product, setProduct] = useState(null)
    const [emailValue, setEmailValue] = useState(user.data?.email || '')
    const [emailValid, setEmailValid] = useState(false)

    useEffect(() => {
        validateEmail(emailValue)
    }, [])


    async function loadProduct() {
        const data = await getProductById(productId)
        if (data)
            setProduct(data)
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return setEmailValid(re.test(String(email).toLowerCase()))
    }


    useEffect(() => {
        loadProduct()
    }, [productId])

    const name = user.data?.full_name

    const sendOnEmail = () => {
        window.mindbox("async", {
            operation: "SubscriptionTiAbsentGood",
            data: {
                customer: {
                    firstName: name ? `${name}` : '',
                    email: `${emailValue}`,
                    subscriptions: [
                        {
                            pointOfContact: "email",
                            topic: ""
                        }
                    ]
                },
                addProductToList: {
                    product: {
                        ids: {
                            sMProductID: product.id
                        }
                    }
                }
            }
        });
    }


    if (!product) return null

    if (width >= 791) {
        return <div className={styles.wrapper}>
            <div className={styles.header}>
                <SText style={{marginTop: 13}} fontSize={24} fontWeight={900} lineHeight={20}>Ð¡Ð¾Ð¾Ð±Ñ‰Ð¸Ñ‚ÑŒ Ð¾
                    Ð¿Ð¾ÑÑ‚ÑƒÐ¿Ð»ÐµÐ½Ð¸Ð¸</SText>
                <TouchableOpacity onClick={onClose}>
                    <div style={{
                        background: '#F5F5F5',
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <img src={closeIcon}/>
                    </div>
                </TouchableOpacity>
            </div>
            <div className={styles.product}>
                <img src={product?.medium_image} onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = placeholder
                }}/>
                <div className={styles.info}>
                    <SText style={{marginBottom: 20}} fontSize={24} fontWeight={900}
                           lineHeight={26}>{product.title}</SText>
                    <SText style={{marginBottom: 45}} fontSize={20} fontWeight={700}
                           lineHeight={20}>{product.weight + ' ÐºÐ³'}</SText>
                    <div>
                        <SText fontSize={28} fontWeight={700} lineHeight={28}>{product.price}</SText>
                        <SText fontSize={18} fontWeight={700} style={{fontWeight: 900}}>{'\u00A0â‚½'}</SText>
                    </div>
                </div>
            </div>
            <div className={styles.mail}>
                <Input title={'ÐÐ´Ñ€ÐµÑ Ð²Ð°ÑˆÐµÐ¹ ÑÐ»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ð¾Ð¹ Ð¿Ð¾Ñ‡Ñ‚Ñ‹'} value={emailValue} onChange={e => {
                    validateEmail(e)
                    setEmailValue(e)
                }
                }/>
                <RoundedButton onPress={async () => {
                    await informAboutAdmission(parseInt(product.id))
                    sendOnEmail()
                    onClose()
                    _open('admission')
                }} disabled={!emailValid} activeColor={Colors.mainGreen} label={'Ð¡Ð¾Ð¾Ð±Ñ‰Ð¸Ñ‚ÑŒ Ð¾ Ð¿Ð¾ÑÑ‚ÑƒÐ¿Ð»ÐµÐ½Ð¸Ð¸'}/>
            </div>
        </div>
    } else return <div className={styles.wrapper}>
        <div className={styles.header}>
            <SText fontSize={16} fontWeight={900} lineHeight={18}>Ð¡Ð¾Ð¾Ð±Ñ‰Ð¸Ñ‚ÑŒ <br/> Ð¾
                Ð¿Ð¾ÑÑ‚ÑƒÐ¿Ð»ÐµÐ½Ð¸Ð¸</SText>
            <TouchableOpacity onClick={onClose}>
                <div style={{
                    background: '#F5F5F5',
                    borderRadius: '50%',
                    width: 26,
                    height: 26,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <img src={closeIcon}/>
                </div>
            </TouchableOpacity>
        </div>
        <div className={styles.product}>
            <img src={product?.medium_image} onError={(e) => {
                e.target.onerror = null;
                e.target.src = placeholder
            }}/>
            <div className={styles.info}>
                <SText style={{marginBottom: 15}} fontSize={16} fontWeight={550} lineHeight={18}>{product.title}</SText>
                <SText style={{marginBottom: 25}} color={'#909090'} fontSize={14} fontWeight={700}
                       lineHeight={18}>{product.weight + ' ÐºÐ³'}</SText>
                <div>
                    <SText fontSize={22} fontWeight={700} lineHeight={22}>{product.price}</SText>
                    <SText fontSize={16} fontWeight={700} style={{fontWeight: 900}}>{'\u00A0â‚½'}</SText>
                </div>
            </div>
        </div>
        <div className={styles.mail}>
            <Input title={'ÐÐ´Ñ€ÐµÑ Ð²Ð°ÑˆÐµÐ¹ ÑÐ»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ð¾Ð¹ Ð¿Ð¾Ñ‡Ñ‚Ñ‹'} value={emailValue} onChange={e => {
                validateEmail(e)
                setEmailValue(e)
            }
            }/>
            <RoundedButton onPress={async () => {
                await informAboutAdmission(parseInt(product.id))
                sendOnEmail()
                onClose()
                _open('admission')
            }} activeColor={Colors.mainGreen} disabled={!emailValid} label={'Ð¡Ð¾Ð¾Ð±Ñ‰Ð¸Ñ‚ÑŒ Ð¾ Ð¿Ð¾ÑÑ‚ÑƒÐ¿Ð»ÐµÐ½Ð¸Ð¸'}/>
        </div>

    </div>
}
