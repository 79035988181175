import React from "react";
import styles from "./BonusSystem.module.css"
import {Helmet} from "react-helmet";
import Header from "./Header";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import {useWindowDimensions} from "../utils/hooks";
import BreadCrumbs from "./BreadCrumbs";
import SText from "./SText";
import Colors from "../utils/Colors";

export default () => {

    const {isMobile, windowDimensions: {width}} = useWindowDimensions(600)

    const breadCrumbs = [
        {
            name: 'Ð‘Ð¾Ð½ÑƒÑÐ½Ð°Ñ ÑÐ¸ÑÑ‚ÐµÐ¼Ð°',
            to: '/bonus-system'
        }
    ]

    return <div>
        <Helmet>
            <title>Ð‘Ð¾Ð½ÑƒÑÐ½Ð°Ñ ÑÐ¸ÑÑ‚ÐµÐ¼Ð°</title>
            <meta name="description" content='Ð‘Ð¾Ð½ÑƒÑÐ½Ð°Ñ ÑÐ¸ÑÑ‚ÐµÐ¼Ð°'/>
            <meta name="keywords" content='Ð‘Ð¾Ð½ÑƒÑÐ½Ð°Ñ ÑÐ¸ÑÑ‚ÐµÐ¼Ð°'/>
        </Helmet>
        <Header/>
        {width < 992 && <MobileExtraInfo/>}
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BreadCrumbs _items={breadCrumbs}/>
                <div style={{marginBottom: width > 762 ? 30 : 30}} className={styles.title}><SText fontSize={24}
                                                                                                   lineHeight={23}
                                                                                                   fontWeight={900}>Ð‘Ð¾Ð½ÑƒÑÐ½Ð°Ñ
                    ÑÐ¸ÑÑ‚ÐµÐ¼Ð°</SText></div>

                <div><SText fontSize={16} fontWeight={550} lineHeight={18}>ÐšÐ¾Ð¿Ð¸Ñ‚Ðµ Ð±Ð¾Ð½ÑƒÑÑ‹ Ð·Ð° ÐºÐ°Ð¶Ð´Ñ‹Ð¹ Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÐµÐ½Ð½Ñ‹Ð¹ Ð·Ð°ÐºÐ°Ð· â€“
                    Ð¾Ð¿Ð»Ð°Ñ‡Ð¸Ð²Ð°Ð¹Ñ‚Ðµ Ð´Ð¾ 50% Ð¾Ñ‚ ÑÑƒÐ¼Ð¼Ñ‹ ÑÐ»ÐµÐ´ÑƒÑŽÑ‰ÐµÐ¹ Ð¿Ð¾ÐºÑƒÐ¿ÐºÐ¸. </SText></div>
                <div><SText fontSize={16} fontWeight={550} lineHeight={18}>1 Ð±Ð¾Ð½ÑƒÑ = 1 Ñ€ÑƒÐ±Ð»ÑŒ.</SText></div>
                <div style={{marginBottom: width > 762 ? 28 : 18}}><SText fontSize={16} fontWeight={550}
                                                                          lineHeight={18}>Ð‘Ð¾Ð½ÑƒÑÑ‹ Ð½Ð° ÑÑ‡Ñ‘Ñ‚ Ð·Ð°Ñ‡Ð¸ÑÐ»ÑÑŽÑ‚ÑÑ
                    ÑÑ€Ð°Ð·Ñƒ Ð¿Ð¾ÑÐ»Ðµ Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹ Ð¸ ÑÐ³Ð¾Ñ€Ð°ÑŽÑ‚ Ñ‡ÐµÑ€ÐµÐ· 30 Ð´Ð½ÐµÐ¹.</SText></div>

                <div style={{marginBottom: width > 762 ? 22 : 42}}><SText fontSize={16} fontWeight={900}
                                                                          lineHeight={15}>ÐšÐ°Ðº Ð¼Ð¾Ð¶Ð½Ð¾ Ð¿Ð¾Ð»ÑƒÑ‡Ð¸Ñ‚ÑŒ
                    Ð±Ð¾Ð½ÑƒÑÑ‹:</SText></div>

                <div className={styles.dot} style={{marginBottom: width > 762 ? 27 : 22}}><SText fontSize={16}
                                                                                                 fontWeight={550}
                                                                                                 lineHeight={18}>Ð·Ð°
                    Ð·Ð°ÐºÐ°Ð· â€“ 5% Ð¾Ñ‚ Ð¾Ð±Ñ‰ÐµÐ¹ ÑÑƒÐ¼Ð¼Ñ‹;</SText></div>

                <div className={styles.dot} style={{marginBottom: width > 762 ? 30 : 30}}><SText fontSize={16}
                                                                                                 fontWeight={550}
                                                                                                 lineHeight={18}>Ð·Ð° Ñ‚Ð¾,
                    Ñ‡Ñ‚Ð¾ Ð¿Ð¾Ð´ÐµÐ»Ð¸Ð»Ð¸ÑÑŒ Ñ Ð´Ñ€ÑƒÐ·ÑŒÑÐ¼Ð¸ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´Ð¾Ð¼ Ð¸Ð· Ð›Ð¸Ñ‡Ð½Ð¾Ð³Ð¾ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚Ð° â€“ 300 Ð±Ð¾Ð½ÑƒÑÐ¾Ð² Ð·Ð° ÐºÐ°Ð¶Ð´Ð¾Ð³Ð¾ Ð´Ñ€ÑƒÐ³Ð°,
                    Ð¿Ñ€Ð¸Ð¼ÐµÐ½Ð¸Ð²ÑˆÐµÐ³Ð¾ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´.</SText></div>

                <div><SText fontSize={16} fontWeight={700} lineHeight={18}>ÐœÑ‹ Ð»ÑŽÐ±Ð¸Ð¼ Ð²Ð°Ñ Ñ€Ð°Ð´Ð¾Ð²Ð°Ñ‚ÑŒ! Ð—Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°Ð¹Ñ‚Ðµ
                    Ð½Ð°Ñ‚ÑƒÑ€Ð°Ð»ÑŒÐ½Ñ‹Ðµ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¾Ð¹ Ð´Ð¾ Ð¿Ð¾Ñ€Ð¾Ð³Ð° Ð½Ð° Ð¸Ð½Ñ‚ÐµÑ€Ð½ÐµÑ‚-Ñ€Ñ‹Ð½ÐºÐµ SeasonMarket. Ð’Ð°Ð¼ Ð¾Ð±ÑÐ·Ð°Ñ‚ÐµÐ»ÑŒÐ½Ð¾
                    Ð¿Ð¾Ð½Ñ€Ð°Ð²Ð¸Ñ‚ÑÑ!</SText></div>
            </div>
        </div>
    </div>
}