import React, {useEffect, useRef, useState} from "react";
import Colors from "../../../utils/Colors";
import { text } from "../../../utils/mixins";
import {useTextMask} from "../../../utils/hooks";
import styles from '../AuthModal.module.css'
import {handleEnter} from "../../../utils/other";

const phoneMask = ['+','7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/];

const PhoneInput = ({onEnter=()=>{}, value, onValueChange=()=>{},}) => {
    const ref = useRef(null)

    useTextMask(ref.current, {mask: phoneMask, guide: false})

    useEffect(()=>{
        if (ref.current)
            ref.current.selectionStart = ref.current.selectionEnd = 20

    }, [value])

    return <input
        className={styles.input}
        onFocusCapture={()=>{if (value === '') onValueChange('+7')}}
        ref={ref}
        type={'cel-phone'}
        inputMode={'tel'}
        onFocus={()=>(value === '' && onValueChange('+7'))}
        maxLength={18}
        onKeyPress={handleEnter(onEnter)}
        onChange={(e)=>{onValueChange(e.target.value)}}
        style={text(18,700,2)}
        value={value}
        placeholder={"ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°"}
    />
}

export default PhoneInput
