import React, {useEffect, useState} from "react";
import styles from './Modal.module.css'
import SText from "./SText";
import TouchableOpacity from "./TouchableOpacity";
import closeIcon from '../assets/images/closeButton.svg'
import cn from "classnames";
import {useEscape, useWindowDimensions} from "../utils/hooks";
import {text} from "../utils/mixins";
import Colors from "../utils/Colors";

const Header = ({onClose = () => {}, modalTitle = '', isBasket = false, isClearBasket, ...otherProps}) => {

    const {isMobile} = useWindowDimensions()

    if (isBasket) {
        return <div className={styles.modalHeaderBasket}>
            <div style={{display: 'flex'}}>
                <div style={{marginRight: '20px'}}>
                    <SText fontSize={16} fontWeight={900} className={styles.modalTitle}>
                        {modalTitle}
                    </SText>
                </div>
                {isMobile
                    ?null
                    :<div>
                        <div className={styles.delivery}>
                            <SText color={Colors.white} fontType={0} fontSize={16}>{'Ð‘Ð»Ð¸Ð¶Ð°Ð¹ÑˆÐ°Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ° - '}</SText>
                            <SText
                                fontWeight={700} fontSize={16} color={Colors.white}
                                style={{borderBottom: 'solid 1px #fff'}}>{'Ð·Ð°Ð²Ñ‚Ñ€Ð° 03 ÑÐµÐ½Ñ‚ÑÐ±Ñ€Ñ'}</SText>
                        </div>
                    </div>
                }
            </div>
            <TouchableOpacity onClick={onClose}
                              className={styles.circle}
                              style={{background: '#F5F5F5', borderRadius: '50%', width: '40px', height: '40px'}}
            >
                    <img src={closeIcon}/>
            </TouchableOpacity>
        </div>
    } else return <div className={isClearBasket?null:styles.modalHeader}>
        <SText fontSize={18} fontWeight={900} className={styles.modalTitle}>
            {modalTitle}
        </SText>
        {isClearBasket
            ?null
            :<TouchableOpacity onClick={onClose}
                               className={styles.circle}
                               style={{background: '#F5F5F5', borderRadius: '50%', width: '40px', height: '40px'}}
            >
                <img src={closeIcon}/>
            </TouchableOpacity>}
    </div>
}

const Modal = ({
                   isVisible = false,
                   onClose = () => {
                   },
                   modalTitle = '',
                   isBasket = false,
                   isClearBasket = false,
                   positions,
                   maxHeight = 550,
                   maxWidth = 600,
                   customHeader=false,
                   isPromo,
                   ...otherProps
               }) => {

    const [isShown, setShown] = useState(isVisible)
    const {isMobile} = useWindowDimensions()
    const [mounted, setMounted] = useState(false)

    const overLayRef = useEscape(styles.overlay, onClose)


    useEffect(() => {
        if (isVisible) {
            setShown(true)
            setTimeout(() => {
                setMounted(true)
            }, 100)
        } else {
            setTimeout(() => {
                setShown(false)
                setMounted(false)
            }, 350)
        }
    }, [isVisible])

    if (!isShown) return null


    return <div className={cn(styles.overlay, (!isVisible || !mounted) && styles.overlayInvisible)}
                ref={overLayRef}>
        <div style={{
            height: isMobile ? '100%' : undefined, //todo
            maxWidth: isBasket ? '996px' : (isMobile  ? '100%' : maxWidth),
            maxHeight: !isBasket
                ? (isMobile  ? '100%' : maxHeight) + 'px'
                : positions > 3 ? '880px' : '650px'
        }}
             className={cn(styles.container, (!isVisible || !mounted) && styles.containerInvisible)}>
            {customHeader
                ?null
                :<Header modalTitle={modalTitle} isBasket={isBasket} onClose={onClose} isClearBasket={isClearBasket}/>
            }
            <div style={{padding: isBasket || isPromo ? '50px 50px 0' : isClearBasket? isMobile?'20px 7px 35px':'20px 50px 35px 40px' :'50px'}} className={styles.content}>
                {otherProps.children}
            </div>
        </div>
    </div>

}

export default Modal
