import React, { useContext, useEffect, useState } from "react";
import { useWindowDimensions } from "../utils/hooks";
import styles from "./OrderIsProcessed.module.css";
import SText from "./SText";
import accepted from "../assets/images/accepted.svg"
import packageBlack from "../assets/images/packageBlack.svg"
import packageWhite from "../assets/images/packageWhite.svg"
import rocketBlack from "../assets/images/rocketBlack.svg"
import rocketWhite from "../assets/images/rocketWhite.svg"
import likeBlack from "../assets/images/thumbsUpBlack.svg"
import likeWhite from "../assets/images/thumbsUpWhite.svg"
import cn from "classnames";
import { Header } from "../screens/Ordering/components/Ordering";
import mapPin from "../assets/images/MapPin.svg"
import clock from "../assets/images/Clock.svg"
import warning from "../assets/images/warning.png"
import DataContext from "../data/DataContext";
import { getOrder } from "../api";
import moment from "moment";
import { capitalize, removeYear } from "../utils/other";
import { Redirect, useHistory, useParams } from "react-router-dom";
import placeholder from '../assets/images/productImagePlaceholder.png'
import RoundedButton from "./RoundedButton";
import Colors from "../utils/Colors";
import { launchPayment } from "../utils/payment";

const StatusBlock = ({ ordersHistory, order }) => {

    function getOrderStatus() {
        switch (order?.status) {
            case 'created':
                return 0
            case 'handed_over_for_picking':
                return 1
            case 'on_the_way':
                return 2
            case 'delivered':
                return 3
            default:
                return -1
        }
    }

    const currentStatus = getOrderStatus()

    const { isMobile, windowDimensions: { width } } = useWindowDimensions()

    if (currentStatus == -1) return null // <SText fontSize={24} style={{marginTop: 16, marginBottom: 16}} div>Ð—Ð°ÐºÐ°Ð· Ð¾Ñ‚Ð¼ÐµÐ½ÐµÐ½</SText>

    return <div className={ ordersHistory ? styles.orderStatusHistory : styles.orderStatus }>
        <div className={ ordersHistory ? styles.statusWrapperHistory : styles.statusWrapper }>
            <div style={ { display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' } }>
                <div className={ cn(styles.statusCircle, styles.statusActive) }><img src={ accepted } /></div>
                <SText fontSize={ 12 } fontWeight={ 550 } color={ '#2DCC70' }>{ 'Ð¿Ñ€Ð¸Ð½ÑÑ‚' }</SText>
            </div>
            <div style={ {
                margin: '0 4px',
                width: '33px',
                height: '3px',
                background: currentStatus >= 1 ? '#2dcc70' : '#EDEDED'
            } } />
            <div style={ { display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' } }>
                <div className={ cn(styles.statusCircle, currentStatus >= 1 && styles.statusActive) }><img
                    src={ currentStatus >= 1 ? packageWhite : packageBlack } />
                </div>
                <SText fontSize={ 12 } fontWeight={ 550 }
                    color={ currentStatus >= 1 ? '#2DCC70' : '#EDEDED' }>{ 'ÑÐ¾Ð±Ñ€Ð°Ð½' }</SText>
            </div>
            <div style={ {
                margin: '0 -1px 0 4px',
                width: '33px',
                height: '3px',
                background: currentStatus >= 2 ? '#2dcc70' : '#EDEDED'
            } } />
            <div style={ {
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '15px'
            } }>
                <div style={ { marginBottom: '0' } }
                    className={ cn(styles.statusCircle, currentStatus >= 2 && styles.statusActive) }><img
                        src={ currentStatus >= 2 ? rocketWhite : rocketBlack } />
                </div>
                <div style={ { textAlign: 'center', width: '73px', height: '25px' } }>
                    <SText style={ { lineHeight: '0,6' } }
                        fontSize={ 12 } fontWeight={ 550 }
                        color={ currentStatus >= 2 ? '#2DCC70' : '#EDEDED' }>
                        { 'Ð¿ÐµÑ€ÐµÐ´Ð°Ð½' } <br />
                        { 'Ð½Ð° Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÑƒ' }
                    </SText>
                </div>
            </div>
            <div style={ {
                margin: '0 4px 0 0',
                width: '33px',
                height: '3px',
                background: currentStatus >= 3 ? '#2dcc70' : '#EDEDED'
            } } />
            <div style={ {
                marginTop: '5px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
            } }>
                <div className={ cn(styles.statusCircle, currentStatus === 3 && styles.statusActive) }><img
                    src={ currentStatus >= 3 ? likeWhite : likeBlack } /></div>
                <SText fontSize={ 12 } fontWeight={ 550 }
                    color={ currentStatus >= 3 ? '#2DCC70' : '#EDEDED' }>{ 'Ð´Ð¾ÑÑ‚Ð°Ð²Ð»ÐµÐ½' }</SText>
            </div>
        </div>
    </div>
}

function processDate(date, time) {
    const day = moment(date, 'YYYY-MM-DD')
    const isToday = day.isSame(moment(), 'day')
    const isTomorrow = day.isSame(moment().add(1, 'days'), 'day')
    const isYesterday = day.isSame(moment().subtract(1, 'days'), 'day')

    const dateLabel = isTomorrow ? 'Ð·Ð°Ð²Ñ‚Ñ€Ð°' : isToday ? 'ÑÐµÐ³Ð¾Ð´Ð½Ñ' : isYesterday ? 'Ð²Ñ‡ÐµÑ€Ð°' : day.format('dddd')

    const timeParts = time?.split('-') || ['', '']

    const timeLabel = `Ñ ${timeParts[0]} Ð´Ð¾ ${timeParts[1]}`
    return {
        full: `${capitalize(dateLabel)}, ${removeYear(day.format('LL'))} ${timeLabel}`
    }
}

const DeliveryPlace = ({ ordersHistory, order }) => {

    const { isMobile, windowDimensions: { width } } = useWindowDimensions()


    return <div>
        <div style={ { marginBottom: '20px' } }><SText fontSize={ ordersHistory ? 16 : 20 } fontWeight={ 900 }>Ð”ÐžÐ¡Ð¢ÐÐ’ÐšÐ</SText>
        </div>
        <div style={ { display: 'flex', alignItems: 'flex-start', margin: '0 0 5px -5px' } }>
            <img style={ { marginRight: 15 } } src={ mapPin } />
            <div>
                <SText fontSize={ 14 }
                    fontWeight={ ordersHistory ? 400 : 700 }>{ order?.address || '' }</SText>
                <br />
                {/*   <SText fontSize={14} fontWeight={ordersHistory ? 400 : 700}>{order?.address?.number ? ('ÐšÐ²/Ð¾Ñ„Ð¸Ñ: '+order?.address?.number) : ''}</SText>
                <br/>
               <SText fontSize={14} fontWeight={ordersHistory ? 400 : 700}>{order?.address?.door ? ('ÐŸÐ¾Ð´ÑŠÐµÐ·Ð´: '+order?.address?.door) : ''}</SText>
                <br/>
                <SText fontSize={14} fontWeight={ordersHistory ? 400 : 700}>{order?.address?.floor ? ('Ð­Ñ‚Ð°Ð¶: '+order?.address?.floor) : ''}</SText>
                <br/>*/}
                <SText fontSize={ 14 }
                    fontWeight={ ordersHistory ? 400 : 700 }>{ order?.comment ? ('ÐšÐ¾Ð¼Ð¼ÐµÐ½Ñ‚Ð°Ñ€Ð¸Ð¹: ' + order?.comment) : '' }</SText>
            </div>
        </div>
        <div style={ {
            display: 'flex',
            alignItems: 'center',
            margin: isMobile ? '0 0 25px -5px' : '0 0 45px -5px'
        } }>
            <img style={ { marginRight: 15 } } src={ clock } />
            <SText fontSize={ 14 }
                fontWeight={ ordersHistory ? 400 : 700 }>{ processDate(order.date_interval, order.time_interval).full }</SText>
        </div>
    </div>
}

const Order = ({ ordersHistory, order }) => {

    return <div style={ {} }>
        <div style={ { marginBottom: '25px' } }><SText fontSize={ 20 } fontWeight={ 900 }>{ 'Ð’Ð« Ð—ÐÐšÐÐ—ÐÐ›Ð˜' }</SText></div>
        <div>
            { order?.orderProducts?.map(position => <Product ordersHistory={ ordersHistory } amount={ position.quantity }
                price={ position.price } id={ position.product_id } />) }
        </div>
    </div>
}

const Product = ({ price, ordersHistory, amount, id }) => {

    const { getProductById } = useContext(DataContext)
    const [product, setProduct] = useState(null)
    const { isMobile, windowDimensions: { width } } = useWindowDimensions()

    useEffect(() => {
        getProductById(id).then(setProduct)
    }, [id])

    if (!product) return null


    if (isMobile) {
        return <div className={ ordersHistory ? styles.productWrapperHistory : styles.productWrapper }>
            <div style={ { display: 'flex', justifyContent: 'space-between' } }>
                <img src={ product.small_image } />
                <div className={ styles.productName }>
                    <SText style={ { marginBottom: '5px' } } largerGap={ true } fontSize={ 16 }
                        fontWeight={ 500 }>{ product.title }</SText>
                    <SText fontSize={ 14 } fontWeight={ 700 } color={ '#909090' }>{ amount + ' ÑˆÑ‚' }</SText>
                </div>
            </div>
            <div>
                <SText fontSize={ 18 } fontWeight={ 700 }>{ price }</SText>
                <SText fontSize={ 14 } fontWeight={ 700 } style={ { fontWeight: 900 } }>{ '\u00A0â‚½' }</SText>
            </div>
        </div>
    } else return <div className={ ordersHistory ? styles.productWrapperHistory : styles.productWrapper }>
        <img src={ product.small_image } onError={ (event => {
            event.target.onerror = null;
            event.target.src = placeholder
        }) } />
        <div style={ ordersHistory ? { width: '100%', display: 'flex', justifyContent: 'space-between' } : {
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 272
        } }>
            <div className={ styles.productName }>
                <SText style={ { marginBottom: '5px' } } largerGap={ true } fontSize={ 16 }
                    fontWeight={ 500 }>{ product.title }</SText>
                <SText fontSize={ 14 } fontWeight={ 700 } color={ '#909090' }>{ amount + ' ÑˆÑ‚' }</SText>
            </div>
            <div><SText fontSize={ 18 } fontWeight={ 700 }>{ price }</SText> <SText fontSize={ 14 } fontWeight={ 700 }
                style={ { fontWeight: 700 } }>{ '\u00A0â‚½' }</SText>
            </div>
        </div>
    </div>
}

const Price = ({
    ordersHistory, order = {}, onRefresh = () => {
    }
}) => {


    const { user, getProductSync } = useContext(DataContext)


    const fullAmount = Number(order?.full_amount || 0) + Number(order?.delivery_price || 0) - Number(order?.amount_bonuses || 0)

    function onPay() {

        try {
            let _tmr = window._tmr || (window._tmr = []);
            _tmr.push({ "type": "reachGoal", "id": 3220587, "goal": "purchase" });

            window.fbq('track', 'Purchase');

            window.gtag('event', 'submitorder', { 'event_category': 'submitorder' });

            const products = order.orderProducts.map(prod => {
                const product = getProductSync(prod.product_id)
                return {
                    id: product.id,
                    name: product.title,
                    category: `${product.master_category[0]}`,
                    quantity: prod.quantity,
                    price: product.price
                }
            })

            window.dataLayer.push({
                "ecommerce": {
                    "currencyCode": "RUB",
                    "purchase": {
                        "actionField": {
                            "id": order.id
                        },
                        "products": products
                    }
                }
            });

            window.ym(100345696, 'reachGoal', 'submitorder')

        } catch { }



        launchPayment({
            userId: user.data.id,
            amount: Number(fullAmount),
            orderId: order.id,
            onComplete: () => {
                user.get()
                onRefresh()
            },
            onFail: () => {
                user.get()
                onRefresh()
            },
            onSuccess: () => {
                user.get()
                onRefresh()
            },
        })
    }

    return <div className={ ordersHistory ? styles.priceWrapperHistory : styles.priceWrapper }>
        <div className={ styles.priceRow }>
            <SText fontSize={ 16 } fontWeight={ 500 }>Ð¡Ñ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð²</SText>
            <div>
                <SText fontSize={ 18 } fontWeight={ 700 }>{ Math.ceil(order.full_amount) }</SText>
                <SText fontSize={ 14 } fontWeight={ 700 } style={ { fontWeight: 700 } }>{ '\u00A0â‚½' }</SText>
            </div>
        </div>
        <div className={ styles.priceRow }>
            <SText fontSize={ 16 } fontWeight={ 500 }>Ð¡Ñ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸</SText>
            <div>
                <SText fontSize={ 18 }
                    fontWeight={ 700 }>{ order.delivery_price == 0 ? 'Ð‘ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾' : order.delivery_price }</SText>
                { order.delivery_price != 0 &&
                    <SText fontSize={ 14 } fontWeight={ 700 } style={ { fontWeight: 900 } }>{ '\u00A0â‚½' }</SText> }
            </div>
        </div>
        { order.amount_bonuses != 0 && <div className={ styles.priceRow }>
            <SText color={ '#2DCC70' } fontSize={ 16 } fontWeight={ 500 }>Ð¡ÐºÐ¸Ð´ÐºÐ°</SText>
            <div>
                <SText color={ '#2DCC70' } fontSize={ 18 } fontWeight={ 700 }>-{ Math.ceil(order.amount_bonuses) }</SText>
                <SText color={ '#2DCC70' } fontSize={ 14 } fontWeight={ 700 } style={ { fontWeight: 700 } }>{ '\u00A0â‚½' }</SText>
            </div>
        </div> }
        <div style={ ordersHistory ? { marginBottom: '15px' } : {} } className={ styles.priceRow }>
            <SText fontSize={ ordersHistory ? 16 : 20 } fontWeight={ 900 }>Ð˜Ð¢ÐžÐ“Ðž</SText>
            <div>
                <SText fontSize={ ordersHistory ? 18 : 20 } fontWeight={ 900 }>{ Math.ceil(fullAmount) }</SText>
                <SText fontSize={ 14 } fontWeight={ 700 } style={ { fontWeight: 900 } }>{ '\u00A0â‚½' }</SText>
            </div>
        </div>
        { order.status !== 'canceled' && (
            <>
                { (order.status_payment == '0' && ordersHistory) &&
                    <div style={ { display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' } }>
                        { order.status !== 'canceled' && <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '36px' } }>
                            <SText style={ { marginRight: '12px' } } fontSize={ 14 } fontWeight={ 550 }>Ð—Ð°ÐºÐ°Ð· Ð½Ðµ Ð¾Ð¿Ð»Ð°Ñ‡ÐµÐ½</SText>
                            <img style={ { width: 16, height: 16 } } src={ warning } />
                        </div> }
                        <RoundedButton activeColor={ Colors.darkBlue }
                            onPress={ onPay }
                            containerStyle={ {
                                justifyContent: 'space-between',
                                padding: '0 20px',
                                maxWidth: 254,
                                marginBottom: 48,
                                alignSelf: 'flex-end'
                            } }>
                            <SText color={ '#fff' } fontWeight={ 550 } fontSize={ 18 }>{ 'ÐžÐ¿Ð»Ð°Ñ‚Ð¸Ñ‚ÑŒ' }</SText>
                            <div>
                                <SText color={ '#fff' } fontWeight={ 550 } fontSize={ 18 }>
                                    <strong>{ Math.ceil(fullAmount) }</strong>
                                </SText>
                                <SText color={ '#fff' } fontSize={ 16 } fontWeight={ 700 } style={ { fontWeight: 900 } }>{ '\u00A0â‚½' }</SText>
                            </div>
                        </RoundedButton></div>
                }
                { (order.status_payment == '0' && !ordersHistory) &&
                    <div style={ { marginBottom: 50, display: 'flex', flexDirection: 'column' } }>
                        <div style={ {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginBottom: '36px',
                            marginTop: 25
                        } }>
                            <SText style={ { marginRight: '12px' } } fontSize={ 14 } fontWeight={ 550 }>Ð—Ð°ÐºÐ°Ð· Ð½Ðµ Ð¾Ð¿Ð»Ð°Ñ‡ÐµÐ½</SText>
                            <img style={ { width: 16, height: 16 } } src={ warning } />
                        </div>
                        <RoundedButton activeColor={ Colors.darkBlue }
                            onPress={ onPay }
                            containerStyle={ {
                                justifyContent: 'space-between',
                                padding: '0 20px',
                                maxWidth: 306,
                                marginBottom: 0,
                                alignSelf: 'flex-end'
                            } }>
                            <SText color={ '#fff' } fontWeight={ 550 } fontSize={ 18 }>{ 'ÐžÐ¿Ð»Ð°Ñ‚Ð¸Ñ‚ÑŒ' }</SText>
                            <div>
                                <SText color={ '#fff' } fontWeight={ 550 } fontSize={ 18 }>
                                    <strong>{ Math.ceil(fullAmount) }</strong>
                                </SText>
                                <SText color={ '#fff' } fontSize={ 16 } fontWeight={ 700 } style={ { fontWeight: 900 } }>{ '\u00A0â‚½' }</SText>
                            </div>
                        </RoundedButton></div>
                }
            </>
        ) }
    </div>
}

export default ({ ordersHistory = false, orderId = false }) => {

    const { isMobile, windowDimensions: { width } } = useWindowDimensions()
    const { user, auth } = useContext(DataContext)
    const { push } = useHistory()

    const { id } = useParams()
    if (!orderId)
        orderId = id

    const [order, setOrder] = useState(null)

    async function loadOrder() {
        const response = await getOrder(orderId)
        if (response?.order?.id) {
            setOrder(response.order)
            console.log({ order: response.order })
        } else {
            push('/')
        }
    }

    useEffect(() => {
        loadOrder()
    }, [orderId])

    if (!auth.data) return <Redirect to={ '/' } />

    if (!order) return null

    if (ordersHistory && isMobile) {
        return <div>
            <StatusBlock ordersHistory={ true } order={ order } />
            <DeliveryPlace order={ order } />
            <Order order={ order } ordersHistory />
            <Price order={ order } ordersHistory />
        </div>
    } else if (ordersHistory) {
        return <div style={ { paddingRight: 20 } }>
            <StatusBlock ordersHistory={ true } order={ order } />
            <DeliveryPlace ordersHistory={ true } order={ order } />
            <Order order={ order } ordersHistory={ true } />
            <Price order={ order } ordersHistory={ true } />
        </div>
    } else if (isMobile) {
        return <div className={ styles.background }>
            <Header />
            <div className={ styles.wrapper }>
                <div style={ { marginBottom: '20px' } }><SText fontSize={ 28 } fontWeight={ 900 }>Ð’ÐÐ¨
                    Ð—ÐÐšÐÐ— <br /> ÐŸÐ Ð˜ÐÐ¯Ð¢!</SText>
                </div>
                <div style={ { marginBottom: '20px' } }><SText fontSize={ 14 } fontWeight={ 500 }
                    color={ '#FFA353' }>{ `ÐÐ¾Ð¼ÐµÑ€ ÐŸÐ -${orderId}` }</SText></div>
                <StatusBlock order={ order } />
                <div style={ { margin: '30px 0 20px' } }><SText fontSize={ 14 } fontWeight={ 500 }>ÐœÑ‹ ÑÐ²ÑÐ¶ÐµÐ¼ÑÑ Ñ Ð²Ð°Ð¼Ð¸ Ð²
                    Ð±Ð»Ð¸Ð¶Ð°Ð¹ÑˆÐµÐµ
                    Ð²Ñ€ÐµÐ¼Ñ Ð´Ð»Ñ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´ÐµÐ½Ð¸Ñ
                    Ð·Ð°ÐºÐ°Ð·Ð°</SText>
                </div>
                <div style={ { marginBottom: '25px' } }><SText fontSize={ 12 } fontWeight={ 400 }>Ð˜Ñ‚Ð¾Ð³Ð¾Ð²Ð°Ñ ÑÑƒÐ¼Ð¼Ð° Ð²Ð°ÑˆÐµÐ³Ð¾ Ð·Ð°ÐºÐ°Ð·Ð°
                    Ð¼Ð¾Ð¶ÐµÑ‚
                    Ð²Ð°Ñ€ÑŒÐ¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒÑÑ <br />
                    Ð² Ð¿Ñ€ÐµÐ´ÐµÐ»Ð°Ñ… 10%, ÐµÑÐ»Ð¸ Ð² Ð·Ð°ÐºÐ°Ð·Ðµ ÐµÑÑ‚ÑŒ Ð²ÐµÑÐ¾Ð²Ð¾Ð¹ Ñ‚Ð¾Ð²Ð°Ñ€. <br />
                    Ð’ Ñ‚Ð°ÐºÐ¾Ð¼ ÑÐ»ÑƒÑ‡Ð°Ðµ Ð±ÑƒÐ´ÐµÑ‚ Ð¾ÑÑƒÑ‰ÐµÑÑ‚Ð²Ð»ÐµÐ½ Ð²Ð¾Ð·Ð²Ñ€Ð°Ñ‚ Ñ‡Ð°ÑÑ‚Ð¸<br /> Ð´ÐµÐ½ÐµÐ¶Ð½Ñ‹Ñ… ÑÑ€ÐµÐ´ÑÑ‚Ð² Ð¸Ð»Ð¸ Ð´Ð¾Ð¿Ð¾Ð»Ð½Ð¸Ñ‚ÐµÐ»ÑŒÐ½Ð¾Ðµ ÑÐ¿Ð¸ÑÐ°Ð½Ð¸Ðµ,
                    ÐºÐ°Ðº<br />
                    Ñ‚Ð¾Ð»ÑŒÐºÐ¾ Ð·Ð°ÐºÐ°Ð· Ð±ÑƒÐ´ÐµÑ‚ ÑÐ¾Ð±Ñ€Ð°Ð½.</SText></div>
                <div className={ styles.line } />
                <DeliveryPlace order={ order } />
                <div className={ styles.line } />
                <Order order={ order } />
                <Price order={ order } />
            </div>
        </div>
    } else {
        return <div className={ styles.background }>
            <Header />
            <div className={ styles.wrapper }>
                <div style={ { marginBottom: '20px' } }><SText fontSize={ 28 } fontWeight={ 900 }>Ð’ÐÐ¨ Ð—ÐÐšÐÐ— ÐŸÐ Ð˜ÐÐ¯Ð¢!</SText>
                </div>
                <div style={ { marginBottom: '20px' } }><SText fontSize={ 14 } fontWeight={ 500 }
                    color={ '#FFA353' }>{ `ÐÐ¾Ð¼ÐµÑ€ ÐŸÐ -${orderId}` }</SText></div>
                <StatusBlock order={ order } />
                <div style={ { margin: '30px 0 20px' } }><SText fontSize={ 14 } fontWeight={ 500 }>ÐœÑ‹ ÑÐ²ÑÐ¶ÐµÐ¼ÑÑ Ñ Ð²Ð°Ð¼Ð¸ Ð²
                    Ð±Ð»Ð¸Ð¶Ð°Ð¹ÑˆÐµÐµ
                    Ð²Ñ€ÐµÐ¼Ñ Ð´Ð»Ñ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´ÐµÐ½Ð¸Ñ
                    Ð·Ð°ÐºÐ°Ð·Ð°</SText>
                </div>
                <div style={ { marginBottom: '25px' } }><SText fontSize={ 12 } fontWeight={ 400 }>Ð˜Ñ‚Ð¾Ð³Ð¾Ð²Ð°Ñ ÑÑƒÐ¼Ð¼Ð° Ð²Ð°ÑˆÐµÐ³Ð¾ Ð·Ð°ÐºÐ°Ð·Ð°
                    Ð¼Ð¾Ð¶ÐµÑ‚
                    Ð²Ð°Ñ€ÑŒÐ¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒÑÑ <br />
                    Ð² Ð¿Ñ€ÐµÐ´ÐµÐ»Ð°Ñ… 10%, ÐµÑÐ»Ð¸ Ð² Ð·Ð°ÐºÐ°Ð·Ðµ ÐµÑÑ‚ÑŒ Ð²ÐµÑÐ¾Ð²Ð¾Ð¹ Ñ‚Ð¾Ð²Ð°Ñ€. <br />
                    Ð’ Ñ‚Ð°ÐºÐ¾Ð¼ ÑÐ»ÑƒÑ‡Ð°Ðµ Ð±ÑƒÐ´ÐµÑ‚ Ð¾ÑÑƒÑ‰ÐµÑÑ‚Ð²Ð»ÐµÐ½ Ð²Ð¾Ð·Ð²Ñ€Ð°Ñ‚ Ñ‡Ð°ÑÑ‚Ð¸ Ð´ÐµÐ½ÐµÐ¶Ð½Ñ‹Ñ… ÑÑ€ÐµÐ´ÑÑ‚Ð² Ð¸Ð»Ð¸ <br /> Ð´Ð¾Ð¿Ð¾Ð»Ð½Ð¸Ñ‚ÐµÐ»ÑŒÐ½Ð¾Ðµ ÑÐ¿Ð¸ÑÐ°Ð½Ð¸Ðµ,
                    ÐºÐ°Ðº
                    Ñ‚Ð¾Ð»ÑŒÐºÐ¾ Ð·Ð°ÐºÐ°Ð· Ð±ÑƒÐ´ÐµÑ‚ ÑÐ¾Ð±Ñ€Ð°Ð½.</SText></div>
                <div className={ styles.line } />
                <DeliveryPlace order={ order } />
                <Order order={ order } />
                <Price order={ order } />
            </div>
        </div>
    }
}