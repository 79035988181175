import React from "react";
import styles from "./AgreementsAndRules.module.css"
import SText from "./SText";
import { Helmet } from "react-helmet";
import Header from "./Header";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import { useWindowDimensions } from "../utils/hooks";
import BreadCrumbs from "./BreadCrumbs";

export default () => {

    const { windowDimensions: { width } } = useWindowDimensions(600)

    const breadCrumbs = [
        {
            name: 'Ð¡Ð¾Ð³Ð»Ð°ÑˆÐµÐ½Ð¸Ñ Ð¸ Ð¿Ñ€Ð°Ð²Ð¸Ð»Ð°',
            to: '/the-agreements-and-rules'
        }
    ]

    return <div>
        <Helmet>
            <title>Ð¡Ð¾Ð³Ð»Ð°ÑˆÐµÐ½Ð¸Ñ Ð¸ Ð¿Ñ€Ð°Ð²Ð¸Ð»Ð°</title>
            <meta name="description" content='Ð¡Ð¾Ð³Ð»Ð°ÑˆÐµÐ½Ð¸Ñ Ð¸ Ð¿Ñ€Ð°Ð²Ð¸Ð»Ð°' />
            <meta name="keywords" content='Ð¡Ð¾Ð³Ð»Ð°ÑˆÐµÐ½Ð¸Ñ Ð¸ Ð¿Ñ€Ð°Ð²Ð¸Ð»Ð°' />
        </Helmet>
        <Header />
        { width < 992 && <MobileExtraInfo /> }
        <div className={ styles.wrapper }>
            <div className={ styles.container }>
                <BreadCrumbs _items={ breadCrumbs } />
                <div style={ { marginBottom: width > 762 ? 30 : 30 } }><SText fontSize={ 24 } fontWeight={ 900 } lineHeight={ 23 }>ÐŸÐ¾Ð»Ð¸Ñ‚Ð¸ÐºÐ° ÐºÐ¾Ð½Ñ„Ð¸Ð´ÐµÐ½Ñ†Ð¸Ð°Ð»ÑŒÐ½Ð¾ÑÑ‚Ð¸</SText></div>
                <div style={ { marginBottom: width > 762 ? 28 : 18 } }><SText fontSize={ 16 } fontWeight={ 900 } lineHeight={ 15 }>ÐŸÐ¾Ð»Ð¸Ñ‚Ð¸ÐºÐ° ÐºÐ¾Ð½Ñ„Ð¸Ð´ÐµÐ½Ñ†Ð¸Ð°Ð»ÑŒÐ½Ð¾ÑÑ‚Ð¸ Ñ€Ð°Ð·Ñ€Ð°Ð±Ð¾Ñ‚Ð°Ð½Ð° Ð²
                    ÑÐ¾Ð¾Ñ‚Ð²ÐµÑ‚ÑÑ‚Ð²Ð¸Ð¸ Ñ Ð·Ð°ÐºÐ¾Ð½Ð¾Ð´Ð°Ñ‚ÐµÐ»ÑŒÑÑ‚Ð²Ð¾Ð¼ Ð Ð¾ÑÑÐ¸Ð¹ÑÐºÐ¾Ð¹
                    Ð¤ÐµÐ´ÐµÑ€Ð°Ñ†Ð¸Ð¸.</SText></div>
                <div style={ { marginBottom: width > 762 ? 28 : 18 } }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>Ð’ÑÐµ Ð»Ð¸Ñ†Ð°, Ð·Ð°Ð¿Ð¾Ð»Ð½Ð¸Ð²ÑˆÐ¸Ðµ ÑÐ²ÐµÐ´ÐµÐ½Ð¸Ñ, ÑÐ¾ÑÑ‚Ð°Ð²Ð»ÑÑŽÑ‰Ð¸Ðµ Ð¿ÐµÑ€ÑÐ¾Ð½Ð°Ð»ÑŒÐ½Ñ‹Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ Ð½Ð° Ð´Ð°Ð½Ð½Ð¾Ð¼ ÑÐ°Ð¹Ñ‚Ðµ
                    seasonmarket.ru,
                    Ð°
                    Ñ‚Ð°ÐºÐ¶Ðµ
                    Ñ€Ð°Ð·Ð¼ÐµÑÑ‚Ð¸Ð²ÑˆÐ¸Ðµ Ð¸Ð½ÑƒÑŽ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸ÑŽ, Ð¾Ð±Ð¾Ð·Ð½Ð°Ñ‡ÐµÐ½Ð½Ñ‹Ð¼Ð¸ Ð´ÐµÐ¹ÑÑ‚Ð²Ð¸ÑÐ¼Ð¸ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´Ð°ÑŽÑ‚ ÑÐ²Ð¾Ðµ ÑÐ¾Ð³Ð»Ð°ÑÐ¸Ðµ Ð½Ð° Ð¾Ð±Ñ€Ð°Ð±Ð¾Ñ‚ÐºÑƒ
                    Ð¿ÐµÑ€ÑÐ¾Ð½Ð°Ð»ÑŒÐ½Ñ‹Ñ…
                    Ð´Ð°Ð½Ð½Ñ‹Ñ… Ð¸ Ð¸Ñ… Ð¿ÐµÑ€ÐµÐ´Ð°Ñ‡Ñƒ Ð¾Ð¿ÐµÑ€Ð°Ñ‚Ð¾Ñ€Ñƒ Ð¾Ð±Ñ€Ð°Ð±Ð¾Ñ‚ÐºÐ¸ Ð¿ÐµÑ€ÑÐ¾Ð½Ð°Ð»ÑŒÐ½Ñ‹Ñ… Ð´Ð°Ð½Ð½Ñ‹Ñ….
                </SText></div>
                <div style={ { marginBottom: width > 762 ? 22 : 42 } }><SText fontSize={ 16 } fontWeight={ 900 } lineHeight={ 15 }>ÐŸÐ¾Ð´ Ð¿ÐµÑ€ÑÐ¾Ð½Ð°Ð»ÑŒÐ½Ñ‹Ð¼Ð¸ Ð´Ð°Ð½Ð½Ñ‹Ð¼Ð¸ Ð“Ñ€Ð°Ð¶Ð´Ð°Ð½Ð¸Ð½Ð°
                    Ð¿Ð¾Ð½Ð¸Ð¼Ð°ÐµÑ‚ÑÑ Ð¿Ð¾Ð´Ð¿Ð¸ÑÐ½Ñ‹Ðµ Ñ„Ð¾Ñ€Ð¼Ñ‹ Ð¸ Ð»ÑŽÐ±Ð°Ñ Ð±Ð¸Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ‡ÐµÑÐºÐ°Ñ
                    Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ:</SText></div>
                <div className={ styles.dot }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>Ð¾Ð±Ñ‰Ð°Ñ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ (Ð¤. Ð˜. Ðž. Ð¿Ð¾ÑÐµÑ‚Ð¸Ñ‚ÐµÐ»Ñ);</SText></div>
                <div className={ styles.dot }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>Ð³Ð¾Ð´, Ñ‡Ð¸ÑÐ»Ð¾ Ð¸ Ð¼ÐµÑÑÑ† Ñ€Ð¾Ð¶Ð´ÐµÐ½Ð¸Ñ;</SText></div>
                <div className={ styles.dot }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°;</SText></div>
                <div className={ styles.dot }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>Ð¿Ð¾Ñ‡Ñ‚Ð¾Ð²Ñ‹Ð¹ Ð°Ð´Ñ€ÐµÑ;</SText></div>
                <div className={ styles.dot }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>email;</SText></div>
                <div className={ styles.dot }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>Ð»ÑŽÐ±Ð°Ñ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ Ð¸Ð· ÑÐ¾Ñ†Ð¸Ð°Ð»ÑŒÐ½Ñ‹Ñ… ÑÐµÑ‚ÐµÐ¹;</SText></div>
                <div style={ { marginBottom: width > 762 ? 30 : 30 } } className={ styles.dot }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>ÑÐµÑ€Ð¸Ñ, Ð½Ð¾Ð¼ÐµÑ€ Ð»ÑŽÐ±Ð¾Ð³Ð¾ Ð¸Ð½Ð´Ð¸Ð²Ð¸Ð´ÑƒÐ°Ð»ÑŒÐ½Ð¾Ð³Ð¾ Ð´Ð¾ÐºÑƒÐ¼ÐµÐ½Ñ‚Ð°.</SText></div>
                <div style={ { marginBottom: width > 762 ? 30 : 30 } }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>ÐŸÐ¾ÑÐµÑ‚Ð¸Ñ‚ÐµÐ»Ð¸ ÑÐ°Ð¹Ñ‚Ð° Ð½Ð°Ð¿Ñ€Ð°Ð²Ð»ÑÑŽÑ‚ ÑÐ²Ð¾Ð¸ Ð¿ÐµÑ€ÑÐ¾Ð½Ð°Ð»ÑŒÐ½Ñ‹Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ Ð¾Ð¿ÐµÑ€Ð°Ñ‚Ð¾Ñ€Ñƒ Ð² Ñ†ÐµÐ»ÑÑ… Ð¿Ð¾Ð»ÑƒÑ‡ÐµÐ½Ð¸Ñ ÐºÐ°ÐºÐ¾Ð¹-Ð»Ð¸Ð±Ð¾
                    Ð½ÐµÐ¾Ð±Ñ…Ð¾Ð´Ð¸Ð¼Ð¾Ð¹ Ð¿Ð¾ÑÐµÑ‚Ð¸Ñ‚ÐµÐ»ÑŽ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ð¸, Ð¿Ñ€Ð¸Ð½Ð°Ð´Ð»ÐµÐ¶Ð°Ñ‰ÐµÐ¹ ÑÐ°Ð¹Ñ‚Ñƒ seasonmarket.ru.</SText></div>
                <div style={ { marginBottom: width > 762 ? 30 : 30 } }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>Ð“Ñ€Ð°Ð¶Ð´Ð°Ð½Ð¸Ð½, Ð¿Ñ€Ð¸Ð½Ð¸Ð¼Ð°Ñ Ð½Ð°ÑÑ‚Ð¾ÑÑ‰ÐµÐµ ÑÐ¾Ð³Ð»Ð°ÑˆÐµÐ½Ð¸Ðµ, Ð²Ñ‹Ñ€Ð°Ð¶Ð°ÐµÑ‚ ÑÐ²Ð¾ÑŽ Ð·Ð°Ð¸Ð½Ñ‚ÐµÑ€ÐµÑÐ¾Ð²Ð°Ð½Ð½Ð¾ÑÑ‚ÑŒ Ð¸ Ð¿Ð¾Ð»Ð½Ð¾Ðµ
                    ÑÐ¾Ð³Ð»Ð°ÑÐ¸Ðµ,
                    Ñ‡Ñ‚Ð¾
                    Ð¾Ð±Ñ€Ð°Ð±Ð¾Ñ‚ÐºÐ° Ð¸Ñ… Ð¿ÐµÑ€ÑÐ¾Ð½Ð°Ð»ÑŒÐ½Ñ‹Ñ… Ð´Ð°Ð½Ð½Ñ‹Ñ… Ð¼Ð¾Ð¶ÐµÑ‚ Ð²ÐºÐ»ÑŽÑ‡Ð°Ñ‚ÑŒ Ð² ÑÐµÐ±Ñ ÑÐ»ÐµÐ´ÑƒÑŽÑ‰Ð¸Ðµ Ð´ÐµÐ¹ÑÑ‚Ð²Ð¸Ñ: ÑÐ±Ð¾Ñ€, ÑÐ¸ÑÑ‚ÐµÐ¼Ð°Ñ‚Ð¸Ð·Ð°Ñ†Ð¸ÑŽ,
                    Ð½Ð°ÐºÐ¾Ð¿Ð»ÐµÐ½Ð¸Ðµ,
                    Ñ…Ñ€Ð°Ð½ÐµÐ½Ð¸Ðµ, ÑƒÑ‚Ð¾Ñ‡Ð½ÐµÐ½Ð¸Ðµ (Ð¾Ð±Ð½Ð¾Ð²Ð»ÐµÐ½Ð¸Ðµ, Ð¸Ð·Ð¼ÐµÐ½ÐµÐ½Ð¸Ðµ), Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ð½Ð¸Ðµ, ÑƒÐ½Ð¸Ñ‡Ñ‚Ð¾Ð¶ÐµÐ½Ð¸Ðµ.</SText></div>
                <div style={ { marginBottom: 20 } }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>Ð“Ñ€Ð°Ð¶Ð´Ð°Ð½Ð¸Ð½ Ð³Ð°Ñ€Ð°Ð½Ñ‚Ð¸Ñ€ÑƒÐµÑ‚: Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ, Ð¸Ð¼ Ð¿Ñ€ÐµÐ´Ð¾ÑÑ‚Ð°Ð²Ð»ÐµÐ½Ð½Ð°Ñ, ÑÐ²Ð»ÑÐµÑ‚ÑÑ Ð¿Ð¾Ð»Ð½Ð¾Ð¹, Ñ‚Ð¾Ñ‡Ð½Ð¾Ð¹ Ð¸
                    Ð´Ð¾ÑÑ‚Ð¾Ð²ÐµÑ€Ð½Ð¾Ð¹;
                    Ð¿Ñ€Ð¸
                    Ð¿Ñ€ÐµÐ´Ð¾ÑÑ‚Ð°Ð²Ð»ÐµÐ½Ð¸Ð¸ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ð¸ Ð½Ðµ Ð½Ð°Ñ€ÑƒÑˆÐ°ÐµÑ‚ÑÑ Ð´ÐµÐ¹ÑÑ‚Ð²ÑƒÑŽÑ‰ÐµÐµ Ð·Ð°ÐºÐ¾Ð½Ð¾Ð´Ð°Ñ‚ÐµÐ»ÑŒÑÑ‚Ð²Ð¾ Ð Ð¾ÑÑÐ¸Ð¹ÑÐºÐ¾Ð¹ Ð¤ÐµÐ´ÐµÑ€Ð°Ñ†Ð¸Ð¸, Ð·Ð°ÐºÐ¾Ð½Ð½Ñ‹Ðµ
                    Ð¿Ñ€Ð°Ð²Ð° Ð¸
                    Ð¸Ð½Ñ‚ÐµÑ€ÐµÑÑ‹ Ñ‚Ñ€ÐµÑ‚ÑŒÐ¸Ñ… Ð»Ð¸Ñ†; Ð²ÑÑ Ð¿Ñ€ÐµÐ´Ð¾ÑÑ‚Ð°Ð²Ð»ÐµÐ½Ð½Ð°Ñ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ð° Ð“Ñ€Ð°Ð¶Ð´Ð°Ð½Ð¸Ð½Ð¾Ð¼ Ð² Ð¾Ñ‚Ð½Ð¾ÑˆÐµÐ½Ð¸Ð¸ ÑÐµÐ±Ñ
                    Ð»Ð¸Ñ‡Ð½Ð¾.</SText>
                </div>

                <div>
                    <table className={ styles.table }>
                        <tr><td>ÐŸÐ¾Ð»Ð½Ð¾Ðµ Ð½Ð°Ð¸Ð¼ÐµÐ½Ð¾Ð²Ð°Ð½Ð¸Ðµ</td> <td>ÐžÐ±Ñ‰ÐµÑÑ‚Ð²Ð¾ Ñ Ð¾Ð³Ñ€Ð°Ð½Ð¸Ñ‡ÐµÐ½Ð½Ð¾Ð¹ Ð¾Ñ‚Ð²ÐµÑ‚ÑÑ‚Ð²ÐµÐ½Ð½Ð¾ÑÑ‚ÑŒÑŽ Â«Ð£Ð¼Ð½Ñ‹Ð¹ ÑÐµÑ€Ð²Ð¸ÑÂ»</td></tr>
                        <tr><td>Ð¡Ð¾ÐºÑ€Ð°Ñ‰ÐµÐ½Ð½Ð¾Ðµ Ð½Ð°Ð¸Ð¼ÐµÐ½Ð¾Ð²Ð°Ð½Ð¸Ðµ</td> <td>ÐžÐžÐž Â«Ð£Ð¼Ð½Ñ‹Ð¹ ÑÐµÑ€Ð²Ð¸ÑÂ»</td></tr>
                        <tr><td>Ð®Ñ€Ð¸Ð´Ð¸Ñ‡ÐµÑÐºÐ¸Ð¹ Ð°Ð´Ñ€ÐµÑ</td> <td>127006, Ð³. ÐœÐ¾ÑÐºÐ²Ð°, ÑƒÐ». ÐšÑ€Ð°ÑÐ½Ð¾Ð¿Ñ€Ð¾Ð»ÐµÑ‚Ð°Ñ€ÑÐºÐ°Ñ, Ð´. 7, Ð¿Ð¾Ð´Ð²Ð°Ð»/Ð¾Ñ„Ð¸Ñ 1/105</td></tr>
                        <tr><td>Ð¤Ð°ÐºÑ‚Ð¸Ñ‡ÐµÑÐºÐ¸Ð¹ (Ð¿Ð¾Ñ‡Ñ‚Ð¾Ð²Ñ‹Ð¹) Ð°Ð´Ñ€ÐµÑ</td> <td>127006, Ð³. ÐœÐ¾ÑÐºÐ²Ð°, ÑƒÐ». ÐšÑ€Ð°ÑÐ½Ð¾Ð¿Ñ€Ð¾Ð»ÐµÑ‚Ð°Ñ€ÑÐºÐ°Ñ, Ð´. 7, Ð¿Ð¾Ð´Ð²Ð°Ð»/Ð¾Ñ„Ð¸Ñ 1/105</td></tr>
                        <tr><td>Ð›Ð¸ÑÑ‚ Ð·Ð°Ð¿Ð¸ÑÐ¸ Ð•Ð´Ð¸Ð½Ð¾Ð³Ð¾ Ð³Ð¾ÑÑƒÐ´Ð°Ñ€ÑÑ‚Ð²ÐµÐ½Ð½Ð¾Ð³Ð¾ Ñ€ÐµÐµÑÑ‚Ñ€Ð° ÑŽÑ€Ð¸Ð´Ð¸Ñ‡ÐµÑÐºÐ¸Ñ… Ð»Ð¸Ñ†</td> <td>Ð²Ñ‹Ð´Ð°Ð½ ÐœÐµÐ¶Ñ€Ð°Ð¹Ð¾Ð½Ð½Ð¾Ð¹ Ð¸Ð½ÑÐ¿ÐµÐºÑ†Ð¸Ñ Ð¤ÐµÐ´ÐµÑ€Ð°Ð»ÑŒÐ½Ð¾Ð¹ Ð½Ð°Ð»Ð¾Ð³Ð¾Ð²Ð¾Ð¹ ÑÐ»ÑƒÐ¶Ð±Ñ‹ â„– 46 Ð¿Ð¾ Ð³. ÐœÐ¾ÑÐºÐ²Ðµ 03.02.2022</td></tr>
                        <tr><td>ÐœÐµÑÑ‚Ð¾ Ð³Ð¾ÑÑƒÐ´Ð°Ñ€ÑÑ‚Ð²ÐµÐ½Ð½Ð¾Ð¹ Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð°Ñ†Ð¸Ð¸</td> <td>Ð³. ÐœÐ¾ÑÐºÐ²Ð°</td></tr>
                        <tr><td>ÐžÐ“Ð Ð</td> <td>1227700042646</td></tr>
                        <tr><td>Ð˜ÐÐ</td> <td>7707461633</td></tr>
                        <tr><td>ÐšÐŸÐŸ</td> <td>770701001</td></tr>
                        <tr><td>ÐžÐšÐ’Ð­Ð”</td> <td>47.91, 10.71, 47.99, 56.10, 73.11, 73.12. 73.20.1</td></tr>
                        <tr><td>Ð ÑƒÐºÐ¾Ð²Ð¾Ð´Ð¸Ñ‚ÐµÐ»ÑŒ</td> <td>Ð“ÐµÐ½ÐµÑ€Ð°Ð»ÑŒÐ½Ñ‹Ð¹ Ð´Ð¸Ñ€ÐµÐºÑ‚Ð¾Ñ€ Ð‘Ð°ÑÑ…Ð°Ð½Ð¾Ð²Ð° Ð‘ÐµÑ€Ð»Ð°Ð½Ñ‚ Ð£Ð¼Ð°Ñ€Ð¾Ð²Ð½Ð°</td></tr>
                        <tr><td>Ð‘Ð°Ð½ÐºÐ¾Ð²ÑÐºÐ¸Ðµ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ñ‹:</td> <td></td></tr>
                        <tr><td>Ð Ð°ÑÑ‡ÐµÑ‚Ð½Ñ‹Ð¹ ÑÑ‡ÐµÑ‚</td> <td>40702810202370009754</td></tr>
                        <tr><td>ÐšÐ¾Ñ€Ñ€ÐµÑÐ¿Ð¾Ð½Ð´ÐµÐ½Ñ‚ÑÐºÐ¸Ð¹ ÑÑ‡ÐµÑ‚</td> <td>30101810200000000593</td></tr>
                        <tr><td>ÐŸÐ¾Ð»Ð½Ð¾Ðµ Ð½Ð°Ð¸Ð¼ÐµÐ½Ð¾Ð²Ð°Ð½Ð¸Ðµ Ð±Ð°Ð½ÐºÐ°</td> <td>ÐÐž Â«ÐÐ›Ð¬Ð¤Ð-Ð‘ÐÐÐšÂ»</td></tr>
                        <tr><td>Ð‘Ð˜Ðš</td> <td>044525593</td></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>

}