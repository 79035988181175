import React from "react";
import SText from "./SText";
import RoundedButton from "./RoundedButton";
import notFound from "../assets/images/notFound.svg"
import notFoundMobile from "../assets/images/notFoundMobile.svg"
import styles from "./NotFound.module.css"
import Colors from "../utils/Colors";
import {useWindowDimensions} from "../utils/hooks";
import Header from "./Header";
import {NavLink} from "react-router-dom";

export default () => {

    const {windowDimensions: {width}} = useWindowDimensions()

    return <div>
        <Header/>
        <div className={styles.wrapper}>
            <div className={styles.image}><img src={width > 680 ? notFound : notFoundMobile}/></div>
            <div className={styles.text}><SText fontSize={width >= 680 ? 18 : 14} fontWeight={400}>Ð’Ð¾Ð·Ð¼Ð¾Ð¶Ð½Ð¾, Ñ‚Ð¾Ð²Ð°Ñ€,
                ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ð¹ Ð²Ñ‹ Ð¸ÑÐºÐ°Ð»Ð¸, Ð±Ñ‹Ð» Ð¿ÐµÑ€ÐµÐ¸Ð¼ÐµÐ½Ð¾Ð²Ð°Ð½. <br/>
                ÐŸÐ¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, Ð²Ð¾ÑÐ¿Ð¾Ð»ÑŒÐ·ÑƒÐ¹Ñ‚ÐµÑÑŒ ÐºÐ°Ñ‚Ð°Ð»Ð¾Ð³Ð¾Ð¼ Ð¸Ð»Ð¸ Ð¿Ð¾Ð¸ÑÐºÐ¾Ð¼.</SText></div>
            <div className={styles.button}><NavLink to={"/"}><RoundedButton label={'ÐÐ° Ð³Ð»Ð°Ð²Ð½ÑƒÑŽ'} activeColor={Colors.mainGreen}/></NavLink></div>
        </div>
    </div>
}