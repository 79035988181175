
const $body = document.querySelector('body');

export function launchPayment({
    userId = 'test',
    preventRefresh = false,
    type = 1,
    amount = 1,
    orderId = 123,
    onSuccess = () => { },
    onFail = () => { },
    onComplete = () => { }
}) {

    const widget = new window.cp.CloudPayments();
    widget.pay('charge', {
        publicId: 'pk_5dfe5d20735e58f3a554157a8a94a', //id Ð¸Ð· Ð»Ð¸Ñ‡Ð½Ð¾Ð³Ð¾ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚Ð°
        description: type === 1 ? 'ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð·Ð°ÐºÐ°Ð·Ð° ' + orderId : 'Ð”Ð¾Ð±Ð°Ð²Ð»ÐµÐ½Ð¸Ðµ Ð½Ð¾Ð²Ð¾Ð¹ ÐºÐ°Ñ€Ñ‚Ñ‹', //Ð½Ð°Ð·Ð½Ð°Ñ‡ÐµÐ½Ð¸Ðµ
        amount: amount, //ÑÑƒÐ¼Ð¼Ð°
        currency: 'RUB', //Ð²Ð°Ð»ÑŽÑ‚Ð°
        accountId: userId, //Ð¸Ð´ÐµÐ½Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚Ð¾Ñ€ Ð¿Ð»Ð°Ñ‚ÐµÐ»ÑŒÑ‰Ð¸ÐºÐ° (Ð½ÐµÐ¾Ð±ÑÐ·Ð°Ñ‚ÐµÐ»ÑŒÐ½Ð¾)
        invoiceId: orderId, //Ð½Ð¾Ð¼ÐµÑ€ Ð·Ð°ÐºÐ°Ð·Ð°  (Ð½ÐµÐ¾Ð±ÑÐ·Ð°Ñ‚ÐµÐ»ÑŒÐ½Ð¾)
        skin: "modern", //Ð´Ð¸Ð·Ð°Ð¹Ð½ Ð²Ð¸Ð´Ð¶ÐµÑ‚Ð° (Ð½ÐµÐ¾Ð±ÑÐ·Ð°Ñ‚ÐµÐ»ÑŒÐ½Ð¾)
    },
        {
            onSuccess: (...args) => {
                onSuccess(...args)
                setTimeout(() => {
                    !preventRefresh && window.location.reload()
                }, 600)
            },
            onFail: (...args) => {
                onFail(...args)
                setTimeout(() => {
                    !preventRefresh && window.location.reload()
                }, 600)
            },
            onComplete: (...args) => {
                onComplete(...args)
                setTimeout(() => {
                    !preventRefresh && window.location.reload()
                }, 600)
            }
        }
    )
}
