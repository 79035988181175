import React, {useEffect, useState} from "react";
import SText from "../../../components/SText";
import styles from "../AuthModal.module.css"
import TouchableOpacity from "../../../components/TouchableOpacity";
import CodeInput from "./CodeInput";
import Colors from "../../../utils/Colors";
import {text} from "../../../utils/mixins";
import {useWindowDimensions} from "../../../utils/hooks";

const Confirm = ({phone = '+7 (999) 999-99-99', onReenter = () => {}, onSubmit = () => {}, error = '', onDismissError = () => {}}) => {
    const [code, setCode] = useState('')

    useEffect(() => {
        if (code.length === 4) {
            onSubmit(code)
        }
    }, [code])

    const {windowDimensions: {width}} = useWindowDimensions()

    return <div className={styles.container}>
        <span style={text(20, 900, 3, true)} className={styles.topLabel}>ÐŸÐžÐ”Ð¢Ð’Ð•Ð Ð”Ð˜Ð¢Ð• ÐÐžÐœÐ•Ð &nbsp;Ð¢Ð•Ð›Ð•Ð¤ÐžÐÐ</span>
        <SText largerGap={true} fontSize={16} fontWeight={400} style={{
            marginTop: width >= 768 ? 13 : 0,
            textAlign: 'center',
            alignSelf: 'center',
            display: 'grid',
            placeItems: 'center'
        }}>
            {'Ð’Ð°Ñˆ Ð½Ð¾Ð¼ÐµÑ€'}
        </SText>
        <SText largerGap={true}
               style={{textAlign: 'center', display: 'grid', placeItems: 'center', margin: '13px 0 18px'}} fontSize={16}
               fontWeight={700}>
            {phone}
        </SText>
        <TouchableOpacity className={styles.enterPhoneAgain} onClick={onReenter}>
            <SText
                style={{display: 'grid', placeItems: 'center', marginBottom: '42px', borderBottom: '1px solid #bdbdbd'}}
                fontSize={12} fontWeight={700} color={'#BDBDBD'}>
                {'Ð’Ð²ÐµÑÑ‚Ð¸ Ð¼Ð¾Ð¹ Ð½Ð¾Ð¼ÐµÑ€ Ð·Ð°Ð½Ð¾Ð²Ð¾'}
            </SText>
        </TouchableOpacity>
        <span style={{margin: '-8px 0 7px 0'}} className={styles.bottomLabel}>
                    ÐœÑ‹ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð¸Ð»Ð¸ Ð²Ð°Ð¼ ÑÐ¾Ð¾Ð±Ñ‰ÐµÐ½Ð¸Ðµ <br/>
                    Ñ 4-Ñ… Ð·Ð½Ð°Ñ‡Ð½Ñ‹Ð¼ ÐºÐ¾Ð´Ð¾Ð¼. <br/>
                    Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ ÐµÐ³Ð¾ Ð² Ð¿Ð¾Ð»Ðµ Ð½Ð¸Ð¶Ðµ
                </span>
        <CodeInput code={code} setCode={setCode} onDismissError={onDismissError}/>
        <SText className={styles.error} color={Colors.red}>{error}</SText>
    </div>
}

export default Confirm
