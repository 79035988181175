import React, {useContext} from "react";
import exit from "../assets/images/exit.svg"
import SText from "./SText";
import RoundedButton from "./RoundedButton";
import styles from "./Exit.module.css"
import Colors from "../utils/Colors";
import {useWindowDimensions} from "../utils/hooks";
import DataContext from "../data/DataContext";
import ModalContext from "../data/ModalContext";

export default ({onClose}) => {

    const {isMobile, windowDimensions:{width}} = useWindowDimensions()
    const {signOut} = useContext(DataContext)
    const {open: _open, close} = useContext(ModalContext)

    if (isMobile) {
        return <div className={styles.wrapper}>
            <img style={{marginBottom:'25px'}} src={exit}/>
            <SText style={{textAlign:'center', marginBottom:'30px'}} fontSize={18} fontWeight={700}>Ð’Ñ‹ ÑƒÐ²ÐµÑ€ÐµÐ½Ñ‹, Ñ‡Ñ‚Ð¾ Ñ…Ð¾Ñ‚Ð¸Ñ‚Ðµ Ð²Ñ‹Ð¹Ñ‚Ð¸ ÑÐ¾ ÑÐ²Ð¾ÐµÐ³Ð¾ Ð°ÐºÐºÐ°ÑƒÐ½Ñ‚Ð°?</SText>
            <RoundedButton onPress={() => {close('exit')}} activeColor={Colors.orange} containerStyle={{marginBottom:'25px', width:'259px', height:'50px'}} label={'ÐžÑ‚Ð¼ÐµÐ½Ð°'}/>
            <RoundedButton onPress={() => {signOut(); close('user'); close('exit')}} containerStyle={{width:'259px', height:'50px'}} label={'Ð”Ð°, Ð²Ñ‹Ð¹Ñ‚Ð¸'}/>
        </div>
    } else return <div className={styles.wrapper}>
        <img style={{marginBottom:'30px'}} src={exit}/>
        <SText style={{textAlign:'center', marginBottom:'30px'}} fontSize={18} fontWeight={700}>Ð’Ñ‹ ÑƒÐ²ÐµÑ€ÐµÐ½Ñ‹, Ñ‡Ñ‚Ð¾ Ñ…Ð¾Ñ‚Ð¸Ñ‚Ðµ Ð²Ñ‹Ð¹Ñ‚Ð¸ ÑÐ¾ ÑÐ²Ð¾ÐµÐ³Ð¾ Ð°ÐºÐºÐ°ÑƒÐ½Ñ‚Ð°?</SText>
        <RoundedButton onPress={() => {close('exit')}} activeColor={Colors.orange} containerStyle={{marginBottom:'25px', width:'259px', height:'50px'}} label={'ÐžÑ‚Ð¼ÐµÐ½Ð°'}/>
        <RoundedButton onPress={() => {signOut(); close('user');close('exit')}} containerStyle={{width:'259px', height:'50px'}} label={'Ð”Ð°, Ð²Ñ‹Ð¹Ñ‚Ð¸'}/>
    </div>
}