import React, { useContext } from "react";
import styles from "./Footer.module.css"
import footerLogo from "../assets/images/footerLogo.svg"
import visa from "../assets/images/visaFooter.svg"
import masterCard from "../assets/images/masterCardFooter.svg"
import supportMail from "../assets/images/supportMail.svg"
import supportWhatsapp from "../assets/images/supportWhatsapp.svg"
import vk from "../assets/images/vkFooter.svg"
import youtube from "../assets/images/youtubeFooter.svg"
import telegram from '../assets/images/telegram.svg'
import odn from "../assets/images/odnFooter.svg"
import logoFooterMobile from "../assets/images/newLogoFooterMobile.svg"
import SText from "./SText";
import { NavLink } from "react-router-dom";
import { useRestrictions, useWindowDimensions } from "../utils/hooks";
import ModalContext from "../data/ModalContext";
import cn from "classnames";

const Footer = () => {

    const { open: _open } = useContext(ModalContext)
    const open = useRestrictions(_open)
    const { windowDimensions: { width } } = useWindowDimensions()

    return <footer className={ styles.wrapper }>
        <div className={ styles.container }>
            <NavLink style={ width <= 768 && { display: 'none' } } to={ '/' }>
                <div onClick={ () => { window.scrollTo(0, 0); } } className={ styles.logo }>
                    <div style={ { marginBottom: 10 } }><img src={ footerLogo } /></div>
                    <div><SText fontSize={ 12 } fontWeight={ 550 } color={ '#fff' }>{ 'Â© SeasonMarket, 2013-2021' }</SText>
                    </div>
                </div>
            </NavLink>
            <NavLink to={ '/' }>
                <div onClick={ () => { window.scrollTo(0, 0); } } className={ styles.logoFooterMobile }>
                    <div style={ { marginBottom: '9px' } }><img src={ logoFooterMobile } /></div>
                    <div style={ { textAlign: 'center' } }>
                        <div><SText fontSize={ 12 } fontWeight={ 550 } color={ '#fff' }>{ 'Â© SeasonMarket,' }</SText></div>
                        <div><SText fontSize={ 12 } fontWeight={ 550 } color={ '#fff' }>{ '2013-2021' }</SText></div>
                    </div>
                </div>
            </NavLink>
            <div className={ styles.company }>
                <div className={ styles.companyHeader }><SText fontSize={ 14 } fontWeight={ 700 }
                    color={ '#fff' }>ÐšÐžÐœÐŸÐÐÐ˜Ð¯</SText></div>
                <div className={ styles.companyContent }>
                    <div style={ { marginBottom: '8px' } }><NavLink onClick={ () => { window.scrollTo(0, 0); } } to={ '/about-season-market' }><SText fontSize={ 14 }
                        fontWeight={ 400 }
                        color={ '#fff' }>Ðž
                        SeasonMarket</SText></NavLink></div>
                    {/*                    <div style={{marginBottom: '8px'}}><SText fontSize={14} fontWeight={400} color={'#fff'}>ÐÐ°ÑˆÐ°
                        ÐºÐ¾Ð¼Ð°Ð½Ð´Ð°</SText></div>
                    <div style={{marginBottom: '8px'}}><SText fontSize={14} fontWeight={400}
                                                              color={'#fff'}>ÐŸÐ¾ÑÑ‚Ð°Ð²Ñ‰Ð¸ÐºÐ¸</SText></div>
                    <div style={{marginBottom: '8px'}}><SText fontSize={14} fontWeight={400}
                                                              color={'#fff'}>Ð’Ð°ÐºÐ°Ð½ÑÐ¸Ð¸</SText></div>*/}
                </div>
            </div>
            <div className={ styles.info }>
                <div className={ styles.infoHeader }><SText fontSize={ 14 } fontWeight={ 700 }
                    color={ '#fff' }>Ð˜ÐÐ¤ÐžÐ ÐœÐÐ¦Ð˜Ð¯</SText></div>
                <div className={ styles.infoContent }>
                    <NavLink to={ '/delivery-and-payment' }>
                        <div onClick={ () => { window.scrollTo(0, 0); } } style={ { marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ°
                            Ð¸ Ð¾Ð¿Ð»Ð°Ñ‚Ð°</SText></div>
                    </NavLink>
                    <NavLink to={ '/bonus-system' }>
                        <div onClick={ () => { window.scrollTo(0, 0); } } style={ { marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>Ð‘Ð¾Ð½ÑƒÑÐ½Ð°Ñ ÑÐ¸ÑÑ‚ÐµÐ¼Ð°</SText></div>
                    </NavLink>
                    <NavLink to={ '/the-agreements-and-rules' }>
                        <div onClick={ () => { window.scrollTo(0, 0); } } style={ { marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>Ð¡Ð¾Ð³Ð»Ð°ÑˆÐµÐ½Ð¸Ñ Ð¸ Ð¿Ñ€Ð°Ð²Ð¸Ð»Ð°</SText></div>
                    </NavLink>
                    <NavLink to={ '/offer' }>
                        <div onClick={ () => { window.scrollTo(0, 0); } } style={ { marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>ÐžÑ„ÐµÑ€Ñ‚Ð°</SText></div>
                    </NavLink>
                    {/*                    <div style={{marginBottom: '8px'}}><SText fontSize={14} fontWeight={400} color={'#fff'}>Ð¡ÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚Ñ‹
                        ÐºÐ°Ñ‡ÐµÑÑ‚Ð²Ð°</SText></div>
                    <div style={{marginBottom: '8px'}}><SText fontSize={14} fontWeight={400} color={'#fff'}>ÐŸÑ€Ð°Ð²Ð¸Ð»Ð°
                        Ð²Ð¾Ð·Ð²Ñ€Ð°Ñ‚Ð° Ñ‚Ð¾Ð²Ð°Ñ€Ð°</SText></div>
                    <div style={{marginBottom: '8px'}}><SText fontSize={14} fontWeight={400} color={'#fff'}>ÐÐ¾Ð²Ñ‹Ð¼
                        Ð¿Ð°Ñ€Ñ‚Ð½Ñ‘Ñ€Ð°Ð¼</SText></div>
                    <div style={{marginBottom: '8px'}}><SText fontSize={14} fontWeight={400}
                                                              color={'#fff'}>ÐšÐ¾Ð½Ñ‚Ð°ÐºÑ‚Ñ‹</SText></div>*/}
                </div>
            </div>
            <div className={ styles.personalAccount }>
                <div className={ styles.personalHeader }><SText fontSize={ 14 } fontWeight={ 700 }
                    color={ '#fff' }>Ð›Ð˜Ð§ÐÐ«Ð™ ÐšÐÐ‘Ð˜ÐÐ•Ð¢</SText></div>
                <div className={ styles.personalContent }>
                    <div onClick={ () => { open('user') } } style={ { cursor: 'pointer', marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>ÐœÐ¾Ð¹
                        Ð°ÐºÐºÐ°ÑƒÐ½Ñ‚</SText></div>
                    <div onClick={ () => { open('userHistory') } } style={ { cursor: 'pointer', marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>Ð˜ÑÑ‚Ð¾Ñ€Ð¸Ñ
                        Ð·Ð°ÐºÐ°Ð·Ð¾Ð²</SText></div>
                    {/*                    <div style={{marginBottom: '22px'}}><SText fontSize={14} fontWeight={400} color={'#fff'}>Ð¡Ð¾Ð³Ð»Ð°ÑˆÐµÐ½Ð¸Ñ
                        Ð¸ Ð¿Ñ€Ð°Ð²Ð¸Ð»Ð°</SText></div>*/}
                    <div className={ styles.personalCards }>
                        <div style={ { marginRight: 11 } }><img src={ visa } /></div>
                        <div><img src={ masterCard } /></div>
                    </div>
                </div>
            </div>
            <div className={ styles.order }>
                <div className={ styles.orderHeader }><SText fontWeight={ 700 } fontSize={ 14 } color={ '#FFA353' }>Ð¡Ð²ÑÐ·Ð°Ñ‚ÑŒÑÑ Ñ Ð½Ð°Ð¼Ð¸:</SText></div>
                <div className={ styles.personalContent }>
                    <div style={ { marginBottom: '8px' } }><a href="tel:+74952155144"><SText fontSize={ 14 } fontWeight={ 700 }
                        color={ '#fff' }>+7 (495)
                        215-51-44</SText></a></div>
                    {/*                    <div style={{marginBottom: '23px'}}><a href="https://wa.me/79771260296" target="_blank"><SText fontSize={14} fontWeight={700}
                                                                                          color={'#fff'}>+7
                        (977) 126-02-96</SText></a></div>*/}
                    {/* <div style={{marginBottom: '23px'}}><a href="https://wa.me/79660363433" target="_blank"><SText fontSize={14} fontWeight={700}
                                                                                                                   color={'#fff'}>+7 (966) 036-34-33</SText></a></div> */}
                    <div style={ { marginBottom: '8px' } } className={ styles.orderSupport }>
                        <div style={ { marginRight: 11 } }><img src={ supportMail } /></div>
                        <div><a href="mailto:support@seasonmarket.ru"><SText fontSize={ 14 } fontWeight={ 400 }
                            color={ '#fff' }>support@seasonmarket.ru</SText></a>
                        </div>
                    </div>
                    {/*                    <a href="https://wa.me/79771260296" target="_blank">
                        <div className={styles.orderWhatsapp}>
                            <div style={{marginRight: 11}}><img src={supportWhatsapp}/></div>
                            <div><SText fontSize={14} fontWeight={400} color={'#fff'}>+7 (977) 126-02-96</SText></div>
                        </div>
                    </a>*/}
                    <a href="https://wa.me/74952155144" target="_blank">
                        <div className={ styles.orderWhatsapp }>
                            <div style={ { marginRight: 11 } }><img src={ supportWhatsapp } /></div>
                            <div><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>+7 (495) 215-51-44</SText></div>
                        </div>
                    </a>
                </div>
            </div>
            <div className={ cn(styles.social) }>
                {/*<a target="_blank" href="https://www.instagram.com/seasonmarket.ru/?hl=ru"><div><img src={instagram}/></div></a>*/ }
                <a target="_blank" href="https://t.me/SMseasonmarket"><div><img src={ telegram } /></div></a>
                <a target="_blank" href="https://vk.com/season_market"><div><img src={ vk } /></div></a>
                <a target="_blank" href="https://www.youtube.com/channel/UCq3qm5yp-Dq__LbJpAnonaw"><div><img src={ youtube } /></div></a>
                <a target="_blank" href="https://ok.ru/group/57961469968460"><div><img src={ odn } /></div></a>
            </div>

        </div>
    </footer>
}

export default Footer
