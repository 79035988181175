import React, {useEffect, useState} from "react";
import styles from "./Unsubscribe.module.css"
import SText from "./SText";
import greenCircle from "../assets/images/emptyCircle.png"
import greenTick from "../assets/images/greenTick.png"
import {Helmet} from "react-helmet";
import Header from "./Header";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import {useWindowDimensions} from "../utils/hooks";
import BreadCrumbs from "./BreadCrumbs";
import {unsubscribe} from "../api";

const reasons = [
    {reason: 'Ð¯ Ð½Ðµ Ð»ÑŽÐ±Ð»ÑŽ Ñ€Ð°ÑÑÑ‹Ð»ÐºÐ¸'},
    {reason: 'Ð’Ð°ÑˆÐ¸ Ñ€Ð°ÑÑÑ‹Ð»ÐºÐ¸ Ð¿Ñ€Ð¸Ñ…Ð¾Ð´ÑÑ‚ ÑÐ»Ð¸ÑˆÐºÐ¾Ð¼ Ñ‡Ð°ÑÑ‚Ð¾'},
    {reason: 'ÐÐµÐ¸Ð½Ñ‚ÐµÑ€ÐµÑÐ½Ð°Ñ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ'},
    {reason: 'ÐÐµÐ¸Ð½Ñ‚ÐµÑ€ÐµÑÐ½Ñ‹Ðµ Ñ‚Ð¾Ð²Ð°Ñ€Ñ‹ Ð² Ñ€Ð°ÑÑÑ‹Ð»ÐºÐµ'},
    {reason: 'Ð¯ ÑƒÐ¶Ðµ Ð½Ðµ ÑÐ²Ð»ÑÑŽÑÑŒ Ð²Ð°ÑˆÐ¸Ð¼ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð¾Ð¼'},
    {reason: 'Ð˜Ð½Ð°Ñ Ð¿Ñ€Ð¸Ñ‡Ð¸Ð½Ð°'}
]

const Checkbox = ({text}) => {
    const [toggle, setToggle] = useState(false)
    return <div style={{display: 'flex', alignItems: 'center', marginBottom: 15}} onClick={() => setToggle(!toggle)}>
        <div style={{marginRight: 10}}><img style={{height: 26, width: 26}} src={toggle ? greenTick : greenCircle}/>
        </div>
        <div><SText fontSize={16} fontWeight={550} lineHeight={18}>{text}</SText></div>
    </div>
}

export default () => {
    const {windowDimensions: {width}} = useWindowDimensions(600)
    const [checked, setChecked] = useState([])
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token')
    const [submited, setSubmited] = useState(false)

    const submit = async () => {
        if (checked.length) {
            await unsubscribe(token, checked)
            setSubmited(true)
        }
    }

    const breadCrumbs = [
        {
            name: 'ÐžÑ‚Ð¿Ð¸ÑÐºÐ° Ð¾Ñ‚ Ñ€Ð°ÑÑÑ‹Ð»ÐºÐ¸',
            to: '/unsubscribe'
        }
    ]
    return <div>
        <Helmet>
            <title>ÐžÑ‚Ð¿Ð¸ÑÐºÐ° Ð¾Ñ‚ Ñ€Ð°ÑÑÑ‹Ð»ÐºÐ¸</title>
            <meta name="description" content='ÐžÑ‚Ð¿Ð¸ÑÐºÐ° Ð¾Ñ‚ Ñ€Ð°ÑÑÑ‹Ð»ÐºÐ¸'/>
            <meta name="keywords" content='ÐžÑ‚Ð¿Ð¸ÑÐºÐ° Ð¾Ñ‚ Ñ€Ð°ÑÑÑ‹Ð»ÐºÐ¸'/>
        </Helmet>
        <Header/>
        {width < 992 && <MobileExtraInfo/>}
        <div className={styles.container}>
            <BreadCrumbs _items={breadCrumbs}/>
            {submited &&
            <div style={{marginBottom: 50}}>
                <div><SText fontSize={16} fontWeight={550} lineHeight={18}>ÐœÑ‹ Ð¾Ñ‚Ð¿Ð¸ÑÐ°Ð»Ð¸ Ð²Ð°Ñ Ð¾Ñ‚ Ð½Ð°ÑˆÐµÐ¹ Ñ€Ð°ÑÑÑ‹Ð»ÐºÐ¸</SText>
                </div>

                <div><SText fontSize={16} fontWeight={550} lineHeight={18}>Ð¡Ð¿Ð°ÑÐ¸Ð±Ð¾! Ð’Ð°Ñˆ Ð¾Ñ‚Ð²ÐµÑ‚ Ð¿Ð¾Ð¼Ð¾Ð¶ÐµÑ‚ Ð½Ð°Ð¼ ÑÑ‚Ð°Ñ‚ÑŒ Ð»ÑƒÑ‡ÑˆÐµ!</SText>
                </div></div>}
            {!submited && <div>
                <div style={{marginBottom: 15}}><SText fontSize={16} fontWeight={550} lineHeight={18}>Ð Ð°ÑÑÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð½Ð°Ð¼,
                    Ð¿Ð¾Ñ‡ÐµÐ¼Ñƒ Ð²Ñ‹ Ñ€ÐµÑˆÐ¸Ð»Ð¸ Ð¾Ñ‚Ð¿Ð¸ÑÐ°Ñ‚ÑŒÑÑ
                    Ð¾Ñ‚
                    Ð½Ð°ÑˆÐµÐ¹ Ñ€Ð°ÑÑÑ‹Ð»ÐºÐ¸?</SText></div>
                {
                    reasons.map(value => {
                        const isChecked = checked.includes(value.reason)
                        return <div
                            onClick={() => isChecked ? setChecked(checked.filter(v => v !== value.reason)) : setChecked(prev => [...prev, value.reason])}>
                            <Checkbox text={value.reason}/></div>
                    })
                }
                <div style={{
                    width: 335,
                    background: !checked.length?'#abebc6':'#2DCC70',
                    borderRadius: 40,
                    height: 50,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '40px 0'
                }} onClick={submit}><SText fontWeight={700} fontSize={18} lineHeight={18}
                                           color={'#fff'}>ÐžÑ‚Ð¿Ð¸ÑÐ°Ñ‚ÑŒÑÑ</SText>
                </div>
            </div>}
        </div>
    </div>
}