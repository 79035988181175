import React, {useContext, useState} from "react";
import styles from "./AccountBonus.module.css"
import SText from "../../components/SText";
import copy from "../../assets/images/copy.svg"
import TouchableOpacity from "../../components/TouchableOpacity";
import {useWindowDimensions} from "../../utils/hooks";
import info from "../../assets/images/info.svg"
import NewModal from "../../components/NewModal";
import RoundedButton from "../../components/RoundedButton";
import closeIcon from "../../assets/images/closeButton.svg";
import DataContext from "../../data/DataContext";
import {declOfNum} from "../../utils/other";
import anketaRef from '../../assets/images/anketa/anketaRef.svg'
import {NavLink} from "react-router-dom";

const PromoModal = ({onClose}) => {

    return <div className={styles.modalWrapper}>
        <TouchableOpacity onClick={onClose}>
            <div className={styles.circle}>
                <img src={closeIcon}/>
            </div>
        </TouchableOpacity>
        <SText style={{marginBottom: '25px'}} fontSize={20} fontWeight={700}>Ð ÑÑ‚Ð¾ Ð²Ð°Ñˆ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´!</SText>
        <SText style={{marginBottom: '30px'}} fontSize={16} fontWeight={550}>ÐŸÐ¾Ð´Ð°Ñ€Ð¸Ñ‚Ðµ Ð´Ñ€ÑƒÐ³Ñƒ 300 Ð±Ð¾Ð½ÑƒÑÐ¾Ð² <br/>
            Ð½Ð° Ð¿ÐµÑ€Ð²Ñ‹Ð¹ Ð·Ð°ÐºÐ°Ð· Ð¸ Ð¿Ð¾Ð»ÑƒÑ‡Ð¸Ñ‚Ðµ <br/>
            300 Ð±Ð¾Ð½ÑƒÑÐ¾Ð² Ð½Ð° ÑÐ²Ð¾Ð¹ ÑÑ‡Ñ‘Ñ‚. <br/>
            Ð”Ð»Ñ ÑÑ‚Ð¾Ð³Ð¾ Ð¿Ð¾Ð´ÐµÐ»Ð¸Ñ‚ÐµÑÑŒ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´Ð¾Ð¼ <br/> ÑÐ¾ ÑÐ²Ð¾Ð¸Ð¼Ð¸ Ð´Ñ€ÑƒÐ·ÑŒÑÐ¼Ð¸ Ð¸ Ð¾Ð¶Ð¸Ð´Ð°Ð¹Ñ‚Ðµ, <br/> ÐºÐ¾Ð³Ð´Ð° Ð¾Ð½Ð¸ Ð¾Ñ„Ð¾Ñ€Ð¼ÑÑ‚ Ð·Ð°ÐºÐ°Ð·!</SText>
        <div style={{width: '259px', height: '50px'}}><RoundedButton onPress={onClose} label={'Ð¯ÑÐ½Ð¾'}/></div>
    </div>
}

export default ({onClose}) => {

    const {isMobile, windowDimensions: {width}} = useWindowDimensions(650)

    const {user} = useContext(DataContext)

    const wordForms = ['Ð±Ð¾Ð½ÑƒÑ', 'Ð±Ð¾Ð½ÑƒÑÐ°', 'Ð±Ð¾Ð½ÑƒÑÐ¾Ð²']

    const [mv, smv] = useState(false)


    if (isMobile) {
        return <div className={styles.wrapper}>
            <div className={styles.bonusInfo}>
                {!user.data.isPolled && <div className={styles.anketa}>
                    <div className={styles.anketaInfo}>
                        <img src={anketaRef}/>
                        <SText fontSize={14} fontWeight={900} color={'#2dcc70'}
                               lineHeight={14}>{'Ð—Ð°Ð¿Ð¾Ð»Ð½Ð¸Ñ‚Ðµ Ð°Ð½ÐºÐµÑ‚Ñƒ Ð¸ Ð¿Ð¾Ð»ÑƒÑ‡Ð¸Ñ‚Ðµ '} <SText fontSize={22} lineHeight={22}
                                                                                       color={'#2dcc70'}
                                                                                       fontWeight={900}>{'1200 Ð±Ð¾Ð½ÑƒÑÐ¾Ð²!'}</SText></SText>
                    </div>
                    <NavLink onClick={() => {
                        window.scrollTo(0, 0)
                        onClose()
                    }} to={'/closer'}>
                        <div className={styles.anketaRef}>
                            <SText>{'ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ðº Ð°Ð½ÐºÐµÑ‚Ðµ >>'}</SText>
                        </div>
                    </NavLink>
                </div>}

                <SText style={{marginBottom: '9px'}} fontSize={14} fontWeight={550}>ÐÐ° Ð»Ð¸Ñ‡Ð½Ð¾Ð¼ ÑÑ‡Ñ‘Ñ‚Ðµ:</SText>
                <SText style={{marginBottom: '20px'}} fontSize={28}
                       fontWeight={900}>{user.data?.scores || 0} {declOfNum(user.data?.scores || 0, wordForms)}</SText>
                <SText style={{lineHeight: '1.2', marginBottom: '15px'}} fontSize={14} fontWeight={500}
                       color={'#909090'}>1 Ð±Ð¾Ð½ÑƒÑ = 1
                    Ñ€ÑƒÐ±Ð»ÑŒ<br/>Ð˜Ð¼Ð¸ Ð¼Ð¾Ð¶Ð½Ð¾ Ð¾Ð¿Ð»Ð°Ñ‚Ð¸Ñ‚ÑŒ Ð´Ð¾ 50%
                    Ð·Ð°ÐºÐ°Ð·Ð°.<br/> Ð¡Ð³Ð¾Ñ€Ð°ÑŽÑ‚ Ð¿Ð¾ Ð¸ÑÑ‚ÐµÑ‡ÐµÐ½Ð¸Ð¸ 30 Ð´Ð½ÐµÐ¹.</SText>
                {/* <SText style={{marginBottom: '8px'}} fontSize={14} fontWeight={700} color={'#FF5252'}>140 Ð±Ð¾Ð½ÑƒÑÐ¾Ð² ÑÐ³Ð¾Ñ€ÑÑ‚
                    Ñ‡ÐµÑ€ÐµÐ· 3 Ð´Ð½Ñ</SText>
                <SText style={{marginBottom: '35px'}} fontSize={14} fontWeight={400} color={'#2DCC70'}>205 Ð±Ð¾Ð½ÑƒÑÐ¾Ð²
                    ÑÐ³Ð¾Ñ€ÑÑ‚
                    Ñ‡ÐµÑ€ÐµÐ· 20 Ð´Ð½ÐµÐ¹</SText>*/}
                <div style={{marginBottom: '7px', display: 'flex', alignItems: 'center'}}>
                    <SText style={{marginRight: 15}} fontSize={14} fontWeight={550}>Ð’Ð°Ñˆ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´ Ð´Ð»Ñ Ð´Ñ€ÑƒÐ·ÐµÐ¹:</SText>
                    <div onClick={() => smv(true)} className={styles.promoModal}><img src={info}/></div>
                </div>
                <NewModal maxHeight={318} maxWidth={301} isVisible={mv} onClose={() => smv(false)}>
                    <PromoModal onClose={() => smv(false)}/>
                </NewModal>
                <div className={styles.promoForFriends}>
                    <input style={{marginRight: 15}} disabled={true} type="text"
                           value={user.data?.personal_promocode || ''}/>
                    <TouchableOpacity>
                        <div className={styles.copyCircle}>
                            <img src={copy}/>
                        </div>
                    </TouchableOpacity>
                </div>
            </div>
        </div>
    } else return <div className={styles.wrapper}>
        <div className={styles.bonusInfo}>
            {!user.data.isPolled && <div className={styles.anketa}>
                <div className={styles.anketaInfo}>
                    <img src={anketaRef}/>
                    <SText fontSize={14} fontWeight={900} color={'#2dcc70'}
                           lineHeight={14}>{'Ð—Ð°Ð¿Ð¾Ð»Ð½Ð¸Ñ‚Ðµ Ð°Ð½ÐºÐµÑ‚Ñƒ Ð¸ Ð¿Ð¾Ð»ÑƒÑ‡Ð¸Ñ‚Ðµ '} <SText fontSize={22} lineHeight={22}
                                                                                   color={'#2dcc70'}
                                                                                   fontWeight={900}>{'1200 Ð±Ð¾Ð½ÑƒÑÐ¾Ð²!'}</SText></SText>
                </div>
                <NavLink onClick={() => {
                    window.scrollTo(0, 0)
                    onClose()
                }} to={'/closer'}>
                    <div className={styles.anketaRef}>
                        <SText>{'ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ðº Ð°Ð½ÐºÐµÑ‚Ðµ >>'}</SText>
                    </div>
                </NavLink>
            </div>}

            <SText style={{marginBottom: '12px'}} fontSize={14} fontWeight={500}>ÐÐ° Ð»Ð¸Ñ‡Ð½Ð¾Ð¼ ÑÑ‡Ñ‘Ñ‚Ðµ:</SText>
            <SText style={{marginBottom: '25px'}} fontSize={28}
                   fontWeight={900}>{user.data?.scores || 0} {declOfNum(user.data?.scores || 0, wordForms)}</SText>
            <SText style={{marginBottom: '15px'}} fontSize={14} fontWeight={550} color={'#909090'}>1 Ð±Ð¾Ð½ÑƒÑ = 1
                Ñ€ÑƒÐ±Ð»ÑŒ<br/>Ð˜Ð¼Ð¸ Ð¼Ð¾Ð¶Ð½Ð¾ Ð¾Ð¿Ð»Ð°Ñ‚Ð¸Ñ‚ÑŒ Ð´Ð¾ 50%
                Ð·Ð°ÐºÐ°Ð·Ð°.<br/> Ð¡Ð³Ð¾Ñ€Ð°ÑŽÑ‚ Ð¿Ð¾ Ð¸ÑÑ‚ÐµÑ‡ÐµÐ½Ð¸Ð¸ 30 Ð´Ð½ÐµÐ¹.</SText>
            {/*<SText style={{marginBottom: '8px'}} fontSize={14} fontWeight={700} color={'#FF5252'}>140 Ð±Ð¾Ð½ÑƒÑÐ¾Ð² ÑÐ³Ð¾Ñ€ÑÑ‚
                Ñ‡ÐµÑ€ÐµÐ· 3 Ð´Ð½Ñ</SText>
            <SText style={{marginBottom: '35px'}} fontSize={14} fontWeight={400} color={'#2DCC70'}>205 Ð±Ð¾Ð½ÑƒÑÐ¾Ð² ÑÐ³Ð¾Ñ€ÑÑ‚
                Ñ‡ÐµÑ€ÐµÐ· 20 Ð´Ð½ÐµÐ¹</SText>*/}
            <SText style={{marginBottom: '15px'}} fontSize={14} fontWeight={550}>Ð’Ð°Ñˆ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´ Ð´Ð»Ñ Ð´Ñ€ÑƒÐ·ÐµÐ¹:</SText>
            <div className={styles.promoForFriends}>
                <input style={{marginRight: 15}} disabled={true} type="text"
                       value={user.data?.personal_promocode || ''}/>
                <TouchableOpacity>
                    <div onClick={() => navigator.clipboard.writeText(user.data?.personal_promocode || '')}
                         className={styles.copyCircle}>
                        <img src={copy}/>
                    </div>
                </TouchableOpacity>
            </div>
        </div>
        <div className={styles.promoInfo}>
            <SText style={{marginBottom: '10px'}} fontSize={20} fontWeight={700}>Ð ÑÑ‚Ð¾ Ð²Ð°Ñˆ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´!</SText>
            <SText fontSize={14} fontWeight={550}>ÐŸÐ¾Ð´Ð°Ñ€Ð¸Ñ‚Ðµ Ð´Ñ€ÑƒÐ³Ñƒ 300 Ð±Ð¾Ð½ÑƒÑÐ¾Ð² Ð½Ð° Ð¿ÐµÑ€Ð²Ñ‹Ð¹ Ð·Ð°ÐºÐ°Ð·<br/>Ð¸ Ð¿Ð¾Ð»ÑƒÑ‡Ð¸Ñ‚Ðµ 300 Ð±Ð¾Ð½ÑƒÑÐ¾Ð²
                Ð½Ð° ÑÐ²Ð¾Ð¹ ÑÑ‡Ñ‘Ñ‚.<br/>
                Ð”Ð»Ñ ÑÑ‚Ð¾Ð³Ð¾ Ð¿Ð¾Ð´ÐµÐ»Ð¸Ñ‚ÐµÑÑŒ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´Ð¾Ð¼ ÑÐ¾<br/>ÑÐ²Ð¾Ð¸Ð¼Ð¸ Ð´Ñ€ÑƒÐ·ÑŒÑÐ¼Ð¸ Ð¸ Ð¾Ð¶Ð¸Ð´Ð°Ð¹Ñ‚Ðµ, ÐºÐ¾Ð³Ð´Ð° Ð¾Ð½Ð¸<br/>Ð¾Ñ„Ð¾Ñ€Ð¼ÑÑ‚ Ð·Ð°ÐºÐ°Ð·!</SText>
        </div>
    </div>
}
