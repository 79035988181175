import './App.css'
import React, { useContext, useEffect, useState } from "react";
import { useWindowDimensions } from "./utils/hooks";
import AuthModal from "./screens/AuthModal/AuthModal";
import DataContext, { useAppData } from "./data/DataContext";
import Div100vh from "react-div-100vh";
import Footer from "./components/Footer";
import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import Catalogue from "./screens/Catalogue";
import ModalContext, { useModalContext } from "./data/ModalContext";
import ProductInfo from "./screens/ProductInfo";
import NewModal from "./components/NewModal";
import Ordering, { AddressSelector } from "./screens/Ordering/components/Ordering";
import PersonalAccount from "./screens/PersonalAccount";
import DeliveryAndPayment from "./components/DeliveryAndPayment";
import AboutSeason from "./components/AboutSeason";
import Exit from "./components/Exit";
import NotFound from "./components/NotFound";
import OrderBasket from "./components/OrderBasket";
import moment from "moment";
import 'moment/locale/ru'
import Found from "./screens/Search/Found";
import preloader from "./assets/images/newPreloader.svg"
import Region from "./components/Region";
import scrollTop from "./assets/images/scrollTop.svg"
import { useContextSearch, SearchContext } from "./data/SearchContext";
import OrderIsProcessed from "./components/OrderIsProcessed";
import Pay from "./screens/Pay";
import Pay2 from "./screens/Pay2";
import { getUserInfo } from "./api";
import BonusSystem from "./components/BonusSystem";
import { ModalTick } from "./components/InformAboutAdmission";
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import AgreementsAndRules from "./components/AgreementsAndRules";
import Offer from "./components/Offer"
import Caviar from "./components/Caviar";
import GiftVoucher from "./components/GiftVoucher";
import Unsubscribe from "./components/Unsubscribe";
import Anketa from "./components/Anketa/Anketa";
// TODO: Replace the following with your app's Firebase project configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyDl7gIoQhHOQ2dgt1GuOuu47oMoOMJ0kOA",
//     authDomain: "seasonmarket-ef7ea.firebaseapp.com",
//     projectId: "seasonmarket-ef7ea",
//     storageBucket: "seasonmarket-ef7ea.appspot.com",
//     messagingSenderId: "1014480778395",
//     appId: "1:1014480778395:web:4b0fdae025863137da023e",
//     measurementId: "G-9EHM7XRB0T"
// };

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

moment.locale('ru')

export const RegionContext = React.createContext({ region: 77, setRegion: () => { } })

const useRegionContext = () => {
    const [state, setState] = useState(null)

    return {
        region: state, setRegion: (region) => {
            setState(region)
            localStorage.setItem('region', JSON.stringify(region))
        }
    }
}
const AppWrapper = () => {


    const regionData = useRegionContext()

    async function detectRegion() {
        const savedRegion = localStorage.getItem('region')
        if (savedRegion) {
            regionData.setRegion(JSON.parse(savedRegion))
            return
        }

        const isAuthorized = localStorage.getItem('token')
        if (isAuthorized) {
            const response = await getUserInfo()
            if (!response) {
                regionData.setRegion(77) // todo ask
                return
            }
            const location = response.address?.[(response.address?.length || 1) - 1] || {}
            if (location) {
                regionData.setRegion(location.address?.includes('Ð¡Ð°Ð½ÐºÑ‚-ÐŸÐµÑ‚ÐµÑ€Ð±ÑƒÑ€Ð³') ? 78 : 77)
            } else {
                regionData.setRegion(77) //todo ask
            }
        } else {
            regionData.setRegion(77)
        }
    }

    useEffect(() => {
        detectRegion()
    }, [])

    if (!regionData.region) return <Preloader />

    return <RegionContext.Provider value={ regionData }>
        <DataLogicLayer>
            <App />
        </DataLogicLayer>
    </RegionContext.Provider>

}


export function Preloader() {
    return <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' } }><img
        style={ { width: 128, height: 128, transform: 'scale(1)', animation: 'pulse 2s infinite' } } src={ preloader } /></div>
}

document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
});

const ScrollTop = () => {

    const { windowDimensions: { width } } = useWindowDimensions()
    const [isScrolled, setScrolled] = useState(false)

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    function onScroll() {
        const offset = window.scrollY
        setScrolled(offset > 300)
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    return <div style={ { position: 'fixed', zIndex: 5, bottom: width > 600 ? 40 : 20, right: width > 600 ? 40 : 20 } }
        onClick={ scrollToTop }>
        { isScrolled ? <img src={ scrollTop } /> : null }
    </div>
}

function App() {
    const [isLoaded, setLoaded] = useState(false)
    const [canHidePreloader, setCanHidePreloader] = useState(false)
    const { fetchAll, catalogue } = useContext(DataContext)
    const { windowDimensions: { width } } = useWindowDimensions()
    const modalState = useContext(ModalContext)
    const { pathname } = useLocation()
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)

    async function init() {
        setLoaded(false)
        await fetchAll()
        setTimeout(() => setLoaded(true), 1000)
    }

    useEffect(() => {
        setTimeout(() => {
            setCanHidePreloader(true)
        }, 1200)
    }, [])

    useEffect(() => {
        init()
    }, [])

    if (!isLoaded || !canHidePreloader || !catalogue.data) return <Preloader />

    return <Div100vh style={ { display: 'flex', flexDirection: 'column' } }>
        <NewModal isVisible={ !!modalState?.['auth'] } onClose={ () => modalState.close('auth') }
            minWidth={ 600 } minHeight={ 503 }>
            <AuthModal onClose={ () => modalState.close('auth') } />
        </NewModal>
        <NewModal isVisible={ !!modalState?.['cart'] }
            minWidth={ 997 }
            onClose={ () => modalState.close('cart') }
        >
            <OrderBasket onClose={ () => modalState.close('cart') } />
        </NewModal>
        <NewModal isVisible={ !!modalState?.['user'] } onClose={ () => modalState.close('user') }
            minWidth={ 996 }>
            <PersonalAccount onClose={ () => modalState.close('user') } />
        </NewModal>
        <NewModal isVisible={ !!modalState?.['userHistory'] }
            onClose={ () => modalState.close('userHistory') } minWidth={ 996 }>
            <PersonalAccount history={ true } onClose={ () => modalState.close('userHistory') } />
        </NewModal>
        <NewModal isVisible={ !!modalState?.['exit'] } onClose={ () => modalState.close('exit') }
            borderRadius={ 20 } minWidth={ width >= 500 ? 375 : 301 } fullScreen={ false }>
            <Exit onClose={ () => modalState.close('user') } />
        </NewModal>
        <NewModal isVisible={ !!modalState?.['address'] } onClose={ () => modalState.close('address') }
            maxHeight={ 812 } maxWidth={ 600 }>
            <AddressSelector onClose={ () => modalState.close('address') } />
        </NewModal>
        <NewModal isVisible={ !!modalState?.['region'] } onClose={ () => modalState.close('region') }
            borderRadius={ 20 } minWidth={ width >= 500 ? 375 : 303 } fullScreen={ false }>
            <Region onClose={ () => modalState.close('region') } />
        </NewModal>
        <NewModal isVisible={ !!modalState?.['admission'] } onClose={ () => modalState.close('admission') } minWidth={ 281 }>
            <ModalTick onClose={ () => modalState.close('admission') } />
        </NewModal>
        <ScrollTop />
        <div style={ { flex: 1 } }>
            <Switch>
                <Route exact path={ '/' } render={ () => <Redirect to={ '/catalog' } /> } />
                <Route path={ '/en' } render={ () => <Redirect to={ '/' } /> } />
                <Route path={ '/catalog' } component={ Catalogue } />
                <Route path={ '/product/:url' } component={ ProductInfo } />
                <Route path={ '/submit_order' } component={ Ordering } />
                <Route path={ '/delivery-and-payment' } component={ DeliveryAndPayment } />
                <Route path={ '/about-season-market' } component={ AboutSeason } />
                <Route path={ '/search' } component={ Found } />
                <Route path={ '/order/:id' } component={ OrderIsProcessed } />
                <Route path={ '/pay/:orderId' } component={ Pay } />
                <Route path={ '/pay2/:orderId' } component={ Pay2 } />
                <Route path={ '/bonus-system' } component={ BonusSystem } />
                <Route path={ '/the-agreements-and-rules' } component={ AgreementsAndRules } />
                <Route path={ '/offer' } component={ Offer } />
                <Route path='/unsubscribe' component={ Unsubscribe } />
                <Route path={ '/caviar' } component={ Caviar } />
                <Route path={ '/gift-voucher' } component={ GiftVoucher } />
                <Route path={ '/closer' } component={ Anketa } />
                <Route path={ '*' } component={ NotFound } />
            </Switch>
        </div>
        { !searchParams.has('hideNavs') && <Footer /> }
    </Div100vh>
}

const DataLogicLayer = (props) => {
    const data = useAppData(false)
    const searchData = useContextSearch()
    const modalState = useModalContext()

    return <DataContext.Provider value={ data }>
        <ModalContext.Provider value={ modalState }>
            <SearchContext.Provider value={ searchData }>
                { props.children }
            </SearchContext.Provider>
        </ModalContext.Provider>
    </DataContext.Provider>
}

export default AppWrapper;
