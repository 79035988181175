import React, {useContext, useEffect, useState} from 'react';
import styles from './Anketa.module.css'
import cn from "classnames";
import fullTick from '../../assets/images/anketa/tickFull.svg'
import male from '../../assets/images/anketa/anketa_male.png'
import female from '../../assets/images/anketa/anketa_female.png'
import checkbox from '../../assets/images/anketa/checkbox.svg'
import checkboxFull from '../../assets/images/anketa/checkboxFull.svg'
import pet1 from '../../assets/images/anketa/anketa_animal_1.png'
import pet2 from '../../assets/images/anketa/anketa_animal_2.png'
import pet3 from '../../assets/images/anketa/anketa_animal_3.png'
import pet4 from '../../assets/images/anketa/anketa_animal_4.png'
import pet5 from '../../assets/images/anketa/anketa_animal_5.png'
import children1 from '../../assets/images/anketa/firstChild.svg'
import children2 from '../../assets/images/anketa/secondChild.svg'
import children3 from '../../assets/images/anketa/thirdChild.svg'
import home from '../../assets/images/anketa/home.svg'
import apartment from '../../assets/images/anketa/apartment.svg'
import office from '../../assets/images/anketa/office.svg'
import blondFemale from '../../assets/images/anketa/blondFemale.svg'
import thunderphone from '../../assets/images/anketa/thunderphone.svg'
import search from '../../assets/images/anketa/search.svg'
import bannerPhone from '../../assets/images/anketa/bannerPhone.svg'
import blondFrien from '../../assets/images/anketa/blondFriend.svg'
import friendShake from '../../assets/images/anketa/friendShake.svg'
import car from '../../assets/images/anketa/car.svg'
import road from '../../assets/images/anketa/road.svg'
import bike from '../../assets/images/anketa/bike.svg'
import tv from '../../assets/images/anketa/tv.svg'
import radio from '../../assets/images/anketa/radio.svg'
import eye from '../../assets/images/anketa/eye.svg'
import disabled from '../../assets/images/anketa/disabled.svg'
import plusCircle from '../../assets/images/anketa/PlusCircle.svg'
/*import goBack from '../../assets/images/cart/goBack.svg'*/
import {useWindowDimensions} from "../../utils/hooks";
import DataContext from "../../data/DataContext";
import SText from "../SText";
import axios from "axios";
import {BASE_URL} from "../../config";
import Header from "../Header";
import PhoneInput from "../../screens/AuthModal/components/PhoneInput";
import {searchAll} from "../../api";
import useSearch from "../../data/SearchContext";
import {useLocation} from "react-router-dom";

const stores = [
    {title: 'ÐŸÐµÑ€ÐµÐºÑ€ÐµÑÑ‚Ð¾Ðº', fieldName: 'perekrestok', value: [], disabledIndex: false},
    {title: 'Ð’ÐºÑƒÑÐ’Ð¸Ð»Ð»', fieldName: 'vkusvill', value: [], disabledIndex: false},
    {title: 'ÐÐ·Ð±ÑƒÐºÐ° Ð’ÐºÑƒÑÐ°', fieldName: 'azbukavkusa', value: [], disabledIndex: false},
    {title: 'ÐŸÑÑ‚Ñ‘Ñ€Ð¾Ñ‡ÐºÐ°', fieldName: 'pyaterochka', value: [], disabledIndex: false},
    {title: 'ÐœÐ°Ð³Ð½Ð¸Ñ‚', fieldName: 'marnit', value: [], disabledIndex: false},
    {title: 'Ð£Ñ‚ÐºÐ¾Ð½Ð¾Ñ', fieldName: 'utkonos', value: [], disabledIndex: 1},
    {title: 'OZON Express', fieldName: 'ozonexpress', value: [], disabledIndex: 1},
    {title: 'Ð¡Ð°Ð¼Ð¾ÐºÐ°Ñ‚', fieldName: 'samokat', value: [], disabledIndex: 1},
    {title: 'ÐÑˆÐ°Ð½', fieldName: 'ashan', value: [], disabledIndex: false},
    {title: 'Ð¯Ð½Ð´ÐµÐºÑ Ð›Ð°Ð²ÐºÐ°', fieldName: 'yandexlavka', value: [], disabledIndex: 1},
    {title: 'Ð¡Ð±ÐµÑ€Ð¼Ð°Ñ€ÐºÐµÑ‚', fieldName: 'sbermarket', value: [], disabledIndex: 1},
    {title: 'Ð›ÐµÐ½Ñ‚Ð°', fieldName: 'lenta', value: [], disabledIndex: false},
    {title: 'Ð“Ð¸Ð¿ÐµÑ€ Ð“Ð»Ð¾Ð±ÑƒÑ', fieldName: 'giperglobus', value: [], disabledIndex: false},
    {title: 'Euro Spar', fieldName: 'eurospar', value: [], disabledIndex: false},
    {title: 'Ð¢Ð¾Ð»ÑŒÐºÐ¾ Season Market', fieldName: 'seasonmarket', value: [], disabledIndex: 1},
    {title: 'Ð´Ñ€ÑƒÐ³Ð¾Ðµ', fieldName: 'else', value: [], disabledIndex: false},
]

const petsArray = [
    {value: '1', img: pet1, name: 'Ð¿Ñ‚Ð¸Ñ‡ÐºÐ°'},
    {value: '2', img: pet2, name: 'ÐºÐ¾ÑˆÐºÐ°'},
    {value: '3', img: pet3, name: 'ÑÐ¾Ð±Ð°Ñ‡ÐºÐ°'},
    {value: '4', img: pet4, name: 'Ñ€Ñ‹Ð±ÐºÐ¸'},
    {value: '5', img: pet5, name: 'Ð´Ñ€ÑƒÐ³Ð¾Ðµ'},
    {value: '6', img: null, name: 'Ð½ÐµÑ‚'},
]

const childrenArray = [
    {value: '0', img: null, name: '0'},
    {value: '1', img: [children1], name: '1'},
    {value: '2', img: [children1, children2], name: '2'},
    {value: '3', img: [children1, children2, children3], name: '3'},
    {value: '4', img: [children1, children2, children3, children1], name: '4+'},
]

const pointArray = [
    {value: '1', img: home, name: 'Ð§Ð°ÑÑ‚Ð½Ñ‹Ð¹ Ð´Ð¾Ð¼'},
    {value: '2', img: apartment, name: 'ÐšÐ²Ð°Ñ€Ñ‚Ð¸Ñ€Ð°'},
    {value: '3', img: office, name: 'ÐžÑ„Ð¸Ñ'},
]

const howKnowsArray = [
    {value: '1', img: [blondFemale], name: 'Ð‘Ð»Ð¾Ð³ÐµÑ€Ñ‹ Ð˜Ð½ÑÑ‚Ð°Ð³Ñ€Ð°Ð¼'},
    {value: '2', img: [female], name: 'ÐŸÑ€Ð¾Ñ‡Ð¸Ðµ Ð±Ð»Ð¾Ð³ÐµÑ€Ñ‹'},
    {value: '3', img: [thunderphone], name: 'ÐÐ°Ñˆ Ð˜Ð½ÑÑ‚Ð°Ð³Ñ€Ð°Ð¼'},
    {value: '4', img: [search], name: 'Ð’ ÑÐ½Ð´ÐµÐºÑ, Ð³ÑƒÐ³Ð» Ð¿Ð¾Ð¸ÑÐºÐ¾Ð²Ð¸ÐºÐµ'},
    {value: '5', img: [bannerPhone], name: 'Ð‘Ð°Ð½Ð½ÐµÑ€Ð½Ð°Ñ Ð² Ð˜Ð½Ñ‚ÐµÑ€Ð½ÐµÑ‚Ðµ'},
    {value: '6', img: [blondFrien, friendShake], name: 'ÐŸÐ¾ÑÐ¾Ð²ÐµÑ‚Ð¾Ð²Ð°Ð» Ð´Ñ€ÑƒÐ³'},
    {value: '7', img: [car, road, bike], name: 'Ð£Ð²Ð¸Ð´ÐµÐ»Ð¸ Ð½Ð° ÑƒÐ»Ð¸Ñ†Ðµ'},
    {value: '8', img: [tv, radio], name: 'Ð¢Ð’, Ñ€Ð°Ð´Ð¸Ð¾'},
    {value: '9', img: [eye], name: 'Ð”Ñ€ÑƒÐ³Ð¾Ðµ'},
]

const monthArray = [
    {value: 1, name: 'Ð¯Ð½Ð²Ð°Ñ€ÑŒ'},
    {value: 2, name: 'Ð¤ÐµÐ²Ñ€Ð°Ð»ÑŒ'},
    {value: 3, name: 'ÐœÐ°Ñ€Ñ‚'},
    {value: 4, name: 'ÐÐ¿Ñ€ÐµÐ»ÑŒ'},
    {value: 5, name: 'ÐœÐ°Ð¹'},
    {value: 6, name: 'Ð˜ÑŽÐ½ÑŒ'},
    {value: 7, name: 'Ð˜ÑŽÐ»ÑŒ'},
    {value: 8, name: 'ÐÐ²Ð³ÑƒÑÑ‚'},
    {value: 9, name: 'Ð¡ÐµÐ½Ñ‚ÑÐ±Ñ€ÑŒ'},
    {value: 10, name: 'ÐžÐºÑ‚ÑÐ±Ñ€ÑŒ'},
    {value: 11, name: 'ÐÐ¾ÑÐ±Ñ€ÑŒ'},
    {value: 12, name: 'Ð”ÐµÐºÐ°Ð±Ñ€ÑŒ'},
]

const foodArray = [
    {value: 1, name: 'Ð’ÐµÐ³ÐµÑ‚Ð°Ñ€Ð¸Ð°Ð½ÐµÑ†'},
    {value: 2, name: 'Ð’ÐµÐ³Ð°Ð½'},
    {value: 3, name: 'ÐšÐ¾ÑˆÐµÑ€'},
    {value: 4, name: 'Ð¥Ð°Ð»ÑÐ»ÑŒ'},
    {value: 5, name: 'Ð‘ÐµÐ· Ð³Ð»ÑŽÑ‚ÐµÐ½Ð°'},
    {value: 6, name: 'Ð‘ÐµÐ· ÑÐ°Ñ…Ð°Ñ€Ð°'},
    {value: 7, name: 'Ð‘ÐµÐ· Ð»Ð°ÐºÑ‚Ð¾Ð·Ñ‹'},
    {value: 8, name: 'ÐÐµÑ‚ Ð¿Ñ€ÐµÐ´Ð¿Ð¾Ñ‡Ñ‚ÐµÐ½Ð¸Ð¹'},
]

const weekendArray = [
    {value: 1, name: 'ÐŸÐ°Ñ€Ðº'},
    {value: 2, name: 'ÐšÐ¸Ð½Ð¾'},
    {value: 3, name: 'Ð¡Ð¿Ð¾Ñ€Ñ‚Ð·Ð°Ð»'},
    {value: 4, name: 'Ð¨Ð¾Ð¿Ð¸Ð½Ð³'},
    {value: 5, name: 'Ð›ÐµÐ¶Ð°Ñ‚ÑŒ Ð½Ð° Ð´Ð¸Ð²Ð°Ð½Ðµ'},
    {value: 6, name: 'Ð’ÑÐµÐ³Ð´Ð° Ð²Ñ‹Ñ…Ð¾Ð´Ð½Ð¾Ð¹'},
    {value: 7, name: 'Ð”Ñ€ÑƒÐ³Ð¾Ðµ'},
]

const socialsArray = [
    {value: 1, name: 'Instagram'},
    {value: 2, name: 'Youtube'},
    {value: 3, name: 'VK'},
    {value: 4, name: 'Tik-Tok'},
    {value: 5, name: 'Ð¯Ð½Ð´ÐµÐºÑ Ð”Ð·ÐµÐ½'},
]

const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmail = (email) => {
    return emailRegex.test(email);
}

export const api = axios.create({baseURL: BASE_URL})

const Anketa = () => {
    const {user} = useContext(DataContext)
    const [name, setName] = useState(user?.data?.full_name || '')
    const [phone, setPhone] = useState(user?.data?.phone || '')
    const [mail, setMail] = useState(user?.data?.email || '')
    const [birthday, setBirthday] = useState('')
    const [validBirthday, setValidBirthday] = useState('')
    const [sex, setSex] = useState('')
    const [region, setRegion] = useState('0')
    const [regionElse, setRegionElse] = useState('')
    const [pet, setPet] = useState([])
    const [children, setChildren] = useState('')
    const [point, setPoint] = useState('')
    const [how, setHow] = useState([])
    const [otherStores, setOtherStores] = useState(stores)
    const [otherStoresElse, setOtherStoresElse] = useState('')
    const [products, setProducts] = useState({product1: '', product2: '', product3: ''})
    const [months, setMonths] = useState([])
    const [countries, setCountries] = useState({country1: '', country2: '', country3: ''})
    const [food, setFood] = useState([])
    const [weekends, setWeekends] = useState([])
    const [organic, setOrganic] = useState('')
    const [socials, setSocials] = useState([])
    const [inst, setInst] = useState('')
    const [popularStores, setPopularStores] = useState(stores.map((item) => ({
        ...item,
        value: false,
        disabledIndex: false,
    })))
    const [points, setPoints] = useState(0)
    const [questions, setQuestions] = useState(0)
    const [success, setSuccess] = useState(false)
    const [agree, setAgree] = useState(false)
    const {windowDimensions: {width}} = useWindowDimensions()
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)

    const countPoints = () => {
        let points = 0
        let questions = 0
        if (name.trim().length) {
            points += 20
            questions += 1
        }
        if (phone.length === 18) {
            questions += 1
            points += 100
        }
        if (validateEmail(mail)) {
            questions += 1
            points += 100
        }
        if (validBirthday.length) {
            questions += 1
            points += 100
        }
        if (sex.length) {
            questions += 1
            points += 20
        }
        if (region > 0) {
            questions += 1
            points += 40
        }
        if (pet.length) {
            questions += 1
            points += 20
        }
        if (children.length) {
            questions += 1
            points += 20
        }
        if (point.length) {
            questions += 1
            points += 20
        }
        if (how.length) {
            questions += 1
            points += 60
        }
        if (otherStores.filter(item => item.value.length !== 0).length !== 0) {
            questions += 1
            points += 200
        }
        if (Object.values(products).filter(f => f.trim() !== '').length > 0) {
            questions += 1
            points += 100
        }
        if (months.length) {
            questions += 1
            points += 60
        }
        if (Object.values(countries).filter(f => f.trim() !== '').length > 0) {
            questions += 1
            points += 20
        }
        if (food.length) {
            questions += 1
            points += 50
        }
        if (weekends.length) {
            questions += 1
            points += 20
        }
        if (socials.length) {
            questions += 1
            points += 50
        }
        if (inst.length) {
            questions += 1
            points += 100
        }
        if (popularStores.filter(item => item.value !== false).length > 0) {
            questions += 1
            points += 100
        }

        setPoints(points)
        setQuestions(questions)
    }

    const submit = async () => {
        let TOKEN = "UNDEFINED";
        if (user.data) {
            TOKEN = user?.data?.apikey;
        }
        if (phone.length === 18) {
            let SEND_DATA = {
                phone,
                name,
                email: mail,
                whatsapp: '',
                product_1: products.product1,
                product_2: products.product2,
                product_3: products.product3,
                region,
                region_else: regionElse,
                birthday: validBirthday,
                sex,
                organic,
                point,
                family_status: '',
                children,
                animals: '',
                animal_types: pet,
                where: '',
                how,
                country_1: countries.country1,
                country_2: countries.country2,
                country_3: countries.country3,
                days: '',
                food_t: food,
                other_stores: otherStores,
                other_stores_else: otherStoresElse,
                popular_stores: popularStores,
                popular_stores_else: '',
                daysInYear: [],
                months,
                food_c: [],
                group: [],
                weekends,
                rest: [],
                service: [],
                big_group: '',
                socials,
                instagram: inst,
                earned: points,
                questions,
                apikey: TOKEN,
            };

            let data = await api.post('question/create ', SEND_DATA)
            if (data !== false) {

                if (data.data.result === 'ok')
                    setSuccess(true)
            } else {
            }
        }
    }

    useEffect(() => {
        document.body.style.backgroundColor = '#F7F7F7'
        return () => {
            document.body.style.backgroundColor = '#fff'
        }
    }, [])

    useEffect(() => {
        countPoints()
    }, [name, phone, mail, validBirthday, sex, region, pet,
        children, point, how, otherStores, products, months,
        countries, food, weekends, organic, socials, inst, popularStores])

    return (
        <div>
            {!searchParams.has('hideNavs') && <div className={styles.header}>
                <Header/>
            </div>}
            <div className={styles.wrapper}>

                <div className={styles.title}>{'ÐÐ½ÐºÐµÑ‚Ð°-Ð¾Ð¿Ñ€Ð¾ÑÐ½Ð¸Ðº'}</div>
                <div className={styles.greenTitle}>{'Ð—Ð°Ð¿Ð¾Ð»Ð½Ð¸Ñ‚Ðµ Ð°Ð½ÐºÐµÑ‚Ñƒ Ð¸ Ð¿Ð¾Ð»ÑƒÑ‡Ð¸Ñ‚Ðµ 1200 Ð±Ð¾Ð½ÑƒÑÐ½Ñ‹Ñ… Ð±Ð°Ð»Ð»Ð¾Ð²!'}</div>

                {(!user?.data?.isPolled || !user.data) ?
                    success ?
                        <div><SText size={18} weight={700} lineHeight={18}>{'ÐÐ½ÐºÐµÑ‚Ð° ÑƒÑÐ¿ÐµÑˆÐ½Ð¾ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ð°!'}</SText></div> :
                        <>
                            <div
                                /*style={{top: true ? (width > 1023 ? 100 : 56) : (width > 1023 ? 100 : 0)}}*/
                                className={styles.earnedWrapper}>
                                <SText size={18} weight={500} color={'#818181'}>{`${questions} Ð¸Ð· 20`}</SText>
                                <div style={{display: 'flex', alignItems: "center", gap: 10}}>
                                    <SText size={12} weight={500} lineHeight={12}>{'ÐÐ°Ð±Ñ€Ð°Ð½Ð¾ Ð±Ð°Ð»Ð»Ð¾Ð² '}</SText>
                                    <div className={styles.pointsEarned}><SText size={18} weight={500}
                                                                                lineHeight={18}>{points}</SText>
                                    </div>
                                </div>
                            </div>

                            <Name name={name} setName={setName}/>

                            <Phone phone={phone} setPhone={setPhone}/>

                            <Mail mail={mail} setMail={setMail}/>

                            <Birthday birthday={birthday} setBirthday={setBirthday}
                                      setValidBirthday={setValidBirthday}/>

                            <Sex sex={sex} setSex={setSex}/>

                            <Region region={region} regionElse={regionElse} setRegion={setRegion}
                                    setRegionElse={setRegionElse}/>

                            <Pets pet={pet} setPet={setPet}/>

                            <Children children={children} setChildren={setChildren}/>

                            <Point point={point} setPoint={setPoint}/>

                            <HowKnows how={how} setHow={setHow}/>

                            <OtherStores otherStores={otherStores} setOtherStores={setOtherStores}
                                         otherStoresElse={otherStoresElse}
                                         setOtherStoresElse={setOtherStoresElse}/>

                            <Products products={products} setProducts={setProducts}/>

                            <Month month={months} setMonth={setMonths}/>

                            <Countries countries={countries} setCountries={setCountries}/>

                            <Food food={food} setFood={setFood}/>

                            <Weekends weekends={weekends} setWeekends={setWeekends}/>


                            <Socials socials={socials} setSocials={setSocials}/>

                            <Inst inst={inst} setInst={setInst}/>

                            <PopularStores otherStores={otherStores} popularStores={popularStores}
                                           setPopularStores={setPopularStores}/>

                            <div onClick={() => setAgree(prev => !prev)} className={styles.agree}>
                                <img src={agree ? checkboxFull : checkbox}/>
                                <SText size={14} weight={500}
                                       lineHeight={14}>{'Ð¡Ð¾Ð³Ñ€Ð°ÑÐµÐ½ Ð½Ð° Ð¾Ð±Ñ€Ð°Ð±Ð¾Ñ‚ÐºÑƒ Ð¿ÐµÑ€ÑÐ¾Ð½Ð°Ð»ÑŒÐ½Ñ‹Ñ… Ð´Ð°Ð½Ð½Ñ‹Ñ…'}</SText>
                            </div>

                            <div onClick={submit} className={styles.submitBtn}><SText size={16} lineHeight={16}
                                                                                      weight={500}
                                                                                      color={'#fff'}>{'ÐžÑ‚Ð¿Ñ€Ð°Ð²Ð¸Ñ‚ÑŒ Ð¾Ñ‚Ð²ÐµÑ‚Ñ‹'}</SText>
                            </div>
                        </> :
                    <div><SText size={18} weight={700} lineHeight={18}>{'Ð’Ñ‹ ÑƒÐ¶Ðµ Ð·Ð°Ð¿Ð¾Ð»Ð½ÑÐ»Ð¸ Ð°Ð½ÐºÐµÑ‚Ñƒ Ñ€Ð°Ð½ÐµÐµ!'}</SText></div>}
            </div>
        </div>
    );
};

const Name = ({name, setName}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>1. <span>{' Ð’Ð°ÑˆÐµ Ð¸Ð¼Ñ'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.nameInput}>
                <input placeholder={'ÐšÐ°Ðº Ð²Ð°Ñ Ð·Ð¾Ð²ÑƒÑ‚?'} value={name} onChange={e => setName(e.target.value)} type="text"/>
            </div>
            <div className={styles.qPoints}>
                <div className={name.length <= 1 && styles.emptyCircle}>{name.length > 1 &&
                    <img src={fullTick}/>}</div>
                <div className={name.length <= 1 ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}

const Phone = ({phone, setPhone}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>2. <span>{' Ð’Ð°Ñˆ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.nameInput}>
                <PhoneInput onValueChange={setPhone} value={phone}/>
            </div>
            <div className={styles.qPoints}>
                <div className={phone.length < 18 && styles.emptyCircle}>{phone.length === 18 &&
                    <img src={fullTick}/>}</div>
                <div className={phone.length < 18 ? styles.amountGray : styles.amountGreen}>{'+100'}</div>
            </div>
        </div>
    </div>
}

const Mail = ({mail, setMail}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>3. <span>{' ÐÐ´Ñ€ÐµÑ Ð²Ð°ÑˆÐµÐ¹ ÑÐ»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ð¾Ð¹ Ð¿Ð¾Ñ‡Ñ‚Ñ‹'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.nameInput}>
                <input placeholder={'Ð­Ð»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ð°Ñ Ð¿Ð¾Ñ‡Ñ‚Ð°'} value={mail} onChange={e => setMail(e.target.value)}
                       type="text"/>
            </div>
            <div className={styles.qPoints}>
                <div className={!validateEmail(mail) && styles.emptyCircle}>{validateEmail(mail) &&
                    <img src={fullTick}/>}</div>
                <div className={!validateEmail(mail) ? styles.amountGray : styles.amountGreen}>{'+100'}</div>
            </div>
        </div>
    </div>
}

const Birthday = ({birthday, setBirthday, setValidBirthday}) => {
    const dayValue = (day) => {
        const d = Number(day.target.value)
        if ((d < 1 || d > 31 || d % 1 !== 0) && day.target.value !== '')
            return

        setBirthday({...birthday, day: day.target.value})
    }

    const monthValue = (month) => {
        const m = Number(month.target.value)
        if ((m < 1 || m > 12 || m % 1 !== 0) && month.target.value !== '')
            return

        setBirthday({...birthday, month: month.target.value})
    }

    const yearValue = (year) => {
        const y = Number(year.target.value)
        if ((y < 1 || y > 2021 || y % 1 !== 0) && year.target.value !== '')
            return

        setBirthday({...birthday, year: year.target.value})
    }

    const validate = () => {
        if (!!birthday.day && !!birthday.month && !!birthday.year && birthday.year.length === 4) {
            const date = `${birthday.day}-${birthday.month}-${birthday.year}`
            setValidBirthday(date)
            return true
        }
        setValidBirthday('')
        return false
    }

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>4. <span>{' Ð”ÐµÐ½ÑŒ Ñ€Ð¾Ð¶Ð´ÐµÐ½Ð¸Ñ'}</span></div>
        <div className={styles.qFlex}>
            <div className={cn(styles.nameInput, styles.birthdayInput)}>
                <input style={{marginRight: '9px', padding: '0 14px', width: '75px', height: '55px'}}
                       placeholder={'Ð”Ð”'} value={birthday?.day || ''} onChange={e => dayValue(e)} type="number"/>
                <input style={{marginRight: '9px', padding: '0 14px', width: '75px', height: '55px'}}
                       placeholder={'MM'} value={birthday?.month || ''} onChange={e => monthValue(e)} type="number"/>
                <input style={{padding: '0 14px', width: '100%', maxWidth: '112px', height: '55px'}}
                       placeholder={'Ð“Ð“Ð“Ð“'} value={birthday?.year || ''} onChange={e => yearValue(e)} type="number"/>
            </div>
            <div className={styles.qPoints}>
                <div className={!validate() && styles.emptyCircle}>{validate() &&
                    <img src={fullTick}/>}</div>
                <div className={!validate() ? styles.amountGray : styles.amountGreen}>{'+100'}</div>
            </div>
        </div>
    </div>
}

const Sex = ({sex, setSex}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>5. <span>{' Ð’Ð°Ñˆ Ð¿Ð¾Ð»'}</span></div>
        <div style={{justifyContent: 'space-between'}} className={styles.qFlex}>
            <div className={styles.sexInput}>
                <div onClick={() => setSex('MALE')}>
                    <div className={sex === 'MALE' ? styles.fullRadioCircle : styles.emptyRadioCircle}/>
                    <img src={male}/>
                </div>
                <div onClick={() => setSex('FEMALE')}>
                    <div className={sex === 'FEMALE' ? styles.fullRadioCircle : styles.emptyRadioCircle}/>
                    <img src={female}/>
                </div>
            </div>
            <div className={styles.qPoints}>
                <div className={sex.length === 0 && styles.emptyCircle}>{sex.length !== 0 &&
                    <img src={fullTick}/>}</div>
                <div className={sex.length === 0 ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}

const Region = ({region, regionElse, setRegion, setRegionElse}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>6. <span>{' Ð’Ð°Ñˆ Ñ€ÐµÐ³Ð¸Ð¾Ð½'}</span></div>
        <div className={styles.qFlex}>
            <div style={{height: 'initial'}} className={styles.nameInput}>
                <select style={{marginBottom: 15}} onChange={(e) => setRegion(e.target.value)}>
                    <option value="0">Ð’Ñ‹Ð±ÐµÑ€Ð¸Ñ‚Ðµ ÑÐ²Ð¾Ð¹ Ñ€ÐµÐ³Ð¸Ð¾Ð½</option>
                    <option value="77">ÐœÐ¾ÑÐºÐ²Ð° Ð¸ ÐœÐ¾ÑÐºÐ¾Ð²ÑÐºÐ°Ñ Ð¾Ð±Ð»Ð°ÑÑ‚ÑŒ</option>
                    <option value="78">Ð¡Ð°Ð½ÐºÑ‚-ÐŸÐµÑ‚ÐµÑ€Ð±ÑƒÑ€Ð³ Ð¸ Ð›ÐµÐ½Ð¸Ð½Ð³Ñ€Ð°Ð´ÑÐºÐ°Ñ Ð¾Ð±Ð»Ð°ÑÑ‚ÑŒ</option>
{/*                    <option value="16">ÐšÐ°Ð·Ð°Ð½ÑŒ Ð¸ Ð ÐµÑÐ¿ÑƒÐ±Ð»Ð¸ÐºÐ° Ð¢Ð°Ñ‚Ð°Ñ€ÑÑ‚Ð°Ð½</option>
                    <option value="52">ÐÐ¸Ð¶Ð½Ð¸Ð¹ ÐÐ¾Ð²Ð³Ð¾Ñ€Ð¾Ð´ Ð¸ ÐÐ¸Ð¶ÐµÐ³Ð¾Ñ€Ð¾Ð´ÑÐºÐ°Ñ Ð¾Ð±Ð»Ð°ÑÑ‚ÑŒ</option>
                    <option value="61">Ð Ð¾ÑÑ‚Ð¾Ð²-Ð½Ð°-Ð”Ð¾Ð½Ñƒ Ð¸ Ð Ð¾ÑÑ‚Ð¾Ð²ÑÐºÐ°Ñ Ð¾Ð±Ð»Ð°ÑÑ‚ÑŒ</option>
                    <option value="36">Ð’Ð¾Ñ€Ð¾Ð½ÐµÐ¶ Ð¸ Ð’Ð¾Ñ€Ð¾Ð½ÐµÐ¶ÑÐºÐ°Ñ Ð¾Ð±Ð»Ð°ÑÑ‚ÑŒ</option>
                    <option value="34">Ð’Ð¾Ð»Ð³Ð¾Ð³Ñ€Ð°Ð´ Ð¸ Ð’Ð¾Ð»Ð³Ð¾Ð³Ñ€Ð°Ð´ÑÐºÐ°Ñ Ð¾Ð±Ð»Ð°ÑÑ‚ÑŒ</option>
                    <option value="1">Ð”Ñ€ÑƒÐ³Ð¾Ð¹</option>*/}
                </select>
                {region === '1' && <input placeholder={'ÐÐ°Ð·Ð²Ð°Ð½Ð¸Ðµ Ð½Ð°ÑÐµÐ»Ñ‘Ð½Ð½Ð¾Ð³Ð¾ Ð¿ÑƒÐ½ÐºÑ‚Ð°'} value={regionElse}
                                          onChange={e => setRegionElse(e.target.value)}
                                          type="select"/>}
            </div>
            <div className={styles.qPoints}>
                <div className={region === '0' && styles.emptyCircle}>{region > 0 &&
                    <img src={fullTick}/>}</div>
                <div className={region <= 0 ? styles.amountGray : styles.amountGreen}>{'+40'}</div>
            </div>
        </div>
    </div>
}

const Pets = ({pet, setPet}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>7. <span>{' Ð”Ð¾Ð¼Ð°ÑˆÐ½Ð¸Ðµ Ð¶Ð¸Ð²Ð¾Ñ‚Ð½Ñ‹Ðµ'}</span></div>
        <div className={styles.qFlex}>

            <div className={styles.pets}>
                {
                    petsArray.map(({value, img, name}) => <div onClick={() => {
                        if (pet.includes(value))
                            setPet(pet.filter((f) => f !== value))
                        else setPet([...pet, value])
                    }} className={styles.petRow}>
                        <img src={pet.includes(value) ? checkboxFull : checkbox}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                        {img && <img src={img}/>}
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={pet.length <= 0 && styles.emptyCircle}>{pet.length > 0 &&
                    <img src={fullTick}/>}</div>
                <div className={pet.length <= 0 ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}

const Children = ({children, setChildren}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>8. <span>{' Ð¡ÐºÐ¾Ð»ÑŒÐºÐ¾ Ð´ÐµÑ‚ÐµÐ¹ Ð² ÑÐµÐ¼ÑŒÐµ?'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.children}>
                {
                    childrenArray.map(({value, img, name}) => <div onClick={() => {
                        setChildren(value)
                    }} className={styles.childrenRow}>
                        <div className={children === value ? styles.fullRadioCircle : styles.emptyRadioCircle}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                        {
                            img && img.map(img => <img src={img}/>)
                        }
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={!children.length && styles.emptyCircle}>{children.length ?
                    <img src={fullTick}/> : null}</div>
                <div className={!children.length ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}

const Point = ({point, setPoint}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>9. <span>{' ÐšÑƒÐ´Ð° Ñ‡Ð°Ñ‰Ðµ Ð²ÑÐµÐ³Ð¾ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°ÐµÑ‚Ðµ?'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.children}>
                {
                    pointArray.map(({value, img, name}) => <div onClick={() => {
                        setPoint(value)
                    }} className={styles.childrenRow}>
                        <div className={point === value ? styles.fullRadioCircle : styles.emptyRadioCircle}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                        <img src={img}/>
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={!point.length && styles.emptyCircle}>{point.length ?
                    <img src={fullTick}/> : null}</div>
                <div className={!point.length ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}

const HowKnows = ({how, setHow}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>10. <span>{' ÐšÐ°Ðº Ð²Ñ‹ ÑƒÐ·Ð½Ð°Ð»Ð¸ Ð¾ Ð½Ð°Ñ?'}</span></div>
        <div className={styles.qFlex}>

            <div className={styles.how}>
                {
                    howKnowsArray.map(({value, img, name}) => <div onClick={() => {
                        if (how.includes(value))
                            setHow(how.filter((f) => f !== value))
                        else setHow([...how, value])
                    }} className={styles.petRow}>
                        <img src={how.includes(value) ? checkboxFull : checkbox}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                        {img.map(img => <img src={img}/>)}
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={how.length <= 0 && styles.emptyCircle}>{how.length > 0 &&
                    <img src={fullTick}/>}</div>
                <div className={how.length <= 0 ? styles.amountGray : styles.amountGreen}>{'+60'}</div>
            </div>
        </div>
    </div>
}

const OtherStores = ({otherStores, setOtherStores, otherStoresElse, setOtherStoresElse}) => {
    const earned = otherStores.filter(item => item.value.length !== 0).length !== 0
    return <div style={{marginBottom: 30}} className={styles.qWrapper}>
        <div className={styles.qTitleSpecial}>
            <div>
                <div style={{display: 'flex', gap: 5, marginBottom: 15}}>
                    <SText size={14} weight={500} lineHeight={14} color={'#818181'}>{'11.'}</SText>
                    <SText size={14} weight={700} lineHeight={14}>{'Ð“Ð´Ðµ Ð²Ñ‹ ÐµÑ‰Ðµ Ð¿Ð¾ÐºÑƒÐ¿Ð°ÐµÑ‚Ðµ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹'} <br/>
                        {'(ÑƒÑ‚Ð¾Ñ‡Ð½Ð¸Ñ‚Ðµ, Ð¾Ð½Ð»Ð°Ð¹Ð½ Ð¸Ð»Ð¸ Ð¾Ñ„Ð»Ð°Ð¹Ð½)'}</SText>
                </div>
                <div style={{marginLeft: 23, marginBottom: 10}}>
                    <SText size={14} weight={700} lineHeight={14}>{'Ð—Ð½Ð°Ñ Ð²Ð°ÑˆÐ¸ Ð¿Ñ€ÐµÐ´Ð¿Ð¾Ñ‡Ñ‚ÐµÐ½Ð¸Ñ, Ð¼Ñ‹ ÑÑ‚Ð°Ð½ÐµÐ¼'} <br/>
                        {'Ð»ÑƒÑ‡ÑˆÐµ Ð¸ ÑƒÐ´Ð¾Ð±Ð½ÐµÐµ Ð´Ð»Ñ Ð²Ð°Ñ'}</SText>
                </div>
            </div>
            <div>
                <div className={styles.qPoints}>
                    <div className={!earned && styles.emptyCircle}>{earned &&
                        <img src={fullTick}/>}</div>
                    <div className={!earned ? styles.amountGray : styles.amountGreen}>{'+200'}</div>
                </div>
            </div>
        </div>

        <div style={{marginBottom: otherStores[15].value.length ? 10 : 0}} className={styles.qColumn}>
            <div className={styles.storesTitle}>
                <div style={{marginBottom: 5}}><SText size={20} weight={700} lineHeight={20}>{'Ð¿Ð¾ÐºÑƒÐ¿Ð°ÐµÑ‚Ðµ'}</SText></div>
                <div><SText size={16} lineHeight={16} weight={700}>{'Ð¾Ð½Ð»Ð°Ð¹Ð½ Ð¸Ð»Ð¸ Ð¾Ñ„Ð»Ð°Ð¹Ð½'}</SText></div>
            </div>
            <div>
                {
                    otherStores.map(({title, fieldName, value, disabledIndex}) => <div
                        className={styles.storeRow}>
                        <SText size={16} lineHeight={16} weight={500}>{title}</SText>
                        <div className={styles.multiCheckboxes}>
                            <img onClick={() => {
                                setOtherStores(prev => prev.map(item => {
                                    if (item.fieldName !== fieldName) return item
                                    else return {
                                        ...item,
                                        value: item.value.includes('0') ? item.value.filter(f => f !== '0') : [...item.value, '0']
                                    }
                                }))
                            }} src={value.includes('0') ? checkboxFull : checkbox}/>
                            {disabledIndex ? <img style={{marginRight: 2}} src={disabled}/> :
                                <img onClick={() => {
                                    setOtherStores(prev => prev.map(item => {
                                        if (item.fieldName !== fieldName) return item
                                        else return {
                                            ...item,
                                            value: item.value.includes('1') ? item.value.filter(f => f !== '1') : [...item.value, '1']
                                        }
                                    }))
                                }} src={value.includes('1') ? checkboxFull : checkbox}/>}
                        </div>
                    </div>)
                }
            </div>
        </div>

        {
            otherStores[15].value.length ?
                <div className={styles.qFlex}>
                    <div className={styles.nameInput}>
                        <input placeholder={'ÐÐ°Ð¿Ð¸ÑˆÐ¸Ñ‚Ðµ Ð½Ð°Ð·Ð²Ð°Ð½Ð¸Ðµ, ÐµÑÐ»Ð¸ Ð´Ñ€ÑƒÐ³Ð¾Ðµ'} value={otherStoresElse}
                               onChange={e => setOtherStoresElse(e.target.value)} type="text"/>
                    </div>
                </div> : null
        }

    </div>
}

const Products = ({products, setProducts}) => {
    const earned = Object.values(products).filter(f => f.trim() !== '').length > 0

    return <div style={{marginBottom: 30}} className={styles.qWrapper}>
        <div style={{display: 'flex', gap: 3}} className={styles.qTitle}>12. <SText div size={14} lineHeight={14}
                                                                                    weight={700}>{' Ð’Ñ‹Ð±ÐµÑ€Ð¸Ñ‚Ðµ 3 Ð»ÑŽÐ±Ð¸Ð¼Ñ‹Ñ… Ñ‚Ð¾Ð²Ð°Ñ€Ð°.'}
            <br/> {'(Ð¡Ð¾ Ð²Ñ€ÐµÐ¼ÐµÐ½ÐµÐ¼ Ð½Ð° ÑÑ‚Ð¸ 3 Ñ‚Ð¾Ð²Ð°Ñ€Ð° Ð±ÑƒÐ´ÐµÑ‚ ÑÐºÐ¸Ð´ÐºÐ°)'}</SText></div>
        <div className={styles.qFlex}>
            <div style={{height: 'initial'}} className={styles.nameInput}>
                <ProductsInput products={products} product={'product1'} setProduct={setProducts}/>
                <ProductsInput products={products} product={'product2'} setProduct={setProducts}/>
                <ProductsInput products={products} product={'product3'} setProduct={setProducts}/>
            </div>
            <div className={styles.qPoints}>
                <div className={!earned && styles.emptyCircle}>{earned &&
                    <img src={fullTick}/>}</div>
                <div className={!earned ? styles.amountGray : styles.amountGreen}>{'+100'}</div>
            </div>
        </div>
    </div>
}

const ProductsInput = ({product, setProduct, products}) => {
    const [text, setText] = useState('')
    const {getProductByEcoId} = useContext(DataContext)
    const {onQueryChange, suggestions} = useSearch()
    const [searchResults, setSearchResults] = useState(false)
    const [focused, setFocused] = useState(false)
    const [data, setData] = useState(false)

    const loadProduct = (id) => {
        return getProductByEcoId(id)
    }

    const search = async (string) => {
        const data = await searchAll(string).then(r => {
            return r?.results?.ids
        })
        setData(data)
    }

    useEffect(async () => {
        if (data) {
            let result = data.map(id => {
                return loadProduct(id)
            }).filter(item => item !== null).slice(0, 5)
            setSearchResults(result)
        }
    }, [data])

    useEffect(async () => {
        await search(text)
        await onQueryChange(text)
    }, [text])

    return <div style={{marginBottom: 5, position: 'relative'}}>

        <input placeholder={'Ð’Ñ‹Ð±ÐµÑ€Ð¸Ñ‚Ðµ Ñ‚Ð¾Ð²Ð°Ñ€'}
               value={text}
               onChange={e => setText(e.target.value)}
               type="text"
               onBlur={() => setTimeout(() => setFocused(false), 300)}
               onFocus={() => setFocused(true)}
        />
        {(searchResults && focused && text !== '') &&
            <div className={styles.suggestionsWrapper}>
                {
                    searchResults && searchResults.map((item) => {
                        return <div onClick={() => {
                            setText(item.title)
                            setProduct({...products, [product]: item.id})
                            setTimeout(() => setFocused(false)
                                , 500)
                        }} className={styles.suggest}>{item.title}</div>
                    })
                }
            </div>
        }
    </div>

}

const Month = ({month, setMonth}) => {

    return <div className={styles.qWrapper}>
        <div style={{display: 'flex', gap: 3}} className={styles.qTitle}>13. <SText div size={14} lineHeight={14}
                                                                                    weight={700}>{' Ð’ ÐºÐ°ÐºÐ¸Ðµ Ð¼ÐµÑÑÑ†Ñ‹ Ð²Ñ‹ ÑƒÐµÐ·Ð¶Ð°ÐµÑ‚Ðµ Ð½Ð°'}
            <br/> {'Ð¾Ñ‚Ð´Ñ‹Ñ… Ð¸ Ð¾Ñ‚ÑÑƒÑ‚ÑÑ‚Ð²ÑƒÐµÑ‚Ðµ Ð² Ð³Ð¾Ñ€Ð¾Ð´Ðµ ?'}</SText></div>
        <div className={styles.qFlex}>
            <div className={styles.pets}>
                {
                    monthArray.map(({value, name}) => <div onClick={() => {
                        if (month.includes(value))
                            setMonth(month.filter((f) => f !== value))
                        else setMonth([...month, value])
                    }} className={styles.petRow}>
                        <img src={month.includes(value) ? checkboxFull : checkbox}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={month.length <= 0 && styles.emptyCircle}>{month.length > 0 &&
                    <img src={fullTick}/>}</div>
                <div className={month.length <= 0 ? styles.amountGray : styles.amountGreen}>{'+60'}</div>
            </div>
        </div>
    </div>
}

const Countries = ({countries, setCountries}) => {
    const earned = Object.values(countries).filter(f => f.trim() !== '').length > 0
    const [visibleInputs, setVisibleInputs] = useState(1)

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>14. <span>{' ÐšÐ°ÐºÐ¸Ðµ ÑÑ‚Ñ€Ð°Ð½Ñ‹ Ð²Ñ‹ Ð¾Ð±Ñ‹Ñ‡Ð½Ð¾ Ð¿Ð¾ÑÐµÑ‰Ð°ÐµÑ‚Ðµ?'}</span></div>
        <div className={styles.qFlex}>
            <div style={{height: 'initial'}} className={styles.nameInput}>
                <input style={{marginBottom: 5}} placeholder={'ÐÐ°Ð·Ð²Ð°Ð½Ð¸Ðµ ÑÑ‚Ñ€Ð°Ð½Ñ‹'} value={countries.country1}
                       onChange={e => setCountries({...countries, country1: e.target.value})} type="text"/>
                {visibleInputs > 1 &&
                    <input style={{marginBottom: 5}} placeholder={'ÐÐ°Ð·Ð²Ð°Ð½Ð¸Ðµ ÑÑ‚Ñ€Ð°Ð½Ñ‹'} value={countries.country2}
                           onChange={e => setCountries({...countries, country2: e.target.value})} type="text"/>}
                {visibleInputs === 3 && <input placeholder={'ÐÐ°Ð·Ð²Ð°Ð½Ð¸Ðµ ÑÑ‚Ñ€Ð°Ð½Ñ‹'} value={countries.country3}
                                               onChange={e => setCountries({...countries, country3: e.target.value})}
                                               type="text"/>}
                {visibleInputs < 3 && <div onClick={() => setVisibleInputs(prev => prev + 1)} style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 6,
                    marginTop: 12,
                    cursor: 'pointer',
                    width: 'fit-content'
                }}><img src={plusCircle}/> <SText size={14} lineHeight={14} color={'#818181'}
                                                  weight={400}>{'Ð´Ð¾Ð±Ð°Ð²Ð¸Ñ‚ÑŒ ÐµÑ‰Ñ‘ ÑÑ‚Ñ€Ð°Ð½Ñƒ'}</SText></div>}
            </div>
            <div className={styles.qPoints}>
                <div className={!earned && styles.emptyCircle}>{earned &&
                    <img src={fullTick}/>}</div>
                <div className={!earned ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}

const Food = ({food, setFood}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>15. <span>{' ÐŸÑ€ÐµÐ´Ð¿Ð¾Ñ‡Ñ‚ÐµÐ½Ð¸Ñ Ð² Ð¿Ð¸Ñ‚Ð°Ð½Ð¸Ð¸'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.pets}>
                {
                    foodArray.map(({value, name}) => <div onClick={() => {
                        if (food.includes(value))
                            setFood(food.filter((f) => f !== value))
                        else setFood([...food, value])
                    }} className={styles.petRow}>
                        <img src={food.includes(value) ? checkboxFull : checkbox}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={food.length <= 0 && styles.emptyCircle}>{food.length > 0 &&
                    <img src={fullTick}/>}</div>
                <div className={food.length <= 0 ? styles.amountGray : styles.amountGreen}>{'+50'}</div>
            </div>
        </div>
    </div>
}

const Weekends = ({weekends, setWeekends}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>16. <span>{' ÐšÑƒÐ´Ð° Ð±Ñ‹ Ð²Ñ‹ Ð¿Ð¾ÑˆÐ»Ð¸ Ð² ÑÐ²Ð¾Ð¹ Ð²Ñ‹Ñ…Ð¾Ð´Ð½Ð¾Ð¹'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.pets}>
                {
                    weekendArray.map(({value, name}) => <div onClick={() => {
                        if (weekends.includes(value))
                            setWeekends(weekends.filter((f) => f !== value))
                        else setWeekends([...weekends, value])
                    }} className={styles.petRow}>
                        <img src={weekends.includes(value) ? checkboxFull : checkbox}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={weekends.length <= 0 && styles.emptyCircle}>{weekends.length > 0 &&
                    <img src={fullTick}/>}</div>
                <div className={weekends.length <= 0 ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}

/*const Organic = ({organic, setOrganic}) => {

    return <div className={styles.qWrapper}>
        <div style={{display: 'flex', gap: 3}} className={styles.qTitle}>17. <SText div size={14} lineHeight={14}
                                                                                    weight={700}>{' Ð˜Ð¼ÐµÐµÑ‚ Ð»Ð¸ Ð´Ð»Ñ Ð²Ð°Ñ Ð·Ð½Ð°Ñ‡ÐµÐ½Ð¸Ðµ'}
            <br/> {'ÑÐ»Ð¾Ð²Ð¾ Â«Ð¾Ñ€Ð³Ð°Ð½Ð¸ÐºÐ°Â»'}</SText>
            <img style={{padding: '0 0 0 22px'}} src={organicLogo}/></div>
        <div style={{justifyContent: 'space-between'}} className={styles.qFlex}>
            <div className={styles.sexInput}>
                <div onClick={() => setOrganic(1)}>
                    <div className={organic === 1 ? styles.fullRadioCircle : styles.emptyRadioCircle}/>
                    <SText size={16} lineHeight={16} weight={500}>{'Ð”Ð°'}</SText>
                </div>
                <div onClick={() => setOrganic(2)}>
                    <div className={organic === 2 ? styles.fullRadioCircle : styles.emptyRadioCircle}/>
                    <SText size={16} lineHeight={16} weight={500}>{'ÐÐµÑ‚'}</SText>
                </div>
            </div>
            <div className={styles.qPoints}>
                <div className={organic === '' && styles.emptyCircle}>{organic !== '' &&
                    <img src={fullTick}/>}</div>
                <div className={organic === '' ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}*/

const Socials = ({socials, setSocials}) => {

    return <div className={styles.qWrapper}>
        <div style={{display: 'flex', gap: 3}}
             className={styles.qTitle}>17. <span>{' ÐšÐ°ÐºÐ¸Ð¼Ð¸ ÑÐ¾Ñ† ÑÐµÑ‚ÑÐ¼Ð¸ Ð²Ñ‹ Ð±Ð¾Ð»ÑŒÑˆÐµ Ð²ÑÐµÐ³Ð¾'} <br/> {'Ð¿Ð¾Ð»ÑŒÐ·ÑƒÐµÑ‚ÐµÑÑŒ?'}</span>
        </div>
        <div className={styles.qFlex}>
            <div className={styles.pets}>
                {
                    socialsArray.map(({value, name}) => <div onClick={() => {
                        if (socials.includes(value))
                            setSocials(socials.filter((f) => f !== value))
                        else setSocials([...socials, value])
                    }} className={styles.petRow}>
                        <img src={socials.includes(value) ? checkboxFull : checkbox}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={socials.length <= 0 && styles.emptyCircle}>{socials.length > 0 &&
                    <img src={fullTick}/>}</div>
                <div className={socials.length <= 0 ? styles.amountGray : styles.amountGreen}>{'+50'}</div>
            </div>
        </div>
    </div>
}

const Inst = ({inst, setInst}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>18. <span>{' Ð’Ð°Ñˆ Ð½Ð¸Ðº Ð² ÑÐ¾Ñ†Ð¸Ð°Ð»ÑŒÐ½Ñ‹Ñ… ÑÐµÑ‚ÑÑ…'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.nameInput}>
                <input placeholder={'Ð’Ð°Ñˆ Ð½Ð¸Ðº'} value={inst} onChange={e => setInst(e.target.value)} type="text"/>
            </div>
            <div className={styles.qPoints}>
                <div className={inst.length <= 1 && styles.emptyCircle}>{inst.length > 1 &&
                    <img src={fullTick}/>}</div>
                <div className={inst.length <= 1 ? styles.amountGray : styles.amountGreen}>{'+100'}</div>
            </div>
        </div>
    </div>
}

const PopularStores = ({popularStores, setPopularStores, otherStores}) => {
    let earned = popularStores.filter(item => item.value !== false).length > 0

    const hiddenNames = otherStores.reduce((acc, cur) => {
        if (cur.value?.length < 1)
            return [...acc, cur.fieldName]
        else return acc
    }, [])

    let visible = otherStores.length !== hiddenNames.length

    return <div style={{marginBottom: 30}} className={styles.qWrapper}>
        <div style={{marginBottom: 10}} className={styles.qTitleSpecial}>
            <div>
                <div style={{display: 'flex', gap: 5, marginBottom: 15}}>
                    <SText size={14} weight={500} lineHeight={14} color={'#818181'}>{'19.'}</SText>
                    <SText size={14} weight={700} lineHeight={14}>{'Ð’ ÐºÐ°ÐºÐ¾Ð¼ Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½Ðµ Ð²Ñ‹ Ñ‡Ð°Ñ‰Ðµ'} <br/>
                        {'Ð·Ð°ÐºÑƒÐ¿Ð°ÐµÑ‚ÐµÑÑŒ'}</SText>
                </div>
            </div>
            <div>
                <div className={styles.qPoints}>
                    <div className={!earned && styles.emptyCircle}>{earned &&
                        <img src={fullTick}/>}</div>
                    <div className={!earned ? styles.amountGray : styles.amountGreen}>{'+100'}</div>
                </div>
            </div>
        </div>

        {visible ? <div className={styles.qColumn}>
            <div className={styles.popularTitle}>
                <div><SText size={16} lineHeight={16} weight={700}>{'Ñ‡Ð°ÑÑ‚Ð¾'}</SText></div>
                <div><SText size={16} lineHeight={16} weight={700}>{'Ñ€ÐµÐ´ÐºÐ¾'}</SText></div>
            </div>
            <div>
                {
                    popularStores.map(({title, fieldName, value, disabledIndex}) => {
                        if (hiddenNames.find(item => item === fieldName)) return null
                        return <div
                            className={styles.storeRow}>
                            <SText size={16} lineHeight={16} weight={500}>{title}</SText>
                            <div className={styles.multiCheckboxes}>
                                <img onClick={() => {
                                    setPopularStores(prev => prev.map(item => {
                                        if (item.fieldName !== fieldName) return item
                                        else return {
                                            ...item,
                                            value: item.value === 0 ? false : 0
                                        }
                                    }))
                                }} src={value === 0 ? checkboxFull : checkbox}/>
                                <img onClick={() => {
                                    setPopularStores(prev => prev.map(item => {
                                        if (item.fieldName !== fieldName) return item
                                        else return {
                                            ...item,
                                            value: item.value === 1 ? false : 1
                                        }
                                    }))
                                }} src={value === 1 ? checkboxFull : checkbox}/>
                            </div>
                        </div>
                    })
                }
            </div>
        </div> : <div>
            <SText size={14} weight={500} lineHeight={14}
                   color={'#818181'}>{'ÐŸÐ¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, Ð¾Ñ‚Ð²ÐµÑ‚ÑŒÑ‚Ðµ ÑÐ½Ð°Ñ‡Ð°Ð»Ð° Ð½Ð° Ð²Ð¾Ð¿Ñ€Ð¾Ñ 11'}</SText>
        </div>}
    </div>
}

export default Anketa;