import React, {useMemo} from "react";
import SText from "./SText";
import styles from "./AboutSeason.module.css"
import Header from "./Header";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import {useWindowDimensions} from "../utils/hooks";
import BreadCrumbs from "./BreadCrumbs";
import {Helmet} from "react-helmet";

export default () => {

    const {isMobile, windowDimensions:{width}} = useWindowDimensions(600)

    const breadCrumbs = [
            {
                name: 'Ðž SeasonMarket',
                to: '/about-season-market'
            }
        ]


    return <div>
        <Helmet>
            <title>Ðž SeasonMarket</title>
            <meta name="description" content='Ðž SeasonMarket'/>
            <meta name="keywords" content='Ðž SeasonMarket'/>
        </Helmet>
        <Header/>
        {width < 992 && <MobileExtraInfo/>}
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BreadCrumbs _items={breadCrumbs}/>
                <div style={{marginBottom: '35px'}}><SText fontSize={24} fontWeight={900}>Ðž SeasonMarket</SText></div>
                <div style={{marginBottom: '15px'}}><SText fontSize={16} fontWeight={900}>Ð§Ñ‚Ð¾ Ñ‚Ð°ÐºÐ¾Ðµ â€“ Â«SeasonMarketÂ»?
                    Ð­Ñ‚Ð¾ ÑÐµÑ€Ð²Ð¸Ñ, ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ð¹ Ð¿Ñ€Ð¾Ð»Ð¾Ð¶Ð¸Ð» ÑÐ²ÑÐ·Ð¸
                    Ð¼ÐµÐ¶Ð´Ñƒ Ð¿Ð¾ÐºÑƒÐ¿Ð°Ñ‚ÐµÐ»ÑÐ¼Ð¸ Ð¸ Ð¿Ñ€Ð¾Ð¸Ð·Ð²Ð¾Ð´Ð¸Ñ‚ÐµÐ»ÑÐ¼Ð¸ Ð½Ð°Ñ‚ÑƒÑ€Ð°Ð»ÑŒÐ½Ñ‹Ñ… Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð² â€“ ÑÐ°Ð¼Ñ‹Ñ… Ð²ÐºÑƒÑÐ½Ñ‹Ñ…, ÑÐ²ÐµÐ¶Ð¸Ñ…, ÐºÐ°Ñ‡ÐµÑÑ‚Ð²ÐµÐ½Ð½Ñ‹Ñ… Ð¸
                    Ð±ÐµÐ·Ð¾Ð¿Ð°ÑÐ½Ñ‹Ñ…. <br/> ÐœÑ‹ Ð½Ð°Ñ…Ð¾Ð´Ð¸Ð¼ Ð¸Ñ… Ð´Ð»Ñ Ð²Ð°Ñ, Ð¸ Ð² ÑÑ‚Ð¾Ð¼ â€“ Ð½Ð°ÑˆÐ° Ð·Ð°Ð´Ð°Ñ‡Ð°.</SText></div>
                <div className={styles.li}><SText fontSize={16} fontWeight={550}>Ð¡ Ð½Ð°ÑˆÐµÐ¹ Ð¿Ð¾Ð¼Ð¾Ñ‰ÑŒÑŽ Ð¿Ð¾ÐºÑƒÐ¿ÐºÐ° Ð¿Ñ€Ð¸Ð¾Ð±Ñ€ÐµÑ‚Ð°ÐµÑ‚
                    Ð¼Ð°ÐºÑÐ¸Ð¼Ð°Ð»ÑŒÐ½Ð¾ ÑƒÐ´Ð¾Ð±Ð½ÑƒÑŽ Ð´Ð»Ñ Ð²Ð°Ñ
                    Ñ„Ð¾Ñ€Ð¼Ñƒ:</SText></div>
                <div className={styles.li}><SText fontSize={16} fontWeight={550}>Ð’Ñ‹ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°ÐµÑ‚Ðµ ÑÐµÐ³Ð¾Ð´Ð½Ñ â€“ Ð¸ Ð¼Ñ‹
                    Ð´Ð¾ÑÑ‚Ð°Ð²Ð»ÑÐµÐ¼ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ Ðº Ð²Ð°Ð¼ Ð´Ð¾Ð¼Ð¾Ð¹ ÑƒÐ¶Ðµ
                    Ð·Ð°Ð²Ñ‚Ñ€Ð°! Ð’Ñ‹ Ð¼Ð¾Ð¶ÐµÑ‚Ðµ Ð²Ñ‹Ð±Ñ€Ð°Ñ‚ÑŒ ÑƒÐ´Ð¾Ð±Ð½Ñ‹Ð¹ Ð²Ñ€ÐµÐ¼ÐµÐ½Ð½Ð¾Ð¹ Ð¸Ð½Ñ‚ÐµÑ€Ð²Ð°Ð» Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸ Ð¿Ñ€Ð¸ Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÐµÐ½Ð¸Ð¸ Ð·Ð°ÐºÐ°Ð·Ð°. ÐžÑÑ‚Ð°Ð²ÑŒÑ‚Ðµ
                    Ð¿Ð¾Ð¶ÐµÐ»Ð°Ð½Ð¸Ñ
                    Ð² ÐºÐ¾Ð¼Ð¼ÐµÐ½Ñ‚Ð°Ñ€Ð¸ÑÑ…, Ð¸ Ð¼Ñ‹ Ð¿Ð¾ÑÑ‚Ð°Ñ€Ð°ÐµÐ¼ÑÑ Ð¸Ñ… Ð²Ñ‹Ð¿Ð¾Ð»Ð½Ð¸Ñ‚ÑŒ.</SText></div>
                <div className={styles.li}><SText fontSize={16} fontWeight={550}>ÐžÑÑ‚Ð°Ð²Ð°Ñ‚ÑŒÑÑ Ð² Ð½ÐµÐ²ÐµÐ´ÐµÐ½Ð¸Ð¸ Ð¾ Ñ‚Ð¾Ñ‡Ð½Ð¾Ð¹ ÑÑƒÐ¼Ð¼Ðµ Ð¸
                    ÑÐ¾ÑÑ‚Ð°Ð²Ðµ Ð·Ð°ÐºÐ°Ð·Ð°?
                    ÐžÐ·Ð½Ð°ÐºÐ¾Ð¼Ð¸Ñ‚ÑŒÑÑ Ñ Ð²ÐµÑÐ¾Ð¼ Ð·Ð°ÐºÐ°Ð·Ð° Ð¸ ÑÑƒÐ¼Ð¼Ð¾Ð¹ Ðº Ð¾Ð¿Ð»Ð°Ñ‚Ðµ Ð»Ð¸ÑˆÑŒ Ð² Ð´ÐµÐ½ÑŒ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸? Ð­Ñ‚Ð¾Ð³Ð¾ Ð½Ðµ Ð±ÑƒÐ´ÐµÑ‚! Ð’Ñ‹ ÑƒÐ·Ð½Ð°ÐµÑ‚Ðµ
                    Ñ‚Ð¾Ñ‡Ð½ÑƒÑŽ
                    Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸ÑŽ Ð¾ ÑÐ¾ÑÑ‚Ð°Ð²Ðµ ÑÐ²Ð¾ÐµÐ³Ð¾ Ð·Ð°ÐºÐ°Ð·Ð° Ð² Ñ‚ÐµÑ‡ÐµÐ½Ð¸Ðµ ÐºÐ¾Ñ€Ð¾Ñ‚ÐºÐ¾Ð³Ð¾ Ð²Ñ€ÐµÐ¼ÐµÐ½Ð¸.</SText></div>
                <div className={styles.li}><SText fontSize={16} fontWeight={550}>ÐœÑ‹ Ð·Ð°Ð±Ð¾Ñ‚Ð¸Ð¼ÑÑ Ð¾ Ñ‚Ð¾Ð¼, Ñ‡Ñ‚Ð¾Ð±Ñ‹ Ð²Ð°Ð¼ Ð½Ðµ
                    Ð¿Ñ€Ð¸ÑˆÐ»Ð¾ÑÑŒ ÑÐ°Ð¼Ð¾ÑÑ‚Ð¾ÑÑ‚ÐµÐ»ÑŒÐ½Ð¾ Ð¸ÑÐºÐ°Ñ‚ÑŒ
                    Ð»ÑƒÑ‡ÑˆÐ¸Ðµ Ð½Ð°Ñ‚ÑƒÑ€Ð°Ð»ÑŒÐ½Ñ‹Ðµ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ Ñƒ Ñ€Ð°Ð·Ð½Ñ‹Ñ… Ð¿Ñ€Ð¾Ð¸Ð·Ð²Ð¾Ð´Ð¸Ñ‚ÐµÐ»ÐµÐ¹. Ð’ Ð°ÑÑÐ¾Ñ€Ñ‚Ð¸Ð¼ÐµÐ½Ñ‚ SeasonMarket Ñ€ÐµÐ³ÑƒÐ»ÑÑ€Ð½Ð¾
                    Ð´Ð¾Ð±Ð°Ð²Ð»ÑÑŽÑ‚ÑÑ
                    Ð½Ð¾Ð²Ñ‹Ðµ Ð¿Ð¾Ð·Ð¸Ñ†Ð¸Ð¸ â€” Ð¼Ñ‹ Ð´ÑƒÐ¼Ð°ÐµÐ¼ Ð¾ Ñ€Ð°Ð·Ð½Ð¾Ð¾Ð±Ñ€Ð°Ð·Ð¸Ð¸ Ð²Ð°ÑˆÐµÐ³Ð¾ Ð¼ÐµÐ½ÑŽ!</SText></div>
                <div className={styles.li}><SText fontSize={16} fontWeight={550}>Ð¡ Ð½Ð°Ð¼Ð¸ Ð²Ñ‹Ð±Ð¸Ñ€Ð°Ñ‚ÑŒ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ Ð¸ Ð³Ð¾Ñ‚Ð¾Ð²Ð¸Ñ‚ÑŒ
                    Ð¸Ð·Ñ‹ÑÐºÐ°Ð½Ð½Ñ‹Ðµ Ð±Ð»ÑŽÐ´Ð° Ñ‚Ð°Ðº Ð»ÐµÐ³ÐºÐ¾!
                    Ð’ÐµÐ´ÑŒ ÐºÐ°Ð¶Ð´Ð°Ñ Ð¿Ð¾Ð·Ð¸Ñ†Ð¸Ñ Ð°ÑÑÐ¾Ñ€Ñ‚Ð¸Ð¼ÐµÐ½Ñ‚Ð° SeasonMarket ÑƒÐ¶Ðµ Ð¿Ð¾Ð´Ð³Ð¾Ñ‚Ð¾Ð²Ð»ÐµÐ½Ð° Ðº ÐºÑƒÐ»Ð¸Ð½Ð°Ñ€Ð½Ð¾Ð¹ Ð¾Ð±Ñ€Ð°Ð±Ð¾Ñ‚ÐºÐµ. Ð˜Ð½Ð´ÐµÐ¹ÐºÐ°
                    Ð¿Ñ€Ð¾Ð´Ð°ÐµÑ‚ÑÑ
                    ÑƒÐ¶Ðµ Ñ€Ð°Ð·Ð´ÐµÐ»Ð°Ð½Ð½Ð¾Ð¹, Ð° Ñ€Ñ‹Ð±Ð° Ð±ÑƒÐ´ÐµÑ‚ Ð²Ñ‹Ð¿Ð¾Ñ‚Ñ€Ð¾ÑˆÐµÐ½Ð° Ð¸ Ð¿Ð¾Ñ‡Ð¸Ñ‰ÐµÐ½Ð° Ð¿Ð¾ Ð²Ð°ÑˆÐµÐ¼Ñƒ Ð¶ÐµÐ»Ð°Ð½Ð¸ÑŽ.</SText></div>
                <div className={styles.li}><SText fontSize={16} fontWeight={550}>ÐŸÑ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ Ð² ÑÐ¾Ð²Ñ€ÐµÐ¼ÐµÐ½Ð½Ð¾Ð¹, Ð½Ð°Ð´ÐµÐ¶Ð½Ð¾Ð¹ Ð¸
                    Ð°ÐºÐºÑƒÑ€Ð°Ñ‚Ð½Ð¾Ð¹ ÑƒÐ¿Ð°ÐºÐ¾Ð²ÐºÐµ â€“ Ð¼Ñ‹
                    ÑÐ¾Ð±Ð»ÑŽÐ´Ð°ÐµÐ¼ Ð²ÑÐµ ÐºÑ€Ð¸Ñ‚ÐµÑ€Ð¸Ð¸ Ð±ÐµÐ·Ð¾Ð¿Ð°ÑÐ½Ð¾Ð¹ Ñ‚Ð¾Ñ€Ð³Ð¾Ð²Ð»Ð¸.</SText></div>
                <div className={styles.li}><SText fontSize={16} fontWeight={550}>ÐœÑ‹ Ð¿Ñ€ÐµÐ´Ð»Ð°Ð³Ð°ÐµÐ¼ Ñ‚Ð¾Ð»ÑŒÐºÐ¾ ÑÐ°Ð¼Ñ‹Ðµ ÑÐ²ÐµÐ¶Ð¸Ðµ
                    Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹: Ñ„Ð°ÑÐ¾Ð²ÐºÐ° Ð¿Ð¾Ð´ Ð·Ð°ÐºÐ°Ð·,
                    Ñ€ÐµÐ°Ð»Ð¸Ð·Ð°Ñ†Ð¸Ñ Ð´Ð¾ Ð¸ÑÑ‚ÐµÑ‡ÐµÐ½Ð¸Ñ 2/3 ÑÑ€Ð¾ÐºÐ° Ð³Ð¾Ð´Ð½Ð¾ÑÑ‚Ð¸, ÐºÐ°Ñ‡ÐµÑÑ‚Ð²ÐµÐ½Ð½Ð°Ñ Ð¾Ñ‚Ð±Ñ€Ð°ÐºÐ¾Ð²ÐºÐ°, Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð² Ð°Ð²Ñ‚Ð¾Ð¼Ð¾Ð±Ð¸Ð»ÑÑ…,
                    Ð¾Ð±Ð¾Ñ€ÑƒÐ´Ð¾Ð²Ð°Ð½Ð½Ñ‹Ñ… Ð¿Ñ€Ð¾Ñ„ÐµÑÑÐ¸Ð¾Ð½Ð°Ð»ÑŒÐ½Ñ‹Ð¼Ð¸ Ñ€ÐµÑ„Ñ€Ð¸Ð¶ÐµÑ€Ð°Ñ‚Ð¾Ñ€Ð°Ð¼Ð¸.</SText></div>
                <div><SText fontSize={16} fontWeight={700}>Ð’Ñ‹, ÐºÐ°Ðº Ð¸ Ð¼Ð½Ð¾Ð³Ð¸Ðµ, ÑÑ‡Ð¸Ñ‚Ð°ÐµÑ‚Ðµ, Ñ‡Ñ‚Ð¾ Ð½Ð°Ñ‚ÑƒÑ€Ð°Ð»ÑŒÐ½Ñ‹Ðµ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ â€“ ÑÑ‚Ð¾
                    Ð²ÑÐµÐ³Ð´Ð°
                    Ð´Ð¾Ñ€Ð¾Ð³Ð¾? Ð£ Ð½Ð°Ñ Ñ‚Ñ‰Ð°Ñ‚ÐµÐ»ÑŒÐ½Ñ‹Ð¹ Ð¿Ð¾Ð´Ñ…Ð¾Ð´ Ðº Ñ„Ð¾Ñ€Ð¼Ð¸Ñ€Ð¾Ð²Ð°Ð½Ð¸ÑŽ Ñ†ÐµÐ½Ñ‹ Ð½Ð° Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ†Ð¸ÑŽ. ÐœÑ‹ Ð¿Ñ€ÐµÐ´Ð»Ð°Ð³Ð°ÐµÐ¼: ÐºÐ°Ñ‡ÐµÑÑ‚Ð²Ð¾ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð²
                    â€“
                    Ð²Ñ‹ÑÐ¾Ñ‡Ð°Ð¹ÑˆÐµÐµ, Ð° Ñ†ÐµÐ½Ñ‹ Ð½Ð° Ð½Ð¸Ñ… â€“ Ñ€Ð°Ð·ÑƒÐ¼Ð½Ñ‹Ðµ Ð¸ Ñ‡ÐµÑÑ‚Ð½Ñ‹Ðµ.</SText></div>
            </div>
        </div>
    </div>
}